import React, { useEffect, useState } from 'react';
import { getSMSTEMP, updateSMSTemp } from "../services/apiServices";
import ReactGA from 'react-ga';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBRow,
} from 'mdb-react-ui-kit';
import PageError from './PageError';
import { googleAnalytic } from '../components/googleAnalytics';

export default function SMSTempleteSettings() {
    const [AllSMS, setAllSMS] = useState([]);
    const [check, setcheck] = useState(false);
    const [data, setData] = useState([])
    const [apiError, setApiError] = useState(false);
    function toggleFunction(label, value) {
        const updatedAllSMS = AllSMS.map(item => {
            if (item.label === label.label && label.type === item.type) {
                return { ...item, toggle: value };
            }
            return item;
        });
        setAllSMS(updatedAllSMS);
        setcheck(true);
    }

    const handleReload = () => {
        window.location.reload();
    }
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

 
    async function updateFunction(e) {
        e.preventDefault();
        if (AllSMS.length > 0) {
            for (const value of AllSMS) {
                const updates = {
                    label: value.label,
                    type: value.type,
                    toggle: value.toggle,
                    duration: value.duration,
                    templete: value.templete,
                    smscategory: value.smscategory,
                    gymid: JSON.parse(sessionStorage.getItem("loggedUserInfo")) ? JSON.parse(sessionStorage.getItem("loggedUserInfo")).gymid : "",
                };
                let filterData = data.filter(item => item.type === value.type && item.label === value.label);
                if ((filterData[0].toggle !== value.toggle) || (value.duration != null ? (filterData[0].duration !== value.duration) : false)) {
                    try {
                        console.log(updates);
                        let saverep = await updateSMSTemp(updates, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);

                        if (saverep === "false") {
                            setApiError(true)
                            return;
                        }
                        const recinfo = await saverep.json();
                        console.log(recinfo);
                        await googleAnalytic("SmsTempleteSettings", 'Sms Templete', 'Update Buttonn', 'Sms Settings Updated')
                   
                    } catch (error) {
                        console.error("Error updating record:", error);
                    }
                }
            }
        }
    }

    useEffect(() => {
        async function fetchPData() {
            let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await getSMSTEMP(gymid);

                if (response === "false") {
                    setApiError(true)
                    return;
                }
                const templetes = await response.json();
                console.log(templetes);
                if (templetes.length > 0) {
                    setAllSMS(templetes);
                    setData(templetes);
                }

            }
        }

        fetchPData();
    }, []);


    console.log(AllSMS);
    return (
        <>
            {apiError ? <PageError handleReload={handleReload} /> : <>
                <div className='d-flex align-items-baseline justify-content-between mob-block'>
                    <p className='mob-w-100'>IMPORTANT NOTE: All SMS will be sent as per new TRAI regulations via DLT platform. Kindly check promotional SMS timings before sending any sms. For more details kindly visit TRAI offical website.</p>
                    <MDBBtn size='lg' onClick={(e) => updateFunction(e)} disabled={!check}><MDBIcon fas icon="pen" className='me-2' /> Update</MDBBtn>
                </div>
                <MDBRow className='d-flex justify-content-evenly mt-4'>
                    <MDBCol size={6} className='mob-w-100'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>Member</MDBCardTitle>
                                <MDBCardText>

                                    {AllSMS.length > 0 && AllSMS.map((lab, index) => {
                                        return (
                                            lab.type === "member" &&
                                            <div key={index} className='d-flex align-items-center lh-1 mt-1'>
                                                <div>
                                                    <label className='switch'>
                                                        <input
                                                            className='form-control'
                                                            type='checkbox'
                                                            checked={lab.toggle}
                                                            onChange={() => toggleFunction(lab, !lab.toggle)}
                                                        />
                                                        <span className='slider round'></span>
                                                    </label>
                                                </div>
                                                <div className='sms-settings-info'>
                                                    <label>{lab.label}</label>
                                                    {/* <MDBIcon fas icon='info-circle' className='ms-2' /> */}
                                                </div>
                                            </div>
                                        )
                                    })}

                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size={6} className='mob-w-100'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>Staff</MDBCardTitle>
                                <MDBCardText>
                                    <div>

                                        {AllSMS.length > 0 && AllSMS.map((lab, index) => {
                                            return (
                                                lab.type === "staff" &&
                                                <div key={index} className='d-flex align-items-center lh-1 mt-1'>
                                                    <div>
                                                        <label className='switch'>
                                                            <input
                                                                className='form-control'
                                                                type='checkbox'
                                                                checked={lab.toggle}
                                                                onChange={() => toggleFunction(lab, !lab.toggle)}
                                                            />
                                                            <span className='slider round'></span>
                                                        </label>
                                                    </div>
                                                    <div className='sms-settings-info'>
                                                        <label>{lab.label}</label>
                                                        {/* <MDBIcon fas icon='info-circle' className='ms-2' /> */}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </>}</>
    );
}