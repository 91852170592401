import React, { useEffect, useState } from 'react'
import { CreateAPIsms, DeleteAPIsms, GetAPIsms, resetGateway } from '../services/apiServices';
import { MDBAlert, MDBBtn, MDBCol, MDBDatatable, MDBIcon, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRow, MDBTextArea } from 'mdb-react-ui-kit';
import {
    isEmpty,
} from "../validators/helper.js";
import PageError from './PageError';
import ReactGA from 'react-ga';
import { googleAnalytic } from '../components/googleAnalytics';

function SmsGateway() {
    const [apiError, setApiError] = useState(false);
    const [smsgateway, setsmsgateway] = useState([]);
    const [editsmsgateway, setEditsmsgateway] = useState(false);
    const [form1, setForm1] = useState(
        {
            name: 'TEXT LOCAL',
            type: 'TEXTLOCAL',
            apikey: 'NzM0NjUwNTAzMDUwNzI0ZTc1NTU1NDU0NjI1MTYxNTE=',
            senderid: 'GYMADN',
            gymid: "",
            _id: "",
            primary: false,
        },
    );
    const toggleShow2 = () => setEditsmsgateway(!editsmsgateway);
    const Setvalue = () => setForm1({ name: "", senderid: "", type: "TEXTLOCAL", apikey: "", gymid: "", _id: "", });
    const [addGateWay, setAddGateWay] = useState(false);
    const toggleShow4 = () => setAddGateWay(!addGateWay);
    const [error, setError] = useState({});
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const toggleShow5 = () => setDeleteConfirm(!deleteConfirm);
    const [ResetToggle, setResetToggle] = useState(false);
    const [Alerttxt, setAlerttxt] = useState("");

    const handleReload = () => {
        window.location.reload();
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    async function DeleteApi(e) {
        if (form1._id && form1._id !== "") {
            await DeleteAPIsms(form1._id);

            setsmsgateway(prevState => {
                const updatedValue = prevState.filter(obj => obj._id !== form1._id);
                return updatedValue;
            });
            setDeleteConfirm(false);
            Setvalue();
        }
        await googleAnalytic("SmsGateway", 'Sms Templete', 'Delete Button', 'Sms Api Deleted')
      
        setDeleteConfirm(false);
        Setvalue();
    }

    async function AddAPIsms() {
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        console.log(form1);
        const updates = {
            name: form1.name,
            senderid: form1.senderid,
            type: form1.type,
            apikey: form1.apikey,
            gymid: gymid,
            id: form1._id ? form1._id : -1,
            primary: form1.primary
        }
        console.log(updates);
        let errors = validateForm();

        if (errors === true) {
            let saverep = await CreateAPIsms(updates, gymid);

            if (saverep === "false") {
                setApiError(true)
                return;
            }
            const recinfo = await saverep.json();
            console.log(recinfo);
            setAlerttxt("Gateway added successfully")
            const response = await GetAPIsms(gymid);
            const smsapi = await response.json();
            await googleAnalytic("SmsGateway", 'Sms Templete', 'Save Button', 'Sms Api Updated')
          
            console.log(smsapi);
            if (smsapi.length > 0) {
                setsmsgateway(smsapi);
            }
            setAddGateWay(false);
            setEditsmsgateway(false);
            Setvalue();
            setForm1(
                {
                    name: '',
                    type: '',
                    apikey: '',
                    senderid: '',
                    gymid: "",
                    _id: "",
                    primary: false,
                },
            )
        } else {
            setError(errors)
            console.log(errors);
        }
    }
    useEffect(() => {
        async function fetchData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await GetAPIsms(
                    JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
                );
                if (response === "false") {
                    setApiError(true)
                    return;
                }
                const plans = await response.json();

                console.log(plans);
                if (plans.length > 0) {
                    Setvalue();
                    setsmsgateway(plans);
                }
              
            }
        }
        fetchData();
        Setvalue();
    }, []);
    function updateForm1(value) {
        return setForm1((prev) => {
            return { ...prev, ...value };
        });
    }
    async function resetTempleteHandler(e) {
        setResetToggle(true);
        e.preventDefault();
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        let saverep = await resetGateway(gymid);
        let response = await saverep.json();
        console.log(response);
        setForm1((prev) => {
            return { ...prev, ...response.smsGateway };
        });
    }


    const smsapigate = smsgateway.filter((el) => {
        let updatedInfo = el;
        return updatedInfo;
    });
    let tmrowA = [];

    smsapigate.forEach((row, index) => {
        console.log(row, row.primary);
        let rarray = [];
        rarray.push(index + 1);
        rarray.push(row.name);
        rarray.push(row.type);
        rarray.push(row.apikey);
        rarray.push(row.senderid);
        rarray.push(row.primary === true ? "Enable" : "Disable")
        rarray.push(
            <><div>
                <MDBIcon icon='pen' className='me-4 fs-6 text-secondary ' onClick={() => { toggleShow2(); setForm1(row); }} />
                <MDBIcon icon='trash' className='fs-6 text-secondary' onClick={() => { toggleShow5(); setForm1(row); }} />
            </div></>
        );
        tmrowA.push(rarray);
    });
    const smsgateway1 = {
        columns: [
            "S.no",
            "Name",
            "Type",
            "API Key",
            "Sender ID",
            "Primary",
            "Actions",
        ],
        rows: tmrowA,
    };
    console.log(tmrowA, '89999999999999999999');
    let validateForm = (e) => {
        console.log("Validating form...");
        let errors = {};
        if (isEmpty(form1.name)) {
            errors.name = "Name can't be blank";
        }
        if (isEmpty(form1.senderid)) {
            errors.senderid = "Id can't be blank";
        }
       
        if (isEmpty(form1.apikey)) {
            errors.apikey = "apikey can't be blank";
        }

        setError(errors);
        if (isEmpty(errors)) {
            return true;
        } else {
            console.log("errorrrr")
            return errors;
        }
    };
 
    return (
        <> {apiError ? <PageError handleReload={handleReload} /> :

            <div>

                <div className='sms-gatewaytable d-flex align-items-center justify-content-end mb-3 '>
                    <MDBBtn className='ms-2' color='success' onClick={toggleShow4}>
                        <MDBIcon fas icon="plus" className='me-2' />Add
                    </MDBBtn>
                </div>
                <MDBDatatable searchLabel="Search by SMS Gateway Details" fixedHeader maxHeight='70vh' hover data={smsgateway1} className="sno Transaction__table" />
                <MDBModal show={editsmsgateway} setShow={setEditsmsgateway} open={editsmsgateway} setOpen={setEditsmsgateway} tabIndex='-1'>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><MDBIcon fas icon="pen" className='me-2 ' /> Edit SMS Gateway</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleShow2}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    <MDBCol>
                                        <label>Name<span className="text-danger">*</span></label>
                                        <MDBInputGroup className='mb-3' size='lg'>
                                            <input className={form1.name === "" ? 'is-invalid form-control' : 'form-control'} type='text' placeholder="Name"
                                                value={form1.name}
                                                onChange={(e) => updateForm1({ name: e.target.value })} />

                                        </MDBInputGroup>
                                        {/* {error.name && (
                                        <>
                                            <p className="clr-red">{error.name}</p>
                                        </>
                                    )} */}
                                    </MDBCol>
                                    <MDBCol>
                                        <label>Sender ID<span className="text-danger">*</span></label>
                                        <MDBInputGroup className='mb-3' size='lg'>
                                            <input className={form1.senderid === "" ? 'is-invalid form-control' : 'form-control'} type='text' placeholder="Sender ID" value={form1.senderid}
                                                onChange={(e) => updateForm1({ senderid: e.target.value })} />
                                            {/* {error.senderid && (
                                            <>
                                                <p className="clr-red">{error.senderid}</p>
                                            </>
                                        )} */}
                                        </MDBInputGroup>
                                    </MDBCol>
                                </MDBRow>
                                <label className=''>Type<span className="text-danger">*</span></label>
                                <select className="form-control form-select selectname" label='Type *' name="All Users"
                                    value={form1.type}
                                    onChange={(e) => updateForm1({ type: e.target.value })}
                                >
                                    <option value="TEXTLOCAL">TEXTLOCAL</option>
                                    {/* <option value="WAY2SMS">WAY2SMS</option> */}
                                </select>
                                <label className=' mt-4'>API Key<span className="text-danger">*</span></label>
                                <MDBTextArea id='textAreaExample' rows={2}
                                    className={form1.apikey === "" ? 'is-invalid ' : ''}
                                    value={form1.apikey}
                                    onChange={(e) => updateForm1({ apikey: e.target.value })}
                                />

                                {error.apikey && (
                                    <>
                                        <p className="clr-red">{error.apikey}</p>
                                    </>
                                )}
                                <div className="col-md-4 gym-section gym-half d-flex flex-row align-items-center   my-0">
                                    <label className="mb-0 switchlabel">Primary:</label>
                                    <label className="switch">
                                        <input className="form-control" type="checkbox" checked={form1.primary} onChange={() => updateForm1({ primary: !form1.primary })} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter className='pb-2 pe-2'>
                                <MDBBtn color='warning' onClick={(e) => resetTempleteHandler(e)}>Reset</MDBBtn>
                                <MDBBtn color='secondary' onClick={() => { toggleShow2(); Setvalue() }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn onClick={AddAPIsms} >Save</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBModal show={addGateWay} setShow={setAddGateWay} open={addGateWay} setOpen={setAddGateWay} tabIndex='-1'>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle><MDBIcon fas icon="plus" className='me-2 fs-6' /> Add SMS Gateway</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleShow4}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBRow>
                                    <MDBCol>

                                        <label>Name<span className="text-danger">*</span></label>
                                        <MDBInputGroup className='mb-3' size='lg'>
                                            <input className={form1.name === "" ? 'is-invalid form-control' : 'form-control'} type='text' placeholder="Name"
                                                value={form1.name}
                                                onChange={(e) => updateForm1({ name: e.target.value })} />
                                        </MDBInputGroup>
                                    </MDBCol>
                                    <MDBCol>
                                        <label>Sender ID<span className="text-danger">*</span></label>
                                        <MDBInputGroup className='mb-3' size='lg'>
                                            <input className={form1.senderid === "" ? 'is-invalid form-control' : 'form-control'} type='text' placeholder="Sender ID" value={form1.senderid}
                                                onChange={(e) => updateForm1({ senderid: e.target.value })} />
                                        </MDBInputGroup>
                                    </MDBCol>
                                </MDBRow>
                                <label className=''>Type<span className="text-danger">*</span></label>
                                <select className="form-control form-select selectname" label='Type *' name="All Users"
                                    value={form1.type}
                                    onChange={(e) => updateForm1({ type: e.target.value })}
                                >
                                    <option value="TEXTLOCAL">TEXTLOCAL</option>
                                    {/* <option value="WAY2SMS">WAY2SMS</option> */}
                                </select>
                                <label className=' mt-4'>API Key<span className="text-danger">*</span></label>
                                <MDBTextArea id='textAreaExample' rows={2}
                                    value={form1.apikey}
                                    className={form1.apikey === "" ? 'is-invalid ' : ''}
                                    onChange={(e) => updateForm1({ apikey: e.target.value })}
                                />

                                <div className="col-md-4 gym-section gym-half d-flex flex-row align-items-center   my-0">
                                    <label className="mb-0 switchlabel">Primary:</label>
                                    <label className="switch">
                                        <input className="form-control" type="checkbox" checked={form1.primary} onChange={() => updateForm1({ primary: !form1.primary })} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={() => { toggleShow4(); Setvalue() }}>
                                    Close
                                </MDBBtn>
                                <MDBBtn onClick={AddAPIsms}>Add</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBModal show={deleteConfirm} setShow={setDeleteConfirm} open={deleteConfirm} setOpen={setDeleteConfirm} tabIndex='-1'>
                    <MDBModalDialog size="sm" centered>
                        <MDBModalContent>
                            <MDBModalBody>
                                <strong>
                                    Are you sure you want to delete this SMS Gateway??
                                </strong>
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={() => { toggleShow5(); Setvalue() }}>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn onClick={(e) => DeleteApi(e)}>Delete</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBAlert
                    color='success'
                    autohide
                    position='top-right'
                    delay={2000}
                    appendToBody
                    open={ResetToggle}
                    onClose={() => setResetToggle(false)}
                >Reset Done successfully
                </MDBAlert>
            </div>}

            <MDBAlert
                color="success"
                autohide
                position='top-center'
                delay={5000}
                appendToBody
                open={Alerttxt != ""}
                onClose={() => setAlerttxt("")}
            >
                {Alerttxt}
            </MDBAlert></>
    )
}

export default SmsGateway