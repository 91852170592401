import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useNavigate } from "react-router";
import Toast from "../components/Toast";
import { getAdminById, saveAdmin } from "../services/apiServices";
import { logEvent } from "../services/apiServices.js";
import {
  isEmpty,
  isLength,
  isValidAge,
  isValidPhoneNumber,
} from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

const Settings = (props) => {
  const [error, setError] = useState({});
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    age: "",
    gender: "",
    number: "",
  });

  const navigate = useNavigate();
  const [smessage, setSMessage] = useState(null);
  const [apiError, setApiError] = useState(false);

  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getAdminById(
          JSON.parse(localStorage.getItem("loggedUserInfo"))._id
        );

        console.log(response);
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {


          const record = await response.json();
          if (!record) {

            navigate("/");
            return;
          }
          if (localStorage.getItem("setting_save")) {
            setSMessage(localStorage.getItem("setting_save"));
            setTimeout(() => {
              setSMessage(null);
              localStorage.removeItem("setting_save");
            }, 5000);
          }

          setForm(record);
          if (!record.gender) {
            updateForm({ gender: "Male" });
          }
        }
      } else {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate]);

  const isAlphabetical = (str) => /^[a-zA-Z]+$/.test(str);

  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};
    if (isEmpty(form.firstname)) {
      errors.firstname = "First Name can't be blank";
    } else if (!isAlphabetical(form.firstname)) {
      errors.firstname = `Name should contain only alphabetical characters`;
    }
    if (isEmpty(form.lastname)) {
      errors.lastname = "Last can't be blank";
    } else if (!isAlphabetical(form.lastname)) {
      errors.lastname = `Name should contain only alphabetical characters`;
    }

    if (isEmpty(form.age)) {
      errors.age = "Age can't be blank";
    } else if (!isLength(form.age, { gte: 1, lte: 3, trim: true })) {
      errors.age = "Age must be between 15 and 100";
    } else if (!isValidAge(form.age)) {
      errors.age = "Please enter valid Age";
    }

    if (isEmpty(form.number)) {
      errors.number = "Contact Number can't be blank";
    } else if (!isValidPhoneNumber(form.number)) {
      errors.number = "Please enter valid Contact Number";
    }

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }
  function processCancel() {
    window.location.href = "/settingview";
  }
  async function onSubmit(e) {

    e.preventDefault();
    const updates = {
      fname: form.firstname,
      lname: form.lastname,
      age: form.age,
      gender: form.gender,
      number: form.number,
      _id: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id : "",
    };
    console.log(updates);
    let errors = validateForm(e);
    if (errors === true) {
      let saverep = await saveAdmin(
        updates,
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo"))._id : ""
      );
      localStorage.setItem("setting_save", "Profile saved successfully");
      console.log(await saverep.json());
      let lObj = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")) : "";
      lObj.name = updates.fname + " " + updates.lname;

      let ip = localStorage.getItem("ipaddress");

      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Settings Changed Successfull";
      let Eventtype = "setting";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
      await googleAnalytic("Settings", 'Settings', 'Save Button', 'Settings')
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      localStorage.setItem("loggedUserInfo", JSON.stringify(lObj));
      window.location.href = "/settingview";
    } else {
      setError(errors);
    }
  }

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => {
    setBasicModal(!basicModal)
  };


  return (
    <>
      <LeftPane isactive="9" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> :
        <div
          className="gym-main rightContent"
        >
          <MDBRow>
            <MDBCol lg={4} sm={12} md={12} className="pb-4">
              <MDBCard>
                <MDBCardBody>
                  <MDBCardTitle className="text-dark d-flex justify-content-between">
                    <label>Profile</label>

                    <button className="px-0 button-link link-color"
                      title="Edit" onClick={(e) => toggleOpen()}>
                      <MDBIcon fas icon="edit" className="ms-3 fs-6" />
                    </button>
                  </MDBCardTitle>
                  <MDBCardText>
                    <div className=" Business__Details__data  mt-2">
                      <label>First Name:<span className="text-danger">*</span></label> <label className="fw-600">{form.firstname}</label>
                    </div>
                    <div className=" Business__Details__data  mt-1">
                      <label>Last Name:<span className="text-danger">*</span></label> <label className="fw-600">{form.lastname}</label>
                    </div>
                    <div className=" Business__Details__data  mt-1">
                      <label>Age:<span className="text-danger">*</span></label> <label className="fw-600">{form.age}</label>
                    </div>
                    <div className=" Business__Details__data  mt-1">
                      <label>Gender:<span className="text-danger">*</span></label> <label className="fw-600">{form.gender}</label>
                    </div>
                    <div className=" Business__Details__data  mt-1">
                      <label>Mobile Number:<span className="text-danger">*</span></label> <label className="fw-600">{form.number}</label>
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          {smessage && (
            <h4>
              <Toast message={smessage} />
            </h4>
          )}


          <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
            <MDBModalDialog>
              {/* <form
              action="#"
              target="_blank"
              onSubmit={onSubmit}
              className="business-form w-100"
            > */}
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Edit Profile</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={processCancel}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className="BusinessPage__content">
                    <MDBCard className="shadow-0">
                      <MDBCardBody className=" py-0">
                        <MDBCardTitle>
                        </MDBCardTitle>
                        <MDBCardText>
                          <MDBRow>

                            <div className=" Business__Details__data">
                              <label>First Name:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="firstname"
                                  id="firs_name"
                                  placeholder="First Name"
                                  value={form.firstname}
                                  onChange={(e) =>
                                    updateForm({ firstname: e.target.value })
                                  }
                                />
                                {error.firstname && (
                                  <>
                                    <p className="clr-red">{error.firstname}</p>
                                  </>
                                )}

                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Last Name:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="lastname"
                                  id="last_name"
                                  placeholder="Last Name"
                                  value={form.lastname}
                                  onChange={(e) => updateForm({ lastname: e.target.value })}
                                />
                                {error.lastname && (
                                  <>
                                    <p className="clr-red">{error.lastname}</p>
                                  </>
                                )}

                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Age:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="age"
                                  id="age"
                                  placeholder="Age"
                                  value={form.age}
                                  onChange={(e) => updateForm({ age: e.target.value })}
                                />
                                {error.age && (
                                  <>
                                    <p className="clr-red">{error.age}</p>
                                  </>
                                )}
                              </label>
                            </div>
                            <div className=" Business__Details__data">
                              <label>Gender:<span className="text-danger">*</span></label> <label className="fw-600">

                                <select
                                  className="Business__input__adjust"
                                  name="gender"
                                  id="time"
                                  value={form.gender}
                                  onChange={(e) => updateForm({ gender: e.target.value })}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </label>
                            </div>

                            <div className=" Business__Details__data">
                              <label>Mobile Number:<span className="text-danger">*</span></label> <label className="fw-600">
                                <input
                                  className="Business__input__adjust"
                                  type="text"
                                  name="contact                                                                                                                                                          s_name"
                                  // id="contact"
                                  placeholder="Number"
                                  value={form.number}
                                  onChange={(e) => updateForm({ number: e.target.value })}
                                />
                                {error.number && (
                                  <>
                                    <p className="clr-red">{error.number}</p>
                                  </>
                                )}
                              </label>
                            </div>
                          </MDBRow>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                </MDBModalBody>

                <MDBModalFooter className="pe-3 pb-2">
                  <MDBBtn color='secondary' onClick={processCancel}>
                    cancel
                  </MDBBtn>
                  <MDBBtn onClick={onSubmit}>Save</MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
              {/* </form> */}
            </MDBModalDialog>
          </MDBModal>


        </div>}
    </>
  );
};

export default Settings;