import ReactGA from 'react-ga';
import { getGAEventTracker } from '../services/apiServices';

export async function googleAnalytic(flName, category, action, label, value) {

  const response = await getGAEventTracker();
  console.log(response);
  if (!response) {
    return;
  }

  if (response === "false") {
    return;
  }
  const record = await response.json();
  if (!record) {
    return;
  }

  const filteredfile = record && record.length > 0 ? record.find(item => item.filename === flName) : {}

  if (filteredfile.value === "1") {

    return ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value
    });
  } else {
    return;
  }

}
