
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';

import deleteImg from "./../components/images/delete.svg";

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBIcon
} from 'mdb-react-ui-kit';

import {
  getGBussinessInfo,
  logEvent,
  saveGBussinessInfo
} from "../services/apiServices.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";
function BussinessHours(props) {

  function getDayName(dayIndex) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[dayIndex - 1];
  }
  const [error, setError] = useState({});
  const [dpaymethods, setDpaymethods] = useState([]);
  const [userpaymethods, setUserpaymethods] = useState([]);
  const [selectedUOpts, setselecteUOpts] = useState([]);
  const [dgsttax, setDgsttax] = useState([]);
  const [ugsttax, setUgsttax] = useState([]);
  const [gstopt, setGstopt] = useState([]);
  const [emailrem, setEmailrem] = useState([]);
  const [smsrem, setSmsrem] = useState([]);
  const [dayid, setdayid] = useState([]);
  const [sunday, setSunday] = useState([]);
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [can, setCan] = useState(false)
  const [apiError, setApiError] = useState(false);
  const [businessForm, setbusinessForm] = useState({
    businessName: "",
    businessEmail: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    memberCapacity: "",
    state: "",
    city: "",
    address: "",
    prefix: "",
    dpaymethods: dpaymethods,
    userpaymethods: userpaymethods,
    dayid: dayid,
    dgsttax: dgsttax,
    ugsttax: ugsttax,
    shortcode: "",
    emailrem: emailrem,
    smsrem: smsrem,
    emailinter: "h",
    smsinter: "h",
    timezone: null,

  });
  useEffect(() => {
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );

        if (!response) {

          return;
        }
        if (response === "false") {
          setApiError(true);
        } else {
          const record = await response.json();
          if (!record) {
            return;
          }
          setbusinessForm(record);
          setEmailrem(record.emailrem);
          setSmsrem(record.smsrem);
          setDpaymethods(record.dpaymethods);
          setDgsttax(record.dgsttax);
          // if (record.ddgst === false) {
          //   setDdgst(false);
          // }
          let temOpts = [];
          record.userpaymethods.forEach((opt) => {
            if (opt.check) {
              temOpts.push(opt._id);
            }
          });
          setselecteUOpts(temOpts);
          setUserpaymethods(record.userpaymethods);
          let gsttaxoption = [];
          record.ugsttax.forEach((opt) => {
            if (opt.check) {
              gsttaxoption.push(opt._id);
            }
          });
          setGstopt(gsttaxoption);
          setUgsttax(record.ugsttax);
          setdayid(record.dayid);

          setSunday((prevSunday) =>
            record.sunday && record.sunday.length > 0
              ? record.sunday
              : [...prevSunday, { fromTime: "0", toTime: "23" }]
          );
          setMonday((prevMonday) =>
            record.monday && record.monday.length > 0
              ? record.monday
              : [...prevMonday, { fromTime: "0", toTime: "23" }]
          );
          setTuesday((prevTuesday) =>
            record.tuesday && record.tuesday.length > 0
              ? record.tuesday
              : [...prevTuesday, { fromTime: "0", toTime: "23" }]
          );
          setWednesday((prevWednesday) =>
            record.wednesday && record.wednesday.length > 0
              ? record.wednesday
              : [...prevWednesday, { fromTime: "0", toTime: "23" }]
          );
          setThursday((prevThursday) =>
            record.thursday && record.thursday.length > 0
              ? record.thursday
              : [...prevThursday, { fromTime: "0", toTime: "23" }]
          );
          setFriday((prevFriday) =>
            record.friday && record.friday.length > 0
              ? record.friday
              : [...prevFriday, { fromTime: "0", toTime: "23" }]
          );
          setSaturday((prevSaturday) =>
            record.saturday && record.saturday.length > 0
              ? record.saturday
              : [...prevSaturday, { fromTime: "0", toTime: "23" }]
          );
        }
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function adddays(id) {
    try {
      if (dayid.includes(id)) {
        // If id is already in dayid, remove it
        const nmethods = dayid.filter((item) => item !== id);
        setdayid(nmethods);
      } else {
        // If id is not in dayid, add it
        setdayid([...dayid, id]);
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  }

  function addsundayRow(e) {
    e.preventDefault();
    if (sunday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setSunday([...sunday, { fromTime: "0", toTime: "23", }]);
  }
  function deletsundayRow(e, index) {
    e.preventDefault();
    setSunday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }
  function Sundayfromtime(obj) {
    setSunday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Sundaytotime(obj) {
    setSunday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /*************************************************************/
  function addmondayRow(e) {
    e.preventDefault();
    if (monday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setMonday([...monday, { fromTime: "0", toTime: "23", }]);
  }
  function deletmondayRow(e, index) {
    e.preventDefault();
    setMonday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }
  function Mondayfromtime(obj) {
    setMonday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Mondaytotime(obj) {
    setMonday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /****************************************************************/
  function addtuesdayRow(e) {
    e.preventDefault();
    if (tuesday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setTuesday([...tuesday, { fromTime: "0", toTime: "23", }]);
  }
  function delettuesdayRow(e, index) {
    e.preventDefault();
    setTuesday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }
  function Tuesdayfromtime(obj) {
    setTuesday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Tuesdaytotime(obj) {
    setTuesday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /*********************************************************/
  function addwednesdayRow(e) {
    e.preventDefault();
    if (wednesday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setWednesday([...wednesday, { fromTime: "0", toTime: "23", }]);
  }
  function deletwednesdayRow(e, index) {
    e.preventDefault();
    setWednesday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }

  function Wednesdayfromtime(obj) {
    setWednesday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Wednesdaytotime(obj) {
    setWednesday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /*********************************************************/
  function addthursdayRow(e) {
    e.preventDefault();
    if (thursday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setThursday([...thursday, { fromTime: "0", toTime: "23", }]);
  }
  function deletthursdayRow(e, index) {
    e.preventDefault();
    setThursday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }

  function Thursdayfromtime(obj) {
    setThursday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Thursdaytotime(obj) {
    setThursday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /*********************************************************/
  function addfridayRow(e) {
    e.preventDefault();
    if (friday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setFriday([...friday, { fromTime: "0", toTime: "23", }]);
  }
  function deletfridayRow(e, index) {
    e.preventDefault();
    setFriday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }
  function Fridayfromtime(obj) {
    setFriday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Fridaytotime(obj) {
    setFriday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /*********************************************************/
  function addsaturdayRow(e) {
    e.preventDefault();
    if (saturday.length >= 10) {
      window.alert("Maximum limit reached");
      return;
    }
    setSaturday([...saturday, { fromTime: "0", toTime: "23", }]);
  }
  function deletsaturdayRow(e, index) {
    e.preventDefault();
    setSaturday((current) =>
      current.filter((obj, tindex) => tindex !== index));
  }

  function Saturdayfromtime(obj) {
    setSaturday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: obj.fromTime,
            toTime: eachObj.toTime,
          };
        }
        return eachObj;
      }); return newState;
    });
  }
  function Saturdaytotime(obj) {
    setSaturday((prevState) => {
      const newState = prevState.map((eachObj, index) => {
        if (index === obj.index) {
          return {
            fromTime: eachObj.fromTime,
            toTime: obj.toTime,
          };
        }
        return eachObj;
      });
      return newState;
    });
  }
  /*********************************************************/
  function processCancel() {
    setCan(!can)
    window.location.href = "/bInfo";

  }
  async function onSubmit(e) {
    e.preventDefault();
    if (can) {

      setCan(false);
      return;
    }
    console.log("onSubmit function called");
    userpaymethods.forEach((opt) => {
      opt.check = selectedUOpts.filter((item) => item === opt._id).length > 0;
    });
    ugsttax.forEach((opt) => {
      opt.check = gstopt.filter((item) => item === opt._id).length > 0;
    });

    const duplicateSlots = checkForDuplicateSlots();
    if (duplicateSlots.length > 0) {
      setError({ duplicateSlots });
      return;
    } else {
      setError({});
    }
    const sunday1 = sunday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");
    const monday1 = monday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");
    const tuesday1 = tuesday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");
    const wednesday1 = wednesday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");
    const thursday1 = thursday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");
    const friday1 = friday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");
    const saturday1 = saturday.filter((obj) => obj.fromTime !== "" || obj.toTime !== "");

    const updates = {
      businessName: businessForm.businessName,
      businessEmail: businessForm.businessEmail,
      firstName: businessForm.firstName,
      lastName: businessForm.lastName,
      contactNumber: businessForm.contactNumber,
      memberCapacity: businessForm.memberCapacity,
      city: businessForm.city,
      address: businessForm.address,
      prefix: businessForm.prefix,
      shortcode: businessForm.shortcode,
      emailinter: businessForm.emailinter,
      smsinter: businessForm.smsinter,
      timezone: businessForm.timezone,
      dayid: dayid,
      sunday: sunday1,
      monday: monday1,
      tuesday: tuesday1,
      wednesday: wednesday1,
      thursday: thursday1,
      friday: friday1,
      saturday: saturday1,
    };
    if (updates) {
      let saverep = await saveGBussinessInfo(updates, businessForm._id);
      await saverep.json();
      let ip = localStorage.getItem("ipaddress");
      let browserinfo = browserName + "-" + browserVersion + ".0.0";
      let flag = "Bussiness Info Updated Successfull";
      let Eventtype = "bussinessinfo";
      let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
      let username1 = JSON.parse(localStorage.getItem("loggedUserInfo")).email;
      await googleAnalytic("BussinessHours", 'BussinessInfo', 'Save Button', 'BussinessHours Updated')
      // ReactGA.event({
      //   category: 'BussinessInfo',
      //   action: 'Save Button',
      //   label: 'BussinessHours Updated',
      // });
      logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
      if (!can)
        localStorage.setItem("business_save", "Profile saved successfully");
      window.location.href = "/bInfo";
    } else {
    }
  }

  function checkForDuplicateSlots() {
    const allDays = [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
    const duplicateSlots = [];

    allDays.forEach((day, dayIndex) => {
      const uniqueSlots = new Set();
      const duplicateIndices = [];

      day.forEach((slot, slotIndex) => {
        const slotString = `${slot.fromTime}-${slot.toTime}`;
        if (uniqueSlots.has(slotString)) {
          duplicateIndices.push(slotIndex);
        } else {
          uniqueSlots.add(slotString);
        }
      });

      if (duplicateIndices.length > 0) {
        duplicateSlots.push({ day: dayIndex + 1, indices: duplicateIndices });
      }
    });

    return duplicateSlots;
  }
  const handleReload = () => {
    window.location.reload();
  }

  return (
    <React.Fragment>
      <>
        {apiError ? <PageError handleReload={handleReload} /> : <form
          action="#"
          target="_blank"
          onSubmit={onSubmit}
        >
          <div className=" ">
            <MDBCard className=" shadow-0">
              <MDBCardBody className='my-0 py-0 business_hours'>
                <MDBCardText>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Sunday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(1)}
                            onChange={() => adddays(1)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {sunday.length > 0 && sunday.map((item, tindex) => (
                              <><li className="Business_Details_data" key={tindex}>
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>
                                  {/* <input type="time" value={item.fromTime} disabled={!dayid.includes(1)}
                                      onChange={(e) => Sundayfromtime({
                                        fromTime: e.target.value,
                                        index: tindex,
                                      })} /> */}
                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(1)}
                                    onChange={(e) => Sundayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(1)}
                                    onChange={(e) => Sundaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addsundayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn>
                                    :
                                    <span className="Delete__icon ms-2" >
                                      <button
                                        className="delete-button"
                                        onClick={(e) => deletsundayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button>
                                    </span>
                                  }
                                </div></li></>))}</ol>
                        </div>
                      </div>
                    </div>
                    {/* *********************************************************************************************** */}
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Monday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(2)}
                            onChange={() => adddays(2)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {monday.length > 0 && monday.map((item, tindex) => (
                              <><li className="Business_Details_data" key={tindex}>
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>

                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(2)}
                                    onChange={(e) => Mondayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(2)}
                                    onChange={(e) => Mondaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addmondayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn>
                                    :

                                    <span className="Delete__icon ms-2" >
                                      <button
                                        className="delete-button"
                                        onClick={(e) => deletmondayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button>
                                    </span>}
                                </div></li></>))}</ol>
                        </div></div></div>

                    { /* *********************************************************************************************** */}
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Tuesday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(3)}
                            onChange={() => adddays(3)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {tuesday.length > 0 && tuesday.map((item, tindex) => (
                              <><li className="Business_Details_data" key={tindex}>
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>
                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(3)}
                                    onChange={(e) => Tuesdayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(3)}
                                    onChange={(e) => Tuesdaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addtuesdayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn> :
                                    <span className="Delete__icon ms-2" >

                                      <button
                                        className="delete-button"
                                        onClick={(e) => delettuesdayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button>

                                    </span>}
                                </div></li></>))}</ol>
                        </div></div></div>
                    { /* *********************************************************************************************** */}
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Wednesday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(4)}
                            onChange={() => adddays(4)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {wednesday.length > 0 && wednesday.map((item, tindex) => (
                              <><li key={tindex} className="Business_Details_data">
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>
                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(4)}
                                    onChange={(e) => Wednesdayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(4)}
                                    onChange={(e) => Wednesdaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addwednesdayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn> :
                                    <span className="Delete__icon ms-2" >

                                      <button
                                        className="delete-button"
                                        onClick={(e) => deletwednesdayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button>
                                    </span>}
                                </div></li></>))}</ol>
                        </div></div></div>
                    { /* *********************************************************************************************** */}
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Thursday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(5)}
                            onChange={() => adddays(5)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {thursday.length > 0 && thursday.map((item, tindex) => (
                              <><li key={tindex} className="Business_Details_data">
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>
                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(5)}
                                    onChange={(e) => Thursdayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(5)}
                                    onChange={(e) => Thursdaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addthursdayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn> :
                                    <span className="Delete__icon ms-2" >


                                      <button
                                        className="delete-button"
                                        onClick={(e) => deletthursdayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button></span>}
                                </div></li></>))}</ol>
                        </div></div></div>
                    { /* *********************************************************************************************** */}
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Friday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(6)}
                            onChange={() => adddays(6)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {friday.length > 0 && friday.map((item, tindex) => (
                              <><li key={tindex} className="Business_Details_data">
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>
                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(6)}
                                    onChange={(e) => Fridayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(6)}
                                    onChange={(e) => Fridaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addfridayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn> :
                                    <span className="Delete__icon ms-2" >

                                      <button
                                        className="delete-button"
                                        onClick={(e) => deletfridayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button></span>}
                                </div></li></>))}</ol>
                        </div></div></div>
                    { /* *********************************************************************************************** */}
                    <div className="d-flex align-items-start my-1 justify-content-between schedules">
                      <div className="d-flex align-items-center justify-content-between w-200px dayslabel">
                        <label>Saturday</label>
                        <label className="switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            checked={dayid.includes(7)}
                            onChange={() => adddays(7)} />
                          <span className="slider round"></span>
                        </label></div>
                      <div className="d-flex justify-content-end w-100 daysdata">
                        <div className="d-flex align-items-center">
                          <ol className="mb0 p-0" >
                            {saturday.length > 0 && saturday.map((item, tindex) => (
                              <><li key={tindex} className="Business_Details_data">
                                <div className="d-flex align-items-center">
                                  <label className="px-2">From</label>
                                  <select
                                    name="shrs"
                                    id="shr"
                                    className="slotdrop mx-1"
                                    value={item.fromTime} disabled={!dayid.includes(7)}
                                    onChange={(e) => Saturdayfromtime({
                                      fromTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  <label className="px-2">To</label>
                                  <select
                                    name="shrs" id="shr" className="slotdrop mx-1" value={item.toTime} disabled={!dayid.includes(7)}
                                    onChange={(e) => Saturdaytotime({
                                      toTime: e.target.value,
                                      index: tindex,
                                    })}
                                  >
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i} value={i}>{i === 0 ? '12 AM' : `${i} AM`}</option>
                                    ))}
                                    {Array.from({ length: 12 }, (_, i) => (
                                      <option key={i + 12} value={i + 12}>{i === 0 ? '12 PM' : `${i} PM`}</option>
                                    ))}
                                  </select>
                                  {tindex === 0 ?
                                    <MDBBtn onClick={(e) => addsaturdayRow(e)} className="ms-2" ><MDBIcon fas icon="plus" /></MDBBtn> :
                                    <span className="Delete__icon ms-2" >

                                      <button
                                        className="delete-button"
                                        onClick={(e) => deletsaturdayRow(e, tindex)}
                                        style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                      >
                                        <img src={deleteImg} width="15px" alt="Delete" />
                                      </button></span>}
                                </div></li></>))}</ol>
                        </div></div></div>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
          {Object.keys(error).length > 0 && (
            <>
              <div className="text-danger">Please remove duplicate slots:</div>
              <div className="error-container">
                {error.duplicateSlots.map((dayError, index) => (
                  <>
                    <span key={dayError.day}  >
                      <span className="text-danger">
                        <b>
                          {`${getDayName(dayError.day)}: `}
                        </b>
                        {`${dayError.indices.map(index => `Slot ${index + 1}`).join(', ')}`}


                      </span>{error.duplicateSlots.length !== (index + 1) && <span className="mx-1"><strong>|</strong></span>}
                    </span>
                  </>
                ))}
              </div>
            </>
          )}
          <div class="modal-footer pb-0">
            <button class="ripple ripple-surface btn btn-secondary" onClick={() => processCancel()}>
              Cancel
            </button>
            <button class="ripple ripple-surface btn btn-primary" type="submit" >
              Save
            </button>
          </div>
        </form>}

      </>
    </React.Fragment>
  )
}
export default BussinessHours

