import { MDBInputGroup } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import { updateLocation } from "../services/apiServices";
// import LocationPicker from 'react-location-picker';

{/* <script
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyACJr7xzWvhrGlvSSU-YbMfiscS2iZUosA&libraries=places&callback=initMap"
    async defer></script> */}

/* Default position */
const defaultPosition = {
    lat: 17.43496886763314,
    lng: 78.35971199722289,
};

const GeoLocation = (props) => {
    console.log(props);
    const [position, setPosition] = useState(defaultPosition);
    const [location, setLocation] = useState('');
    const [address, setAddress] = useState('');
    const [radius, setRadius] = useState(100)
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState(0);
    const [cursor, SetCusor] = useState(0);
    const handleSearch = async () => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`);
            if (response.ok) {
                const data = await response.json();
                if (data.length > 0) {
                    setError(0);
                    setSuggestions(data);
                } else {
                    // Handle case when no results are found
                    console.error('No results found');
                    setError(1)
                }
            } else {
                // Handle HTTP error
                console.error('HTTP error:', response.status);
            }
        } catch (error) {
            // Handle other errors
            console.error('Error fetching suggestions:', error);
        }
    };

    const handleSelectLocation = (selectedLocation) => {
        setLocation(selectedLocation.display_name);
        setAddress(selectedLocation.display_name);
        setPosition({ lat: parseFloat(selectedLocation.lat), lng: parseFloat(selectedLocation.lon) });
        setSuggestions([]);
    };
    // const handleLocationChange = ({ position, address, places }) => {
    //     console.log(position);
    //     console.log(address);
    //     console.log(places);
    //     // Set new location
    //     setPosition(position);
    //     setLocation('');
    //     setAddress('');
    // }
    async function onSaveButton(e) {
        e.preventDefault();
        let id = JSON.parse(sessionStorage.getItem("loggedUserInfo")) ? JSON.parse(sessionStorage.getItem("loggedUserInfo")).gymid : "";
        const updates = {
            latitude: position.lat,
            longitude: position.lng,
            address: address,
            gymid: id,
            radius: radius,
        };
        console.log(updates);
        await updateLocation(id, updates);
        props.close()
    }

    useEffect(() => {
        setPosition({ lat: props.data.latitude, lng: props.data.longitude });
        setAddress(props.data.address);
        setRadius(props.data.radius);
    }, [])

    return (
        <>
            <input
                type="text"
                placeholder="Enter location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
            />
            <button onClick={handleSearch}>Search</button>
            {suggestions.length > 0 && (
                <div className="searchbar-result gym-container">
                    {suggestions.length > 0 &&
                        suggestions.map((item, i) => (
                            <div
                                key={i}
                                className={
                                    cursor === i
                                        ? "d-flex home-select-drp home-active"
                                        : "d-flex home-select-drp"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelectLocation(item)}
                            >
                                <div className="w-91 posSearchData_1 ">{item.display_name}</div>
                            </div>
                        ))}
                </div>
            )}

            {error === 1 && <div><span>No results Found</span></div>}

            <div>
                <div>
                    {/* <LocationPicker
                        containerElement={<div style={{ height: '100%' }} />}
                        mapElement={<div style={{ height: '400px' }} />}
                        defaultPosition={position}
                        position={position}
                        zoom={12}
                        onChange={handleLocationChange}
                    /> */}
                </div>
                {/* <div className='mt-3'>
                    <h3>Selected Location Details:</h3>
                    <p><strong> Address:</strong> {address ? address : ""}</p>
                    <p><strong> Latitude:</strong> {position ? position.lat : ""}</p>
                    <p><strong> Longitude:</strong> {position ? position.lng : ""}</p>
                </div> */}
            </div>
            <div className=" Business__Details__data ">
                <label className='w-90'>Radius </label>
                <label className="fw-600 w-100">
                    <MDBInputGroup className='mb-0 w-100' textAfter='Meters'>
                        <input type='number' min="10" max="1000" className='form-control w-100'
                            onChange={(e) => { setRadius(e.target.value) }}
                            value={radius}
                        />
                    </MDBInputGroup>
                </label>
            </div>
            <div class="modal-footer pb-0">
                <button class="ripple ripple-surface btn btn-secondary"
                    onClick={() => props.close()}
                    >
                    Cancel
                </button>
                <button class="ripple ripple-surface btn btn-primary"
                    onClick={(e) => onSaveButton(e)}
                    >
                    Save
                </button>
            </div>
        </>);
};

export default GeoLocation;
