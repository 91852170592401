import React, { Component } from "react";


class Comingsoon extends Component {
    render() {
        return (
            <div>
                   <h1>Gym App Privacy Policy</h1> 
            </div>
            
        )
    }
}

export default Comingsoon;
