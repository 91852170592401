import React, { useEffect, useState } from "react";

const Popup = (props) => {
  const [className, setClassName] = useState("modal-dialog " + props.ptype);


  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        // if(window.confirm("Are you sure want to close this popup?")){
        props.handleClose();
        //}
      }
    };
    if (props.from === "transactions") {
      return;
    } else {
      window.addEventListener("keydown", handleEsc);

      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }
  }, []);

  function closePopup() {
    // if(window.confirm("Are you sure want to close this popup?")){
    props.handleClose();
    //}
  }
  return (
    <div
      className="modal fade show"
      id="staticBackdrop"
      data-mdb-backdrop="static"
      data-mdb-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className={className}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {props.title}
            </h5>
            {props.hideClose ? <></> : <button
              type="button"
              className="btn-close"
              data-mdb-dismiss="modal"
              aria-label="Close"
              onClick={closePopup}
            ></button>}
          </div>
          <div className={`modal-body ${props.style}`} >
          {props.content}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Popup;
