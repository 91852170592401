import React, { useState, useEffect } from 'react';

const ReverseTimer = ({ minute, checkingPaymentStatus, requestPaymentStatus }) => {
    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 20;
    const ALERT_THRESHOLD = 15;

    const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };

    const [timeLeft, setTimeLeft] = useState(minute * 60);
    const [remainingPathColor, setRemainingPathColor] = useState(COLOR_CODES.info.color);

    useEffect(() => {
        let counter = 0;
        const timerInterval = setInterval(async () => {
            counter++;
            if (counter % 10 === 0) {
                let value = await checkingPaymentStatus(timeLeft);
                if (value) {
                    clearInterval(timerInterval);
                }
            }
            if (counter % 20 === 0) {
                requestPaymentStatus();
            }
            setTimeLeft((prevTime) => {
                if (prevTime === 0) {
                    clearInterval(timerInterval);
                    checkingPaymentStatus(0);
                    return 0;
                } else {
                    return prevTime - 1;
                }
            });
        }, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    }, []);

    useEffect(() => {
        setRemainingPathColor(getRemainingPathColor(timeLeft));
    }, [timeLeft]);

    const getRemainingPathColor = (timeLeft) => {
        if (timeLeft <= COLOR_CODES.alert.threshold) {
            return COLOR_CODES.alert.color;
        } else if (timeLeft <= COLOR_CODES.warning.threshold) {
            return COLOR_CODES.warning.color;
        } else {
            return COLOR_CODES.info.color;
        }
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    const calculateTimeFraction = () => {
        return timeLeft / (minute * 60);
    }

    const setCircleDasharray = () => {
        const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
        return circleDasharray;
    }

    return (
        <div className="base-timer">
            <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g className="base-timer__circle">
                    <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                    <path
                        id="base-timer-path-remaining"
                        strokeDasharray={setCircleDasharray()}
                        className={`base-timer__path-remaining ${remainingPathColor}`}
                        d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
                    ></path>
                </g>
            </svg>
            <div className='base-timer__label flex-column'>
                <span id="base-timer-label" className="line-height-normal">{formatTime(timeLeft)}</span>
                <div className='fs-6'>Minutes</div>
            </div>
        </div>
    );
}

export default ReverseTimer;
