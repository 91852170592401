import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCompressPaymentLink, } from '../services/apiServices';

function PaymentLinkPage() {
    const [expired, setExpired] = useState(false);
    const { id } = useParams();
    console.log(id);
    const encryptedId = id;
    async function fetchData() {
        try {
            const response = await getCompressPaymentLink(encryptedId);
            if (response) {
                const recinfo = await response.json();
                console.log(recinfo);
                if (recinfo.status == 0) {
                    const paymentUrl = recinfo.url;
                    console.log("generated payment url::", paymentUrl);
                    if (window.location.origin.includes('localhost') || window.location.origin.includes('beta')) {
                        if (window.confirm("Are you sure you want to open the link in new tab?")) {
                            window.location.href = paymentUrl; // Use window.location.href to open in the same tab
                        }
                    } else {
                        window.location.href = paymentUrl;
                    }
                } else {
                    if (!recinfo.status) {
                        setExpired(true);
                    } else {
                    const redirectUrl = `${window.location.origin}/paymentlink?id=${encryptId(recinfo._id)}`;
                    window.location.href = redirectUrl;
                    console.error("Payment link expired or invalid");
                    }
                }
            } else {
                setExpired(true);
            }
        } catch (error) {
            console.error("Error fetching payment URL:", error);
        }
    }
    function encryptId(id) {
        return btoa(id);
    }
    function decryptId(encryptedId) {
        return atob(encryptedId);
    }
    useEffect(() => {
        if (encryptedId) {
            fetchData();
        }
    }, [encryptedId]);

    return (<>
        {expired && <>
            <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center flex-column py-4">
                    <h5 >Payment Link Expired.</h5>
                </div>
            </div>
        </>}</>);
}

export default PaymentLinkPage;