import React, { Component } from "react";


class Faqs extends Component {
    render() {
        return (
            <div>
                    Gym App FAQs
            </div>
            
        )
    }
}

export default Faqs;
