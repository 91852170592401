import React, { useState, useEffect } from 'react';

const Timer = ({ time }) => {
    const [elapsedTime, setElapsedTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const checkinTime = new Date(time);
            const diff = now - checkinTime; // Difference in milliseconds

            // Convert milliseconds to hours, minutes, and seconds
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            // Ensure two digits by padding with a leading zero if necessary
            const formattedHours = ("0" + hours).slice(-2);
            const formattedMinutes = ("0" + minutes).slice(-2);
            const formattedSeconds = ("0" + seconds).slice(-2);

            // Format the time string
            const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} Hrs`;
            setElapsedTime(formattedTime);
        }, 1);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [time]);

    return (
        <span>
            {elapsedTime}
        </span>
    );
};

export default Timer;