
import React from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';

const Loader = (props) => {
  return (
    <>
      <div className={props.Dashboard === "1" ? "loading12" : "loadingclass"}>
        <MDBBtn color="primary">
          <span class="spinner-border spinner-border-sm me-2" role="status">
          </span>
          Loading...
        </MDBBtn>
      </div>
    </>
  );
};

export default Loader;