import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import { MDBDatatable,  MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";
import { getSMSTEMP } from '../services/apiServices';
import MessageFrequency from './frequency';
import SmsGateway from './SmsGateway';
import WhatsappGateway from './WhatsAppGateway';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane
} from 'mdb-react-ui-kit';


function MessageSettings() {
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };
    const [fillActive, setFillActive] = useState('tab1');
    
    const handleFillClick = (value) => {
        if (value === fillActive) {
            return;
        }

        setFillActive(value);
    };

  
    const navigate = useNavigate();


    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""

    const [allTempletes, setAllTempletes] = useState([])
   

    useEffect(() => {
        async function fetchPData() {
            if (localStorage.getItem("loggedUserInfo")) {
                const response = await getSMSTEMP(gymid);
                if (!response) {
                    return;
                }
                const templetes = await response.json();
                setAllTempletes(templetes);
            }
        }
        fetchPData();
    }, [basicActive === 'tab1']);
    console.log(allTempletes);


    const actionData_1 = {
        columns: [
            { label: 'Template', field: 'Template', sort: false },
            { label: 'SMS', field: 'SMSSettings', sort: false },
            { label: 'WhatsApp', field: 'WhatsappSettings', sort: false },
            { label: 'Email', field: 'EmailSettings', sort: false },
            { label: 'Edit', field: 'Actions', sort: false },
        ],
        rows: allTempletes.filter(item => item.type === "member").map((templateObj, index) => {
            return {
                Template: templateObj.smscategory,
                SMSSettings: (
                    <>
                        <div className='cursor-normal'>
                            {templateObj.toggle ?
                                <MDBIcon fas icon="check" color='success' className='fs-5' /> :
                                <MDBIcon fas icon="times" color='danger' className='fs-5' />}
                        </div>
                    </>

                ),
                WhatsappSettings: (
                    <>
                        <div className='cursor-normal'>
                            {templateObj.wtsapptoggle ?
                                <MDBIcon fas icon="check" color='success' className='fs-5' /> :
                                <MDBIcon fas icon="times" color='danger' className='fs-5' />}
                        </div>
                    </>
                ),

                EmailSettings: (
                    <>
                        <div className='cursor-normal'>
                            {templateObj.emailtoggle ?
                                <MDBIcon fas icon="check" color='success' className='fs-5' /> :
                                (templateObj.smscategory === "MEMBERSHIP_PAYMENT_ALERT" ? <MDBIcon fas icon="times" color='danger' className='fs-5' />
                                    : <div className='fw-bold'>NA</div>)
                            }
                        </div>
                    </>

        
                ),
                Actions: (
                    <div onClick={() => navigate(`/editmessage`, { state: { row: templateObj, index: index } })} className='text-center'>
                        <MDBIcon fas icon="edit" className="fs-5" />
                    </div>
                )
            };
        }),
    };

    const actionData_2 = {
        columns: [
            { label: 'Template', field: 'Template', sort: false },
            { label: 'SMS', field: 'SMSSettings', sort: false },
            { label: 'WhatsApp', field: 'WhatsappSettings', sort: false },
            { label: 'Email', field: 'EmailSettings', sort: false },
            { label: 'Actions', field: 'Actions', sort: false },
        ],
        rows: allTempletes.filter(item => item.type !== "member").map((templateObj, index) => {
            return {
                Template: templateObj.smscategory,
                SMSSettings: (
                    <div className='cursor-normal'>
                        {templateObj.toggle ?
                            <MDBIcon fas icon="check" color='success' className='fs-5' /> :
                            <MDBIcon fas icon="times" color='danger' className='fs-5' />}
                    </div>
                ),
                WhatsappSettings: (
                    <div className='cursor-normal'>
                        {templateObj.wtsapptoggle ?
                            <MDBIcon fas icon="check" color='success' className='fs-5' /> :
                            <MDBIcon fas icon="times" color='danger' className='fs-5' />}
                    </div>
                ),

                EmailSettings: (
                    <div className='cursor-normal'>
                        {templateObj.emailtoggle ?
                            <MDBIcon fas icon="check" color='success' className='fs-5' /> :
                            (templateObj.smscategory === "MEMBERSHIP_PAYMENT_ALERT" ? <MDBIcon fas icon="times" color='danger' className='fs-5' />
                                : <div className='fw-bold'>NA</div>)
                        }
                    </div>
                ),
                Actions: (
                    <div onClick={() => navigate(`/editmessage`, { state: { row: templateObj, index: index } })} className='text-center'>
                        <MDBIcon fas icon="edit" className="fs-5" />
                    </div>
                )
            };

        }),
    };
    return (
        <React.Fragment>
            <>
                <LeftPane isactive="4" />
                <Header />
                <div className="gym-main gym-main-div rightContent">
                    {/* <MDBIcon fas icon="arrow-left" className="fs-3" onClick={() => showThisInf()} /> */}
                    <div className="gym-container gym-cnr" id="showcase">


                        <MDBTabs className='mb-3'>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                                    Templates
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleBasicClick('tab5')} active={basicActive === 'tab5'}>
                                    Notification Frequency
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                                    SMS Settings
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                                    Whatsapp Settings
                                </MDBTabsLink>
                            </MDBTabsItem>
                        

                        </MDBTabs>

                        <MDBTabsContent>
                            <MDBTabsPane open={basicActive === 'tab1'}>
                                <MDBTabs className='mb-3'>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                                            Member
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleFillClick('tab2')} active={fillActive === 'tab2'}>
                                            Owner
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                </MDBTabs>

                                <MDBTabsContent>
                                    <MDBTabsPane open={fillActive === 'tab1'}>
                                        <div className="sms-settings-table custon_DT smsTable">
                                            <MDBDatatable pagination={false} hover data={actionData_1} />
                                        </div>
                                    </MDBTabsPane>
                                    <MDBTabsPane open={fillActive === 'tab2'}>
                                        <div className="sms-settings-table custon_DT smsTable">
                                            <MDBDatatable pagination={false} hover data={actionData_2} />
                                        </div>
                                    </MDBTabsPane>
                                </MDBTabsContent>
                            </MDBTabsPane>
                            <MDBTabsPane open={basicActive === 'tab2'}>
                              

                                <MDBTabsContent>
                                   
                                    <SmsGateway />
                                 
                                </MDBTabsContent>

                            </MDBTabsPane>
                            <MDBTabsPane open={basicActive === 'tab3'}>
                                

                                <MDBTabsContent>
                                   
                                   
                                    <WhatsappGateway />
                               
                                </MDBTabsContent>


                            </MDBTabsPane>
                           
                            <MDBTabsPane open={basicActive === 'tab5'}>
                                <MessageFrequency allData={allTempletes} />
                            </MDBTabsPane>

                        </MDBTabsContent>
                    </div>
                </div>
            </>
        </React.Fragment>
    );
}

export default MessageSettings;
