import React, { Component } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

import logImg from '../../assets/images/GymLogo.png';
import logBg from "../../../components/images/login-page-compressed.png";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";

/**
 * Reset-password component
 */
class ResetPassword extends Component {
    render() {

        return (
            <React.Fragment>
                <div className="back-to-home">
                    <Link to="/">
                        <MDBBtn>
                            <MDBIcon fas icon="arrow-left" />
                        </MDBBtn>
                    </Link>
                </div>
                {/* Hero Start */}
                <section className="cover-user bg-white">
                    <div className="container-fluid px-0">
                        <Row className="g-0 position-relative">
                            <Col lg={4} className="cover-my-30 order-2">
                                <div className="cover-user-img d-flex align-items-center">
                                    <Row>
                                        <div className="col-12">
                                            <div className="d-flex flex-column auth-hero">
                                                <div className="mt-md-5 text-center">
                                                    <Link to="#"><img src={logImg} className="gymlogo1" alt="" /></Link>
                                                </div>
                                                <div className="title-heading my-lg-auto">
                                                    <div className="login-page border-0" style={{ zIndex: 1 }}>
                                                        <CardBody className="p-0">
                                                            <h4 className="card-title">Recover password</h4>
                                                            <form className="login-form mt-4">
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <p className="text-muted">Please enter your registered email address. You will receive a link to create new password via email.</p>
                                                                        {/* {<p className="text-muted">Please enter a valid/registered email-id.</p>} */}
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Email address<span className="text-danger">*</span></label>
                                                                            <input type="email" className="form-control" placeholder="Enter Your Email Address" name="email" required />
                                                                        </div>
                                                                    </Col>

                                                                    <Col lg={12}>
                                                                        <div className="d-grid">
                                                                            <button className="btn btn-primary ">Send</button>
                                                                        </div>
                                                                    </Col>

                                                                    <div className="mx-auto">
                                                                        <p className="mb-0 mt-3"><span className="text-dark me-2 font-14px">Remember your password ?</span> <Link to="/login" className="text-dark fw-bold">Login</Link></p>
                                                                    </div>
                                                                </Row>
                                                            </form>
                                                        </CardBody>
                                                    </div>
                                                </div>
                                                <div className="mb-md-5 text-center">
                                                    <p className="mb-0 text-muted">© {(new Date().getFullYear())}{" "} Gym Admin Online.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>

                            <div className="col-lg-8 offset-lg-4 padding-less img order-1" style={{ backgroundImage: `url(${logBg})` }} data-jarallax='{"speed": 0.5}'>
                                <div className="greenoverlay"> </div>
                            </div>
                        </Row>
                    </div>
                </section>
                {/* end section */}
            </React.Fragment>
        )
    }
}

export default ResetPassword;
