import rupeeImg from "../components/images/Transactions.svg";
import checkImg from "../components/images/circle-check.svg";
import { React, useState, useEffect } from "react";
import { format } from "date-fns";
import { saveTransaction, checkMembers, getGBussinessInfo, uploadInvoice, sendReceiptMail, numbermessage, getCheckStaff, getInvoicehtmlcontent } from "../services/apiServices.js";
import Timer from "../components/timer";
// import logo from "../components/images/GymLogo.png";
import logo from "../components/images/Easy-MembrLogo.png";
import ReactGA from 'react-ga';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCheckbox,
  MDBInput,
  MDBTooltip,
  MDBRadio,
} from 'mdb-react-ui-kit';
import PageError from "./PageError";


export default function ReceiptPrint(props) {

  const ids = ["1"];
  const [data1, setData] = useState("");
  const [basicModal, setBasicModal] = useState(false);
  const toggleOpen = () => setBasicModal(!basicModal);
  const [selItems, setSelItems] = useState([]);
  const [allmember, setAllmember] = useState([]);
  const [allmembers, setAllMembers] = useState([]);
  const [userdata, setUserdata] = useState([])
  const gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
  const [trainers, setTrainers] = useState([]);
  const [apiError, setApiError] = useState(false);

  // const [trainerid, settrainerid] = useState(); //by vamshi
  const [transactiond, settransactiond] = useState(localStorage.getItem("transactionId"))

  const [smessage, setSMessage] = useState(false);
  const paymentData = JSON.parse(localStorage.getItem("selItems"))
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [mailData, setMailData] = useState([])
  const [uploaded, setUploaded] = useState(true)
  const [selectedEmails, setSelectedEmails] = useState(mailData || []);
  const [trans, settras] = useState(null)
  const [OtherData, setOtherData] = useState("")
  const [numberData, setNumberData] = useState([])
  const [selectedNumbers, setSelectedNumbers] = useState(numberData || []);
  const [selectType, setSelectType] = useState("email")
  // const multipayment=paymentData.payinfo.info
  const [payInfo, setpayInfo] = useState(JSON.parse(localStorage.getItem("selItems")));
  console.log(payInfo)
  const [price, setPrice] = useState(
    paymentData
      ? (paymentData.balance === 0 ? paymentData.price : (Number(paymentData.payinfo.info.cash_amt) + Number(paymentData.payinfo.info.cc_amt) + Number(paymentData.payinfo.info.upi_amt) + Number(paymentData.payinfo.info.upi_input) + Number(paymentData.payinfo.info.userpaymethods) + Number(paymentData.payinfo.info.wallet_amt) + Number(paymentData.payinfo.info.wallet_input)))
      : "0.0"
  );
  const logourl = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo)
  const [trainerList, setTrainerList] = useState("");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);


  console.log(price);
  console.log(props);

  async function processandReturn(event, id) {
    event.preventDefault();
    printhandler(id)
  }

  async function printhandler(id, tselItems, userdata) {
    let listeditem = tselItems || selItems;
    console.log(listeditem);
    let data = userdata || data1;

    const billdata = listeditem.map((value, index) => {
      const taxIncluded = value.Item.taxcheck ? "Included" : "Excluded";
      return {
        sno: index + 1,
        name: value.Item.name,
        qty: value.quantity,
        price: value.Item.price * value.quantity,
        check: value.Item.taxcheck,
        discount: value.members && value.members.reduce((memberAcc, member) =>
          memberAcc + (member.discount ? member.discount.amount : 0), 0),
        overAllTax: value.taxamounts ? value.taxamounts : [{ TaxCheck: `${taxIncluded}` }],
        cost: value.Item.taxcheck ?
          parseFloat((value.Item.price * value.quantity) /
            (1 + (value.taxamounts.reduce((acc, obj) => acc +
              (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)))).toFixed(2)
          : value.Item.price * value.quantity,

        discountper: value.members.reduce((memberAcc, member) => {
          const discountValue = member.discount ? member.discount.EnterValue : 0;
          const actualamount = value.Item.taxcheck ?
            parseFloat((value.Item.price * value.quantity) /
              (1 + (value.taxamounts.reduce((acc, obj) => acc +
                (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0)))).toFixed(2)
            : value.Item.price * value.quantity;

          const memberDiscount = member.discount && member.discount.discountType === "percent" ?
            discountValue : (discountValue / actualamount) * 100;

          return memberAcc + memberDiscount;
        }, 0),
      };
    });

    console.log(billdata);

    let userdetails = {
      name: data.firstName + " " + data.lastName,
      number: data.contactNumber,
      state: data.state,
      business: data.businessName,
      city: data.city,
      address: data.address,
      membername: listeditem.length > 0 && listeditem[0].members.length > 0 ? (listeditem[0].members[0].fname + ' ' + listeditem[0].members[0].lname) : "",
      membernumber: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].number : "",
      memberaddress: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].address : "",
      membercity: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].city : "",
      membercuntry: listeditem.length > 0 && listeditem[0].members.length > 0 ? listeditem[0].members[0].country : "",
      invoice: parseFloat(data._id) + format(new Date(), "MM"),
      gst: data.dgsttax.length > 0 && data.dgsttax[0] === 1 ? data.gstnumber : "",
    }
    console.log(userdetails);
    let taxdetails = data;
    let date = format(new Date(), "dd-MM-yyyy");
    let paidPrice = Number(payInfo.totalPrice ? payInfo.totalPrice : 0) - payInfo.balance;
    let payObj = {
      dueAmount: payInfo.type === 2 ? payInfo.balance : payInfo.balance,
      paidAmount: payInfo.type === 2 ? paidPrice : (Number(payInfo.price) - payInfo.balance),
      totalPrice: payInfo.type === 2 ? Number(payInfo.totalPrice) : Number(payInfo.price),
    }

    const updates = {
      logourl: logourl,
      userdetails: userdetails,
      taxdetails: taxdetails,
      payObj: payObj,
      billdata: billdata,
      transactiond: transactiond,
      totalPrice: payObj.totalPrice,
      date: date
    }
    const htmlContent = await getInvoicehtmlcontent(updates, transactiond)
    if (htmlContent === "false") {
      setApiError(true)
      return
    }
    const record = await htmlContent.json();

    console.log(record, '9999999999999999999');


    /**
      * Upload Invoice to server
      */
    if (id === "pdfupload") {
      try {
        if (payInfo.PaymentLinkId == -1) {
          const saverep = await uploadInvoice(
            { invoiceId: transactiond, Gid: gymid, url: window.location.origin },
            transactiond,
          );
          console.log("uploaded...");
          setUploaded(false)
          if (saverep === "false") {
            setApiError(true)
          } else {
            const url = await saverep.json()
            console.log(url.pdfFileLink);
            setInvoiceUrl(url.pdfFileLink);
          }
        }
      } catch (e) {
        console.log("Errore::::::::::::::::: " + e);
      }
      clearTransactoion();
      return;
    }

    if (id === 'send') {


    } else {
      const print_area = window.open('', '_blank');
      print_area.document.write(record.htmlContent);
      print_area.document.close();
      print_area.focus();
      setTimeout(() => {
        print_area.print();
      }, 500)

      // Clear transaction data
      clearTransactoion();
    }
  }

  function clearTransactoion() {
    localStorage.removeItem("selItems");
    localStorage.removeItem("allmember");
    localStorage.removeItem("transactionId");
    localStorage.removeItem("selectedusers");
    localStorage.removeItem("allData");
  }

  async function processAllmember() {
    if (localStorage.getItem("loggedUserInfo")) {
      let tsata = JSON.parse(localStorage.getItem("selItems"));
      console.log(tsata);
      if (tsata) {
        tsata.transactiondate = (!props.transactionDate) ? new Date() : props.transactionDate
      }
      let txnmember = [];
      for (const x in tsata) {
        console.log(tsata[x]);
        if (tsata[x].isplan === 1 || tsata[x].isplan === 0 || tsata[x].isplan === 3) {
          console.log(tsata[x].members);
          txnmember.push(tsata[x]);
          setAllMembers(allmembers.concat(tsata[x].members));
        }
      }
      let tallm = allmembers;
      setAllmember(tallm);

      setSelItems(txnmember);
      let tmmember = JSON.parse(localStorage.getItem("allmember"));
      setAllmember(tmmember);
      console.log(txnmember);
      console.log(allmember);
      let saverep = 0;
      let tid = localStorage.getItem("transactionId");
      console.log(tsata)
      try {
        saverep = await saveTransaction(
          { ...tsata, sId: JSON.parse(localStorage.getItem("loggedUserInfo"))._id, tId: tid },
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        );
        // console.log(await saverep.json());
        if (!saverep) {
          return;
        }
        if (saverep === "false") {
          return;
        }

        settras(await saverep.json())


      } catch (e) {
        console.log("Errore::::::::::::::::: " + e);
      }
      let selItems = txnmember
      // printhandler('send', selItems, record)
      localStorage.removeItem("selItems");
      localStorage.removeItem("allmember");
      localStorage.removeItem("transactionId");
      /**
       * Uploading invoice pdf to server
       */

      const response = await getGBussinessInfo(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (response === "false") {
        setApiError(true)
      } else {
        const record = await response.json();
        printhandler('pdfupload', selItems, record)
        console.log(selItems);
        setUserdata(selItems)
      }
    }
  }

  const [checkedMembers, setCheckedMembers] = useState([]);

  const [ErrorTrainer, setErrorTrainer] = useState()

  async function checkinUser(e, mId, plan) {
    e.preventDefault();
    e.stopPropagation();
    console.log("mId :: " + mId);
    const selectedTrainer = trainers.find((trainer) => trainer.mid === mId);
    let checked = checkedMembers.filter((item) => item === mId);
    let chekinflag = checked.length === 0 ? "1" : "0";
    if (plan.forcetrainer && !selectedTrainer && chekinflag === "1") {
      setErrorTrainer({ mId: mId, plan: plan });
      return;
    } else {
      let updates = {
        checkFlag: chekinflag,
        gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
        mid: mId,
        _id: mId,
        date: new Date(),
        fvalue: "pos",
        selectedplan: plan
      };

      if (selectedTrainer) {
        updates = { ...updates, trainer: selectedTrainer.trainer, trainerid: selectedTrainer.trainerid, };
      }

      await checkMembers(updates, mId);

      if (chekinflag === "1") {
        setCheckedMembers([...checkedMembers, mId]);
        // setCheckedMembers((prevCheckedMembers) => [...prevCheckedMembers, mId]);

      } else {
        const nusers = checkedMembers.filter((item) => item !== mId);
        setCheckedMembers(nusers);
        const nonSelectedTrainer = trainers.filter((trainer) => trainer.mid !== mId);
        setTrainers(nonSelectedTrainer);
      }
      setErrorTrainer();
    }
  }


  /**
* The below useEffect is for run the autoCheckinMembers.
* created by vamshi
* @useEffect
* @description
* This useEffect has been created on [13-3-2024] to run autoCheckinMembers function with respect to the selItems.
*/
  useEffect(() => {
    if (selItems && selItems.length > 0 && trans != null) {

      selItems.forEach((item) => {
        autoCheckinMembers(item.Item._id, item.members);
      });
    }

    function autoCheckinMembers(planId, members) {
      console.log(planId);
      const plan = selItems.find(item => item.Item._id === planId);
      console.log(plan, '99999999999999999999999999999');
      if (plan && plan.Item && plan.Item.autocheckin === true) {
        console.log("inside checkin");
        members.forEach(async (member) => {
          let updates = {
            checkFlag: "1",
            gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
            mid: member._id,
            _id: member._id,
            date: new Date(),
            fvalue: "pos",
            selectedplan: plan.Item
          };

          await checkMembers(updates, member._id);

          setCheckedMembers(prevCheckedMembers => [...prevCheckedMembers, member._id]);

        }
        );
      }
    }
  }, [trans]);

  async function fetchData() {
    setPrice(
      paymentData
        ? (paymentData.balance === 0 ? paymentData.price : (Number(paymentData.payinfo.info.cash_amt) + Number(paymentData.payinfo.info.cc_amt) + Number(paymentData.payinfo.info.upi_amt) + Number(paymentData.payinfo.info.upi_input) + Number(paymentData.payinfo.info.userpaymethods) + Number(paymentData.payinfo.info.wallet_amt) + Number(paymentData.payinfo.info.wallet_input))) : "0.0")
    if (localStorage.getItem("loggedUserInfo")) {
      const res = await getCheckStaff(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!res) {

        return;
      }
      if (res === "false") {
        setApiError(true)
      } else {
        const usersData = await res.json();
        setTrainerList(usersData)
      }
      const response = await getGBussinessInfo(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (response === "false") {
        setApiError(true)
      } else {
        const record = await response.json();
        if (!record) {
          return;
        }
        setData(record);
      }
    }
  }

  useEffect(() => {
    setpayInfo(JSON.parse(localStorage.getItem("selItems")));

    fetchData();
    processAllmember()
  }, []);


  const handleCheckboxEmailChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter((selectedEmail) => selectedEmail !== email);
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  const handleCheckboxNumberChange = (number) => {
    setSelectedNumbers((prevSelectedNumbers) => {
      if (prevSelectedNumbers.includes(number)) {
        return prevSelectedNumbers.filter((selectedNumber) => selectedNumber !== number);
      } else {
        return [...prevSelectedNumbers, number];
      }
    });
  };


  const handlesendReceipt = (e) => {
    e.preventDefault();
    // const uniqueEmailAddresses = ["tvamshif@gmail.com", "vamshibuss99@gmail.com"];
    console.log(userdata);


    const uniqueEmailsSet = new Set();
    const uniqueNumbersSet = new Set()
    userdata.forEach(item => {
      if (item.members && Array.isArray(item.members)) {
        item.members.forEach(member => {
          if (member && member.email) {
            uniqueEmailsSet.add(member.email);
          }
          if (member && member.number) {
            uniqueNumbersSet.add(member.number);
          }
        });
      }
    });

    const uniqueEmailAddresses = Array.from(uniqueEmailsSet);
    uniqueEmailAddresses.push(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo"))).email : "")
    const uniqueNumbers = Array.from(uniqueNumbersSet);
    uniqueNumbers.push(localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo"))).number : "")
    // console.log(uniqueEmailAddresses);
    setMailData(uniqueEmailAddresses)
    setSelectedEmails(uniqueEmailAddresses)
    setNumberData(uniqueNumbers)
    setSelectedNumbers(uniqueNumbers)
    toggleOpen()
  }



  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (mobileNumber) => {
    // const mobileRegex = /^\d{10}$/;
    const mobileRegex = /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;

    //this validation of moblile number is valid for the below case
    //6856438922
    //7856128945
    //8945562713
    //9998564723
    //+91-9883443344
    //09883443344
    //919883443344
    //0919883443344
    //+919883443344
    //+91-9883443344
    //0091-9883443344
    //+91 9883443344
    //+91-785-612-8945
    //+91 999 856 4723
    return mobileRegex.test(mobileNumber);
  };

  const sendReceipt = async (e) => {
    e.preventDefault();

    console.log(selectedEmails);
    console.log(selectedNumbers);
    console.log("OtherMails", OtherData);

    const uniqueEmailsSet = new Set([...selectedEmails]);
    const uniqueNumbersSet = new Set([...selectedNumbers]);

    const otherDataArray = OtherData.split(',').map(data => data.trim());

    let response = "";

    if (selectType === "email") {
      otherDataArray.forEach(email => uniqueEmailsSet.add(email));
      otherDataArray.forEach(email => {
        if (isValidEmail(email)) {
          uniqueEmailsSet.add(email);
        } else {
          console.log(`Invalid email format: ${email}`);
        }
      });

      uniqueEmailsSet.forEach(email => {
        if (!isValidEmail(email)) {
          uniqueEmailsSet.delete(email);
        }
      });
      setMailData(Array.from(uniqueEmailsSet));


      const logo = localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : "") : "";
      let saverep = await sendReceiptMail(localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : '', Array.from(uniqueEmailsSet), invoiceUrl, logo ? logo : '');
      if (saverep === "false") {
        setApiError(true)
      } else {
        const responseData = await saverep.json();

        response = responseData.sendEmail
      }

    } else {
      const number = "6281619099"
      otherDataArray.forEach(number => uniqueNumbersSet.add(number));
      otherDataArray.forEach(number => {
        if (isValidPhoneNumber(number)) {
          uniqueNumbersSet.add(number);
        } else {
          console.log(`Invalid number format: ${number}`);
        }
      });
      uniqueNumbersSet.forEach(number => {
        if (!isValidPhoneNumber(number)) {
          uniqueNumbersSet.delete(number);
        }
      });
      setNumberData(Array.from(uniqueNumbersSet));
      const respon = await numbermessage({ phone: number, url: invoiceUrl });
      console.log(respon);
      if (response === "false") {
        setApiError(true)
      } else {
        let resData = await respon.json();
        console.log(resData);
        response = resData.message
        console.log("sms sent successfully ");
      }
    }

    toggleOpen()

    if (response === "success") {
      setSMessage(true);
      setTimeout(() => {
        setSMessage(false);
      }, 5000);
    }
    else {
      setSMessage(false)
    }

  };

  const handleHompage = () => {
    window.location.href = "/home";

    window.location.reload();
  }

  function onChangeTrainerFunction(mId, selectedtrainerid) {
    if (selectedtrainerid !== "") {
      const selectedTrainer = trainerList.find(trainer => trainer._id === selectedtrainerid);
      const trainerName = selectedTrainer.firstname + " " + selectedTrainer.lastname;

      const existing = trainers.filter(trainer => trainer.mid === mId);
      if (existing.length > 0) {
        const nonExisting = trainers.filter(trainer => trainer.mid !== mId);
        let updatedTrainer = { mid: mId, trainer: trainerName, trainerid: selectedTrainer._id };
        const updatedTrainers = [...nonExisting, updatedTrainer];
        setTrainers(updatedTrainers);
      } else {
        setTrainers([...trainers, { mid: mId, trainer: trainerName, trainerid: selectedTrainer._id }]);
      }
    } else {
      const nonExisting = trainers.filter(trainer => trainer.mid !== mId);
      const updatedTrainers = [nonExisting];
      setTrainers(updatedTrainers);
    }
  }
  console.log(trainers);

  const handleReload = () => {
    window.location.reload();
  }
  console.log(checkedMembers, '000000000000000000000000000');
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <div className="">
        <div className="text-right mb20 mob-trans-btns">
          <div className="gym-container" id="showcase">
            {smessage && (
              <div>
                {/* <Toast message={smessage} /> */}

                <div id="snackbar-submit" className={`show-submit alert ${selectType === 'email' ? (mailData && mailData.length > 0 ? "alert-success" : "alert-danger") : (numberData && numberData.length > 0 ? "alert-success" : "alert-danger")} alert-fixed SentMailAllert w-30 mob-w-100`}>

                  <div className="text-left">
                    {selectType === 'email' ? (mailData && mailData.length > 0 ? "Email sent successfully!!" : "Email not sent sentsuccessfully!! ") : (numberData && numberData.length > 0 ? "Sms sent successfully" : "Sms not sent successfully")}
                    <div>
                      {selectType === 'email' ? " Email(s):" : "Moblie Number(s):"}
                    </div>
                    <div>
                      {selectType === 'email' && (mailData && mailData.length > 0 ? (
                        <span>{mailData.join(', ')}</span>
                      ) : (
                        <span>No emails available</span>
                      ))}
                      {selectType === 'number' && (numberData && numberData.length > 0 ? (
                        <span>{numberData.join(', ')}</span>
                      ) : (
                        <span>No number available</span>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
          <button
            type="button"
            onClick={handleHompage}
            className="btn btn-secondary text-right"
            member-mdb-dismiss="modal"
          >
            Home
          </button>



          <div className="d-flex  txt_wrapper">
            <MDBBtn
              type="button"
              className="btn btn-primary  mt-2 me-2"
              member-mdb-dismiss="modal"
              // disabled={uploaded}
              onClick={(e) => {

                processandReturn(e, 'print')
              }}
            >

              Print Receipt
            </MDBBtn>

            <MDBTooltip placement="bottom-end" tag='a' title={uploaded ? "Generating invoice.Please Wait" : "Send Receipt"} >
              <MDBBtn
                type="button"
                className="btn btn-primary  mt-2 "
                member-mdb-dismiss="modal"
                disabled={uploaded}

                onClick={(e) => handlesendReceipt(e)}
              >
                Send Receipt
              </MDBBtn>
            </MDBTooltip>


            {/* </a> */}


          </div>

        </div>

        <MDBModal show={basicModal} setShow={setBasicModal} open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Send Reciept</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="d-flex align-items-center mb-2">

                  <MDBRadio
                    name='inlineRadio'
                    id='inlineRadio1'
                    value='email'
                    label='Email'
                    inline
                    checked={selectType === 'email'}
                    onChange={() => {
                      setSelectType('email')
                      setOtherData("")
                    }}

                  />

                </div>
                {selectType === 'email' && mailData && mailData.length > 0 && mailData.map((email, index) => (
                  <div key={index}>
                    {email && <MDBCheckbox
                      id={`flexCheckDefault${index}`}
                      label={email}
                      checked={selectedEmails.includes(email)}
                      onChange={() => handleCheckboxEmailChange(email)}
                    />}
                  </div>
                ))}
                {selectType === 'number' && numberData && numberData.length > 0 && numberData.map((number, index) => (
                  <div key={index}>
                    {number && <MDBCheckbox
                      id={`flexCheckDefault${index}`}
                      label={number}
                      checked={selectedNumbers.includes(number)}
                      onChange={() => handleCheckboxNumberChange(number)}
                    />}
                  </div>
                ))}
                <div className="mt-2">
                  <label>
                    Enter other {selectType === 'number' ? "number" : "email"}(s):
                  </label>
                  <MDBInput value={OtherData} onChange={(e) => setOtherData(e.target.value)} />
                  <p className="text-secondary m-0 mt-2">Note: To enter multiple {selectType === 'number' ? "number" : "email"}, use comma (,) separated values. </p>
                </div>
              </MDBModalBody>

              <MDBModalFooter className="pe-4 pb-3">
                <MDBBtn color='secondary' onClick={toggleOpen}>
                  Close
                </MDBBtn>
                <MDBBtn
                  disabled={mailData.length > 0 ? false : true}
                  onClick={(e) => {
                    sendReceipt(e)
                  }}>Send</MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <div className="printpayment" id="1234">
          <div id={ids[0]}>
            <div className="d-flex">
              <img src={checkImg} alt="succes" className="w-payment" />
              <div className="ml30">
                <div className="paymentsuccess">Payment Successful</div>
                <div className="paymentsuccess-numbr">
                  <span className="bold">Amount:</span>{" "}
                  <img src={rupeeImg} alt="amount" className="w-10" height="10" width="10" />{" "}
                  &nbsp;
                  {price}
                </div>
                <h5 className="text-center line-height font-15">
                  {" "}
                  <span className="bold">Transaction ID: </span>
                  {transactiond}
                </h5>
              </div>
            </div>
            <div className="mt30">
              {selItems && selItems.map(
                (item, index) =>
                  item && (
                    <>
                      <div className="d-flex justify-between w-100 m-auto paymentpopup">
                        <div className="w-100">
                          <div className="row m-0">
                            <h5 className="col-lg-7 col-md-4 col-lp-4 line-height mb0 print_ricipt_table border__rnone">
                              {" "}
                              <span className="bold"> Plan/Addon: </span>
                              {item.Item.name}
                            </h5>
                            <h5 className="col-lg-3 col-md-4 col-lp-4 line-height mb0 print_ricipt_table border__rnone">
                              {" "}
                              <span className="bold"> Start Date: </span>
                              {new Date(
                                new Date(item.idate).getTime() - 1
                              ).toLocaleDateString("en-IN", {
                                timeZone: "Asia/Kolkata",
                              })}
                            </h5>
                            <h5 className="col-lg-2 col-md-4 col-lp-4 line-height mb0 print_ricipt_table">
                              {" "}
                              <span className="bold"> Quantity: </span>
                              {item.quantity}
                            </h5>
                          </div>



                          <div className="routing-border"></div>
                          <div className="mt-3">
                            <h5>Members:</h5>

                            <div className="d-flex flex-column pb-3">
                              {item.members &&
                                item.members.map((member, tin) => (
                                  <>
                                    <div className="border p-3 d-flex align-items-center justify-content-between shadow-2 w-100 mt-2">
                                      <div className="w-35">
                                        <strong className="m-0 fs-5">{member.fname} {member.lname}</strong>
                                        <p className="m-0 small">{member.sex}{member.sex && (member.age || member.number || member.weight) ? ', ' : ''} {member.age}{member.age && (member.number || member.weight) ? 'y, ' : ''}{" "}
                                          {member.weight}{member.weight && member.number ? 'kgs, ' : ''} {member.number}</p>
                                      </div>
                                      <div className="gym-section">

                                        <label className="me-2 ">Select Trainer: </label>

                                        {checkedMembers.filter(
                                          (item) => item === member._id
                                        ).length == 0 && <>
                                            <select
                                              className="slotdrop w-100 m-0 p-1 "
                                              onChange={(e) => onChangeTrainerFunction(member._id, e.target.value)}
                                            >
                                              <option value=''>Select trainer</option>
                                              {trainerList && trainerList.length > 0 && trainerList.map((data, index) => (
                                                <option key={index} value={data._id}>
                                                  {(data.firstname !== "" || data.lastname !== "") ? data.firstname + ' ' + data.lastname : data.email}                                                </option>
                                              ))}
                                            </select>
                                          </>}
                                        <label className="me-3 clr-red">
                                          {ErrorTrainer && ErrorTrainer.mId === member._id && ErrorTrainer.plan._id === item.Item._id &&
                                            "Please select a trainer"
                                          }
                                        </label>
                                        {checkedMembers.filter(
                                          (item) => item === member._id
                                        ).length > 0 && <label className="me-3">
                                            {trainers && trainers.length > 0 && trainers.find(trainer => trainer.mid === member._id) ?
                                              trainers.find(trainer => trainer.mid === member._id).trainer :

                                              'None Selected'
                                            }
                                          </label>}

                                      </div>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        className={
                                          checkedMembers.filter(
                                            (item) => item === member._id
                                          ).length > 0
                                            ? "checkindate checkinbuttonout"
                                            : "checkindate"
                                        }
                                        onClick={(e) =>
                                          checkinUser(
                                            e,
                                            member._id,
                                            item.Item
                                          )
                                        }
                                      >
                                        <input
                                          type="button"
                                          value={
                                            checkedMembers.filter(
                                              (item) => item === member._id
                                            ).length > 0
                                              ? "Check-Out"
                                              : "Check-In"
                                          }
                                          className={
                                            checkedMembers.filter(
                                              (item) => item === member._id
                                            ).length > 0
                                              ? "checkinbuttonout"
                                              : "checkinbutton"
                                          }
                                        />

                                        {checkedMembers.filter(
                                          (item) => item === member._id
                                        ).length > 0 && (
                                            <p className="d-flex m-inherit f-9">
                                              <Timer
                                                member={member.fname}
                                                time={
                                                  new Date()
                                                }
                                              />
                                              &nbsp;
                                            </p>
                                          )}
                                      </div>


                                    </div >
                                  </>
                                ))}
                            </div>
                          </div>

                        </div>

                      </div>
                    </>
                  )
              )}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
