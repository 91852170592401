// ReportsGraph.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// Chart options


// Data for the chart
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July',];
export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [2000, 3000, 2500, 4000, 3500, 2800, 4500],
            backgroundColor: 'rgb(255, 99, 132)',
        },
        {
            label: 'Dataset 2',
            data: [3000, 2500, 3500, 2800, 4500, 2000, 4000],
            backgroundColor: 'rgb(75, 192, 192)',
        },
        {
            label: 'Dataset 3',
            data: [2500, 3500, 2800, 4500, 2000, 4000, 3000],
            backgroundColor: 'rgb(53, 162, 235)',
        },

    ],
};

// ReportsGraph component
function ReportsGraph(props) {
    const options = {
        indexAxis: 'y',
        plugins: {
            title: {
                display: true,
                text: `${props.info && props.info.title} Report`,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: props.info && props.info.xtitle,
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: props.info && props.info.ytitle,
                },
            },
        },
    };

    return <Bar options={options} data={props.data} />;
}

export default ReportsGraph;