import copy from 'clipboard-copy';
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import ReactGA from 'react-ga';
import { QRCode } from 'react-qr-svg';
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import Toast from "../components/Toast";
// import logo from "../components/images/GymLogo.png";
import logo from "../components/images/Easy-MembrLogo.png";
import { getGBussinessInfo, getWaiverSettingsbyGymid, updateLocation, getLocation, updateWaiverSettings, getPaymentLinkSetting, reqConfirmPaymentStatus } from "../services/apiServices.js";
import BusinessPage from "./BusinessPage";
import BussinessHours from "./BussinessHours.js";
import PaymentMethods from "./PaymentMethods.js";
import TaxSettings from "./TaxSettings";
import WaiverSettings from "./waiverSettings.js";

import {
  MDBAlert,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBChip,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow
} from 'mdb-react-ui-kit';
import PageError from './PageError';
import GeoLocation from '../components/geolocationfencing.js';
const BusinessInfo = () => {
  // const [plansInfo, setPlansInfo] = useState(JSON.parse(localStorage.getItem("planInfo")));
  const [info, setInfo] = useState("");
  const [smessage, setSMessage] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [PaymentLink, setPaymentLink] = useState();
  const [Error2, setError2] = useState("")
  const [WaiverForm, setWaiverForm] = useState({
    uniqueNumber: "",
    title: "Digital Waiver Form",
    emergencycontact: 2,
    bloodgroup: 2,
    address: 2,
    phone: 1,
    email: 1,
    checkboxcontent: `By checking this box, I acknowledge that I have read, understood, and agree to the terms and conditions
outlined in the waiver form. I accept full responsibility for my participation, or for the participation of the
minor I am legally responsible for, in climbing activities at Crag Studio, and recognize the inherent risks
involved.`,
    agreement:
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
deserunt mollit anim id est laborum`
    ,
  });

  const logourl = (localStorage.getItem("loggedUserInfo") ? (JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymlogoUrl : logo) : logo)
  const [imageLoaded, setImageLoaded] = useState(true);
  const [geolocation, setGeolocation] = useState({})
  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    const handleImageError = () => {
      setImageLoaded(false);
    };
    const imgElement = document.createElement("img");
    imgElement.onload = handleImageLoad;
    imgElement.onerror = handleImageError;
    imgElement.src = logourl;

    return () => {
      imgElement.onload = null;
      imgElement.onerror = null;
    };
  }, [logourl]);

  function encryptId(id) {
    return btoa(id);
  }
  useEffect(() => {
    if (PaymentLink && PaymentLink.status == 2) {
      setTimeout(() => {
        checkingConfirmStatus();
      }, 5000);
    }
  }, [PaymentLink]);
  async function checkingConfirmStatus() {
    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
    try {
      let response = await reqConfirmPaymentStatus(gymid);
      if (!response) {
        return;
      }
      if (response === "false") {
        return;
      }
      const resObject = await response.json();
      console.log(resObject);
      setPaymentLink(resObject);
      if (resObject && resObject.status && (resObject.status === 1 || resObject.status === 0)) {
        if (resObject.status === 1) {
          setError2("Successfully confirmed payment.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  function generateUniqueKey(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueKey = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueKey += characters.charAt(randomIndex);
    }
    return uniqueKey;
  }

  function saveWaiverSettings(data) {
    setWaiverForm(data);
    DigitalWaverShow();
  }

  useEffect(() => {
    if (localStorage.getItem("loggedUserInfo")) {
      fetchData();
      fetchGeolocation();
    } else {
      window.location.href = "/login"
    }
    async function fetchGeolocation() {
      const getlocation = await getLocation(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
      );
      if (!getlocation) {
        return;
      }

      if (getlocation === "false") {
        return;
      }
      const getloc = await getlocation.json();
      console.log(getloc);
      if (!getloc.gymid) {
        let id = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
        const updates = {
          latitude: 17.43496886763314,
          longitude: 78.35971199722289,
          address: "",
          gymid: id,
          radius: 100,
        };
        console.log(updates);
        const saverep = await updateLocation(id, updates);
        const recinf = await saverep.json();
        setGeolocation(recinf);
      } else {
        setGeolocation(getloc);
      }
    }
    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const waiverRes = await getWaiverSettingsbyGymid(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (waiverRes === "false") {
          setApiError(true)
        } else {
          const waiver = await waiverRes.json();
          console.log(waiver);
          if (!waiver.gymid) {
            const updates = {
              gymid: JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
              uniqueNumber: generateUniqueKey(6),
              emergencycontact: 2,
              bloodgroup: 2,
              address: 2,
              phone: 1,
              email: 1,
              title: `Digital Waiver Form`,
              checkboxcontent: `By checking this box, I acknowledge that I have read, understood, and agree to the terms and conditions
  outlined in the waiver form. I accept full responsibility for my participation, or for the participation of the
  minor I am legally responsible for, in climbing activities at Crag Studio, and recognize the inherent risks
  involved.`,
              agreement:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
  deserunt mollit anim id est laborum`
            }
            let saverep = await updateWaiverSettings(updates, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
            const recinf = await saverep.json();
            console.log(recinf);
            setWaiverForm(recinf);
          } else {
            setWaiverForm(waiver);
          }
        }
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {

          const recinfo = await response.json();
          console.log(recinfo);
          if (!recinfo) {
            return;
          }

          if (localStorage.getItem("business_save")) {
            setSMessage(localStorage.getItem("business_save"));
            setTimeout(() => {
              setSMessage(null);
              localStorage.removeItem("business_save");
            }, 5000);
          }
          const updatedInfo = { ...recinfo };

          if (!updatedInfo.prefix) {
            updatedInfo.prefix = updatedInfo.businessName ? updatedInfo.businessName.replace(/\s+/g, '').substring(0, 4) : "GYMA";
          }
          if (!updatedInfo.shortcode) {
            updatedInfo.shortcode = "GYMADN";
          }
          if (!updatedInfo.country) {
            updatedInfo.country = "India";
            updatedInfo.state = "Telangana";
          }
          if (updatedInfo.dayid.includes(1) && updatedInfo.sunday.length === 0) {
            updatedInfo.sunday = [{ fromTime: "0", toTime: "23" }];
          } if (updatedInfo.dayid.includes(2) && updatedInfo.monday.length === 0) {
            updatedInfo.monday = [{ fromTime: "0", toTime: "23" }];
          } if (updatedInfo.dayid.includes(3) && updatedInfo.tuesday.length === 0) {
            updatedInfo.tuesday = [{ fromTime: "0", toTime: "23" }];
          } if (updatedInfo.dayid.includes(4) && updatedInfo.wednesday.length === 0) {
            updatedInfo.wednesday = [{ fromTime: "0", toTime: "23" }];
          } if (updatedInfo.dayid.includes(5) && updatedInfo.thursday.length === 0) {
            updatedInfo.thursday = [{ fromTime: "0", toTime: "23" }];
          } if (updatedInfo.dayid.includes(6) && updatedInfo.friday.length === 0) {
            updatedInfo.friday = [{ fromTime: "0", toTime: "23" }];
          } if (updatedInfo.dayid.includes(7) && updatedInfo.saturday.length === 0) {
            updatedInfo.saturday = [{ fromTime: "0", toTime: "23" }];
          }
          setInfo(updatedInfo);
          console.log(updatedInfo);
        }
      }
    }
  }, []);
  async function fetchPaymentLink() {
    const paymentLinkSetting = await getPaymentLinkSetting(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
    if (!paymentLinkSetting) {
      return;
    }
    if (paymentLinkSetting == "false") {
      return;
    }
    const paymentLinkSettings = await paymentLinkSetting.json();
    if (paymentLinkSettings.gymid) {
      console.log(paymentLinkSettings);
      setPaymentLink(paymentLinkSettings);
      if (paymentLinkSettings.status == 2) {
        checkingConfirmStatus();
      }
    }
  }
  useEffect(() => {
    fetchPaymentLink();
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);



  const [paymentMethods, setPaymentMethods] = useState(false);
  const paymentMethodsShow = () => setPaymentMethods(!paymentMethods);
  const [taxSettings, setTaxSettings] = useState(false);
  const taxSettingsShow = () => setTaxSettings(!taxSettings);

  const [locationSetting, setLocationSetting] = useState(false);
  const locationFenchingShow = () => setLocationSetting(!locationSetting);

  const [bussinessHours, setBussinessHours] = useState(false);
  const bussinessHoursShow = () => setBussinessHours(!bussinessHours);

  const [DigitalWaver, setDigitalWaver] = useState(false);
  const DigitalWaverShow = () => setDigitalWaver(!DigitalWaver);

  const [QRDigitalWaver, setQRDigitalWaver] = useState(false);
  const QRDigitalWaverShow = () => setQRDigitalWaver(!QRDigitalWaver);

  const [QRDigitalWaverconfirmation, setQRDigitalWaverconfirmation] = useState(false);
  const QRDigitalWaverconfirmationShow = () => setQRDigitalWaverconfirmation(!QRDigitalWaverconfirmation);
  const [copyLink, setcopyLink] = useState(false);

  let uniqueId = WaiverForm.uniqueNumber;
  const encryptedId = encryptId(uniqueId);
  const pathAndQuery = `/waiver?id=${encryptedId}`;
  const baseUrl = window.location.origin;
  const fullUrl = baseUrl + pathAndQuery;
  const link = fullUrl;
  const reGenerateLink = async () => {
    const updates = {
      gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
      uniqueNumber: generateUniqueKey(6),
    };
    let saverep = await updateWaiverSettings(updates, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
    if (saverep === "false") {
      setApiError(true)
    } else {
      const recinfo = await saverep.json();
      console.log(recinfo);
      setWaiverForm(recinfo);
    }
    QRDigitalWaverconfirmationShow();
  };
  const handleCopyLink = () => {
    copy(link);
    setcopyLink(true);
  };
  // const handlePreview = () => {
  //   window.open(link, '_self');
  // };
  const modalBodyRef = useRef(null);
  // <link rel="icon" href="../../build/favicon.ico" />
  // <link rel="icon" href=${faviconUrl}/>
  const handlePrintPDF = () => {
    const contentToPrint = `
    <html>
      <head>
      <title>Gym Management</title>
  <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
  <link rel="icon" href="/favicon.ico" />
          </head>
      <body style="background-color:#d7e1ee;">
        <center>
        <div style="
        min-width: 50%;
        background-color: #fff;
        padding: 0px 0px 160px 0px;
        border-radius: 7px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
        ">
        <div className='d-flex align-items-center Waiver-logo'>
        <div style="display: flex;align-items: center;justify-content: center;">
          <img src=${imageLoaded ? logourl : logo} style="width:140px" />
          <div style="display: flex;align-items: flex-start;flex-direction: column;margin-top: 0.5rem;border-left: 2px solid #e0e0e0;padding-left: 0.5rem;">
            <h4 style="color: #193b8b; margin:0px; !important; font-family: &quot;Inter&quot;, sans-serif;">${info && info.businessName ? info.businessName : "GYM Admin Online"}</h4>
            <div className='d-flex'>
              <p style="margin:0px; !important;margin-top: 10px; font-family: &quot;Inter&quot;, sans-serif;">
              ${(info.address || info.city) ? ((info.address ? info.address : '') +
        ((info.address && info.city) ? ', ' : '') +
        (info.city ? (info.city) : '')
      ) : "Suadaraiah Vignana Kendram, Gachibowli, Hyderabad"}
              </p>
              </div>
              </div>
              </div>
              </div>
              <div style="border-bottom: 2px solid #ddd;margin: 15px 0px;width: 90%;"></div>
              <div>
              <h1 style="padding-top: 20px;font-family: &quot;Inter&quot;, sans-serif;">${(WaiverForm && WaiverForm.title) ? WaiverForm.title : "Digital Waiver Form"}</h1><br/>
              <div>
              ${ReactDOMServer.renderToString(<QRCode style={{ width: '300px', height: '300px' }} value={link} />)}
              </div>
              </div>
              </div>
        </center>
      </body>
    </html>
  `;


    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    // Write content to the iframe
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(contentToPrint);
    iframeDoc.close();

    // Print the content
    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 200);
  };

  const truncateContent = (content, maxLength) => {
    if (content && (content.length > maxLength)) {
      return content.substring(0, maxLength) + '...';
    } else {
      return content;
    }
  };
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  return (
    <>
      <LeftPane isactive="2" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> : <div
        className="gym-main rightContent"
      >
        <div className="gym-container" id="showcase">
          {smessage && (
            <div>
              <Toast message={smessage} />
            </div>
          )}
        </div>

        <div>
          <div className="business_Info_page" id="contact">
            <MDBRow>
              <MDBCol sm="12" md="4" lg="4">

                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle>
                      <label>Business Details </label>
                      <div>
                        <MDBIcon fas icon="edit" onClick={() => toggleShow()} />
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      <MDBRow>

                        <MDBCol>
                          <div className="payment-span chip">
                            <label>Business Name:</label> <label className="fw-600">{info.businessName}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Owner First Name:</label> <label className="fw-600">{info.firstName}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Owner Last Name:</label> <label className="fw-600">{info.lastName}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Email:</label> <label className="fw-600">{info.businessEmail}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Contact No:</label> <label className="fw-600">{info.contactNumber}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>City:</label> <label className="fw-600">{info.city}</label>
                          </div>
                          <div className="payment-span chip">
                            <label>State:</label> <label className="fw-600">{info.state}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Country:</label> <label className="fw-600">{info.country}</label>
                          </div>
                          <div className="payment-span chip">
                            <label>Timezone:</label> <label className="fw-600">{info.timezone && info.timezone.value}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Transaction Id Prefix:</label> <label className="fw-600">{info.prefix}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>SMS ShortCode:</label> <label className="fw-600">{info.shortcode}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Member Capacity:</label> <label className="fw-600">{info.memberCapacity}</label>
                          </div>

                          <div className="payment-span chip">
                            <label>Business Address:</label> <label className="fw-600">{info.address}</label>
                          </div>
                          <div className="payment-span chip">
                            <label>GST Number:</label> <label className="fw-600">{info.gstnumber && info.gstnumber !== "" ? info.gstnumber : "--"}</label>
                          </div>
                          <div className="payment-span chip">
                            <label>Member Check-In Duration:</label> <label className="fw-600">{info.checkoutduration ? info.checkoutduration : 1.5} hours</label>
                          </div>

                          <div className="mt-3">
                            <label className="m-0 fw-600">Bussiness Logo:</label>
                            <div className="py-2">
                              <img src={imageLoaded ? logourl : logo} alt="Selected" style={{ maxWidth: '100%', maxHeight: '80px' }} />
                            </div>
                          </div>
                        </MDBCol>

                      </MDBRow>

                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>

                <MDBCard className=" mt-22px mb-5">
                  <MDBCardBody>
                    <MDBCardTitle>
                      <label>Business Hours </label>
                      <div onClick={bussinessHoursShow}>
                        <MDBIcon fas icon="edit" />
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      {info.dayid && info.dayid.length > 0 &&
                        <div className="d-block">
                          {info.dayid.includes(1) &&
                            <div className="payment-span chip">
                              <label className='d-inline'>Sunday:
                                <span className="m-1">
                                  {info.sunday && info.sunday.length > 0 && info.sunday.map((obj, index) =>
                                    <label key={index} className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12)) + "PM ") : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toTime) === 12 ? 0 : 12)) + "pm") : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime))) + "AM"}{((info.sunday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}
                                </span></label>
                            </div>}

                          {info.dayid.includes(2) &&
                            <div className="payment-span chip">
                              <label>Monday:
                                <span className="m-1">
                                  {info.monday && info.monday.length > 0 && info.monday.map((obj, index) =>
                                    <label className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12) + "PM ")) : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toTime) === 12 ? 0 : 12) + "pm")) : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime)) + "AM")}{((info.monday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}

                                </span></label>
                            </div>}

                          {info.dayid.includes(3) &&
                            <div className="payment-span chip">
                              <label>Tuesday:
                                <span className="m-1">
                                  {info.tuesday && info.tuesday.length > 0 && info.tuesday.map((obj, index) =>
                                    <label key={index} className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12) + "PM ")) : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toTime) === 12 ? 0 : 12) + "pm")) : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime)) + "AM")}{((info.tuesday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}
                                </span>
                              </label>
                            </div>}
                          {info.dayid.includes(4) &&
                            <div className="payment-span chip">
                              <label>Wednesday:
                                <span className="m-1">
                                  {info.wednesday && info.wednesday.length > 0 && info.wednesday.map((obj, index) =>
                                    <label key={index} className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12) + "PM ")) : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toTime) === 12 ? 0 : 12) + "pm")) : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime)) + "AM")}{((info.wednesday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}

                                </span></label>
                            </div>}
                          {info.dayid.includes(5) &&
                            <div className="payment-span chip">
                              <label>Thursday:
                                <span className="m-1">
                                  {info.thursday && info.thursday.length > 0 && info.thursday.map((obj, index) =>
                                    <label key={index} className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12) + "PM ")) : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toTime) === 12 ? 0 : 12) + "pm")) : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime)) + "AM")}{((info.tuesday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}

                                </span></label>
                            </div>}
                          {info.dayid.includes(6) &&
                            <div className="payment-span chip">
                              <label>Friday:
                                <span className="m-1">
                                  {info.friday && info.friday.length > 0 && info.friday.map((obj, index) =>
                                    <label key={index} className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12) + "PM ")) : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toTime) === 12 ? 0 : 12) + "pm")) : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime)) + "AM")}{((info.friday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}

                                </span></label>
                            </div>}
                          {info.dayid.includes(7) &&
                            <div className="payment-span chip">
                              <label>Saturday:
                                <span className="m-1">
                                  {info.saturday && info.saturday.length > 0 && info.saturday.map((obj, index) =>
                                    <label key={index} className="fw-600"> From {parseFloat(obj.fromTime) > 11 ? ((parseFloat(obj.fromTime) - (parseFloat(obj.fromTime) === 12 ? 0 : 12) + "PM ")) : ((parseFloat(obj.fromTime) === 0 ? 12 : parseFloat(obj.fromTime)) + "am ")}
                                      To {parseFloat(obj.toTime) > 11 ? ((parseFloat(obj.toTime) - (parseFloat(obj.toime) === 12 ? 0 : 12) + "pm")) : ((parseFloat(obj.toTime) === 0 ? 12 : parseFloat(obj.toTime)) + "AM")}{((info.saturday.length - 1) === index ? <>&nbsp;</> : ",")} </label>
                                  )}

                                </span></label>
                            </div>}
                        </div>}
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol sm="12" md="4" lg="4">


                <MDBCard className='mobile'>
                  <MDBCardBody>
                    <MDBCardTitle>
                      <label>Payment Methods</label>
                      <div onClick={paymentMethodsShow}>
                        <MDBIcon fas icon="edit" />
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      <div className="payments mt-3">

                        <span className="payment-span chip">Cash </span>

                        <span className="payment-span chip">Debit/Credit Card</span>

                        <span className="payment-span chip">UPI</span>

                        <span className="payment-span chip">
                          Wallets ( Gpay,Phonephy, Paytm, etc)
                        </span>

                        <span className="payment-span chip">Coupons</span>

                        {info.userpaymethods &&
                          info.userpaymethods.map((item, index) => (
                            <>

                              <span className="payment-span chip">
                                <label>{item.title}</label>:{" "}{item.label}
                              </span>

                            </>
                          ))}
                        {PaymentLink && PaymentLink.toggle &&
                          <div className='mt-2'>
                            <label class="m-0 fw-600">Phonepe Payment Link:</label>
                            <div>
                              <span className="payment-span chip">
                                <label>Merchant ID</label>: {PaymentLink.merchantid}
                              </span>
                              <span className="payment-span chip">
                                <label>Salt Key</label>: {PaymentLink.merchantkey}
                              </span>
                              <span className="payment-span chip">
                                <label>Salt Index</label>: {PaymentLink.merchantindex}
                              </span>
                            </div>
                            {PaymentLink.status == 1 ?
                              <label
                                className="clr-green"
                              >
                                <MDBIcon fas icon="check" /> Confirmed
                              </label>
                              : ""}
                            {PaymentLink.status == 2 ? <label className="clr-orange">
                              <MDBIcon far icon="clock" /> Waiting For Confirm
                            </label> : ""}
                            {PaymentLink.status == 0 ? <label className="clr-red">
                              <MDBIcon fas icon="times" /> Failed to confirm
                            </label> : ""}
                          </div>}
                      </div>

                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>

                <MDBCard className="mt-22px ">
                  <MDBCardBody>
                    <MDBCardTitle>
                      <label>Tax Settings</label>
                      <div onClick={taxSettingsShow}>
                        <MDBIcon fas icon="edit" />
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>
                      <div className="payments  mt-3">

                        {info.ddgst &&

                          <span className="payment-span chip">GST: <span className="fw-600">18%</span></span>

                        }
                        {info.ddgst == null &&

                          <span className="payment-span chip">GST: <span className="fw-600">18%</span></span>

                        }
                        {info.ugsttax &&
                          info.ugsttax.map((item, index) => (
                            <>


                              <span className="payment-span chip">{item.title}: <span className="fw-600">{item.label}{"%"}</span></span>

                            </>
                          ))}

                      </div>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>



              </MDBCol>

              <MDBCol sm="12" md="4" lg="4">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBCardTitle>
                      <label> Digital Waiver </label>
                      <div onClick={() => DigitalWaverShow()}>
                        <MDBIcon fas icon="edit" />
                      </div>
                    </MDBCardTitle>
                    <MDBCardText>

                      <div className="mt-3">
                        <p className='mt-2 mb-1 fw-bold'>Title</p>
                        <label className="mt-0">{WaiverForm.title}</label>
                      </div>
                      <div className="mt-3">
                        <h6 className='mt-2 fw-bold'>Content checkbox text</h6>
                        <div title={stripHtmlTags(WaiverForm.checkboxcontent)} className="ps-0">{truncateContent(WaiverForm.checkboxcontent, 200)}</div>

                        <h6 className='mt-2 fw-bold'>Agreement text</h6>
                        <div className="ps-0" title={stripHtmlTags(WaiverForm.agreement)} dangerouslySetInnerHTML={{ __html: truncateContent(WaiverForm.agreement, 300) }} />

                        <h6 className='mt-3'>Optional fields</h6>
                        <div className='d-flex flex-wrap'>
                          <MDBChip>
                            <span className='me-1'>Email: </span>
                            <span className='fw-600 '>
                              {WaiverForm.email === 1 ? 'Mandatory' :
                                WaiverForm.email === 2 ? 'Optional' :
                                  WaiverForm.email === 3 ? 'Exclude' : ''}
                            </span>
                          </MDBChip>
                          <MDBChip>
                            <span className='me-1'>Phone: </span>
                            <span className='fw-600 '>
                              {WaiverForm.phone === 1 ? 'Mandatory' :
                                WaiverForm.phone === 2 ? 'Optional' :
                                  WaiverForm.phone === 3 ? 'Exclude' : ''}
                            </span>
                          </MDBChip>
                          <MDBChip>
                            <span className='me-1'>Blood group: </span>
                            <span className='fw-600 '>
                              {WaiverForm.bloodgroup === 1 ? 'Mandatory' :
                                WaiverForm.bloodgroup === 2 ? 'Optional' :
                                  WaiverForm.bloodgroup === 3 ? 'Exclude' : ''}
                            </span>
                          </MDBChip>
                          <MDBChip>
                            <span className='me-1'>Address: </span>
                            <span className='fw-600 '>
                              {WaiverForm.address === 1 ? 'Mandatory' :
                                WaiverForm.address === 2 ? 'Optional' :
                                  WaiverForm.address === 3 ? 'Exclude' : ''}
                            </span>
                          </MDBChip>
                          <MDBChip>
                            <span className='me-1'>Emergency contact: </span>
                            <span className='fw-600 '>
                              {WaiverForm.emergencycontact === 1 ? 'Mandatory' :
                                WaiverForm.emergencycontact === 2 ? 'Optional' :
                                  WaiverForm.emergencycontact === 3 ? 'Exclude' : ''}
                            </span>
                          </MDBChip>
                        </div>
                        <div className="routing-border">
                        </div>

                        <div className="d-flex align-items-center justify-content-center">
                          <MDBBtn className="ms-2" onClick={QRDigitalWaverShow}><MDBIcon fas icon="qrcode" /> Show QR Code</MDBBtn>

                        </div>
                      </div>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              {/* </> : ""} */}
            </MDBRow>
          </div >
        </div >
      </div >}

      <MDBModal open={basicModal} setOpen={setBasicModal} show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Bussiness Details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>

              <BusinessPage toggleShow={toggleShow} Gid={info._id} />

            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


      <MDBModal show={paymentMethods} setShow={setPaymentMethods} open={paymentMethods} setOpen={setPaymentMethods} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Payment Methods</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={paymentMethodsShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {paymentMethods && <PaymentMethods toggleShow={setPaymentMethods} paymentMethods={paymentMethods} />}

            </MDBModalBody>

            <MDBModalFooter className="d-none">
              <MDBBtn color='secondary' onClick={paymentMethodsShow}>
                Close
              </MDBBtn>
              <MDBBtn>Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={taxSettings} setShow={setTaxSettings} open={taxSettings} setOpen={setTaxSettings} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Tax Settings</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={taxSettingsShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>

              <TaxSettings toggleShow={setTaxSettings} />

            </MDBModalBody>
            <MDBModalFooter className="d-none">
              <MDBBtn color='secondary' onClick={taxSettingsShow}>
                Close
              </MDBBtn>
              <MDBBtn >Save</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={bussinessHours} setShow={setBussinessHours} open={bussinessHours} setOpen={setBussinessHours} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Bussiness Hours</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={bussinessHoursShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>

              <BussinessHours toggleShow={setBussinessHours} />

            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal open={locationSetting} setOpen={setLocationSetting} show={locationSetting} setShow={setLocationSetting} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Geo Location Fenching</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => { locationFenchingShow() }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className='pb-3'>
              {locationSetting && <GeoLocation data={geolocation} close={() => locationFenchingShow()} />}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal >
      <MDBModal open={DigitalWaver} setOpen={setDigitalWaver} show={DigitalWaver} setShow={setDigitalWaver} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Digital Waiver Form</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => { DigitalWaverShow(); }}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className='pb-3'>
              {DigitalWaver && <WaiverSettings toggleShow={DigitalWaverShow} data={WaiverForm} onSave={saveWaiverSettings} />}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal open={QRDigitalWaver} setOpen={setQRDigitalWaver} show={QRDigitalWaver} setShow={setQRDigitalWaver} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{WaiverForm.title ? WaiverForm.title : "Digital Waiver Form"}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={QRDigitalWaverShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* <div className="d-flex align-items-center justify-content-center pb-3">
              <h4>{WaiverForm.title} QR Code</h4>
            </div> */}

              <div ref={modalBodyRef}>
                <center>
                  <div>
                    <QRCode style={{ width: '300px', height: '300px' }} value={link} />
                  </div>
                </center>
              </div>
              <div className="mob-flex float-end mt-4">
                <MDBBtn className="ms-2" onClick={handleCopyLink}>
                  Copy Link
                </MDBBtn>
                <MDBBtn className="ms-2" onClick={handlePrintPDF}>
                  Print
                </MDBBtn>
                <MDBBtn className="ms-2" onClick={setQRDigitalWaverconfirmation}>
                  Regenerate Link
                </MDBBtn>

                <button className="ripple ripple-surface btn btn-secondary ms-2" onClick={QRDigitalWaverShow}>
                  Close
                </button>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal open={QRDigitalWaverconfirmation} setOpen={setQRDigitalWaverconfirmation} show={QRDigitalWaverconfirmation} setShow={setQRDigitalWaverconfirmation} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{WaiverForm.title ? WaiverForm.title : "Digital Waiver Form"}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={QRDigitalWaverconfirmationShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* <div className="d-flex align-items-center justify-content-center pb-3">
              <h4>{WaiverForm.title} QR Code</h4>
            </div> */}

              <div ref={modalBodyRef}>
                <center>
                  <div>By regenerating the <strong>link</strong>, the existing one sent to multiple members will be replaced and may no longer function. <br />Are you certain you wish to proceed with <strong>Regenerating</strong> the link?
                  </div>
                </center>
              </div>
              <div className="mob-flex float-end mt-4">
                <MDBBtn className="ms-2" onClick={reGenerateLink}>
                  Regenerate Link
                </MDBBtn>

                <button className="ripple ripple-surface btn btn-secondary ms-2" onClick={QRDigitalWaverconfirmationShow}>
                  Close
                </button>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBAlert
        color='success'
        autohide
        position='top-right'
        delay={2000}
        appendToBody
        open={copyLink}
        onClose={() => setcopyLink(false)}
      >Link copied to clipboard!
      </MDBAlert>
      <MDBAlert
        color={Error2.includes("ailed") ? "danger" : "success"}
        autohide
        position='top-right'
        delay={3000}
        appendToBody
        open={Error2 !== ""}
        onClose={() => setError2("")}
      >
        {Error2}
      </MDBAlert>
    </>
  );
};

export default BusinessInfo;