import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { MDBBtn, MDBIcon, MDBRow, MDBCol, MDBTextArea, MDBCard, MDBCheckbox, MDBAlert, MDBSelect, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBFooter, MDBModalFooter } from 'mdb-react-ui-kit';
import LeftPane from "../components/LeftPane";
import Header from "../components/Header";
import { getWhatsAppTEMP, updateSMSTemp, getappvariables, GetAPIsms } from "../services/apiServices";
import EmailTempletes from './EmailTempletes';


const EditMessage = () => {
    const location = useLocation();
    let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")) ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
    const navigate = useNavigate();
    const [apiError, setApiError] = useState(false);
    const [form, setForm] = useState({
    });
    const textAreaRef = useRef(null);
    const [templateinfo, setTemplateinfo] = useState([]);
    const [selectData, setSelectData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [templateData, setTemplateData] = useState({});
    const [smstemvariables, setSmstemvariables] = useState([])
    const [appVariables, setAppvariables] = useState([]);
    const [whatsappvar, setwhatsappvar] = useState([]);
    const [variableMappings, setVariableMappings] = useState([]);
    const [mappingErrors, setMappingErrors] = useState({});
    const [smsapi, setSmsapi] = useState([])
    const [Alerttxt, setAlerttxt] = useState("");
    const [smsvableMaping, setSmsvarbleMaping] = useState([])
    const [prevCheck, setPrevCheck] = useState(false)

    useEffect(() => {
        setForm(location.state.row);

        if (location.state.row.wtstemplatename) {
            setSelectedTemplate(location.state.row.wtstemplatename);
            setVariableMappings(location.state.row.wtsvariablemaping || []);
        }
    }, [location.state.row]);

    useEffect(() => {
        fetchData();

    }, []);

    useEffect(() => {
        if (whatsappvar.length > 0) {
            if (location.state.row.wtstemplatename === selectedTemplate) {
                setVariableMappings(location.state.row.wtsvariablemaping);
            } else {
                setVariableMappings((prevMappings) => {
                    const existingMappings = new Map(prevMappings.map(mapping => [mapping.whatsappvariable, mapping]));
                    const newMappings = whatsappvar.map(varName => (
                        existingMappings.get(varName) || { whatsappvariable: varName, applicationvariable: '' }
                    ));
                    return newMappings;
                });
            }
        } else {
            setVariableMappings([]);
        }
    }, [whatsappvar]);

    useEffect(() => {
        if (templateData && templateData.customParams) {
            const waitVariables = templateData.customParams.map(param => param.paramName);
            setwhatsappvar(waitVariables);
        } else {
            setwhatsappvar([]);
        }
    }, [templateData]);

    useEffect(() => {
        if (templateinfo && templateinfo.length > 0) {
            const transformedData = templateinfo.map(param => ({ text: param.elementName, value: param.elementName }));
            const selectDataWithDefault = [{ text: "select a template", value: "" }, ...transformedData];
            setSelectData(selectDataWithDefault);
        } else {
            setSelectData([{ text: "select a template", value: "" }]);
        }
    }, [templateinfo]);

    /**
    * handleSelectTemplate function is to get the select template.
    * created by vamshi
    * @function
    * 
    * @description
    * This handleSelectTemplate function has been created on [8-7-2024].It is used select template so that template can mapped.
    */
    const handleSelectTemplate = (values) => {
        if (values) {
            setSelectedTemplate(values.value);
            if (templateinfo && templateinfo.length > 0) {
                const filteredTemplates = templateinfo.filter((item) => item.elementName === values.value);
                setTemplateData(filteredTemplates.length > 0 ? filteredTemplates[0] : {});
            } else {
                setTemplateData({});
            }
        }
    };

    async function fetchData() {
        const resp = await getWhatsAppTEMP(gymid);
        if (!resp) return;

        if (resp === "false") {
            setApiError(true);
            return;
        } else {
            const TemplateData = await resp.json();
            const filterTemplates = TemplateData.messageTemplates || [];
            const filterExtend = filterTemplates.filter(item => item.status === "APPROVED");
            setTemplateinfo(filterExtend);
        }
        const res = await getappvariables(gymid);
        if (!res) return;

        if (res === "false") {
            setApiError(true);
            return;
        } else {
            const variables = await res.json();
            setAppvariables(variables);
        }
        console.log(gymid);
        const response = await GetAPIsms(gymid);
        if (!response) return;

        if (response === "false") {
            setApiError(true);
            return;
        } else {
            const smsapi = await response.json();
            setSmsapi(smsapi)
        }

    }



    const goBack = () => {
        navigate("/messageSettings");
    };

  

    /**
    * saveFunction function is save the template.
    * created by vamshi
    * @function
    * 
    * @description
    * This saveFunction function has been created on [29-5-2024].It is used save the changes made to the template.
    */
    async function saveFunction(e) {
        e.preventDefault();

        const errors = {};
        variableMappings.forEach((mapping, index) => {
            if (!mapping.applicationvariable) {
                errors[index] = `${mapping.whatsappvariable} is not mapped.`;
            }
        });
        setMappingErrors(errors);
        if (Object.keys(errors).length > 0) return;

        const updates = {
            ...form,
            gymid,
            wtsvariablemaping: variableMappings,
            wtstemplatename: selectedTemplate,
            smsvariableMaping: smsvableMaping,
        };

        let saverep = await updateSMSTemp(updates, gymid);
        if (saverep === "false") {
            setApiError(true);
        } else {
            const recinfo = await saverep.json();
            setAlerttxt("Template saved successfully");
            setForm(recinfo);
            setVariableMappings(recinfo.wtsvariablemaping || []);
            setSelectedTemplate(recinfo.wtstemplatename || "");
        }
        hadlecancel();
    }

    function updateForm(value) {
        setForm(prev => ({ ...prev, ...value }));
    }
    /**
    * handleSelectChange function .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleSelectChange function has been created on [21-6-2024].It is handle the mapping for whatsapp template.
    */
    const handleSelectChange = (index, field, value) => {
        const newMappings = [...variableMappings];
        newMappings[index][field] = value;
        setVariableMappings(newMappings);
    };

    /**
    * handleSelectChange function .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleSelectChange function has been created on [11-7-2024].It is handle the mapping for sms template.
    */
    const handleSmsSelectChange = (index, field, value) => {
        const newMappings = [...smsvableMaping];
        newMappings[index][field] = value;
        setSmsvarbleMaping(newMappings);
    };

    const numMapped = variableMappings.filter(mapping => mapping.applicationvariable).length;
    const numSmsMapped = smsvableMaping.filter(mapping => mapping.applicationvariable).length;
    const hadlecancel = () => {
        navigate("/messageSettings");
    };


    /**
   * extractVariables function .
   * created by vamshi
   * @function
   * 
   * @description
   * This extractVariables function has been created on [11-7-2024].It will extact the template variables.
   */
    const extractVariables = (template) => {
        const regex = /%%\|(\w+)\^/g;
        let match;
        const variables = new Set();

        while ((match = regex.exec(template)) !== null) {
            variables.add(match[1]);
        }
        return Array.from(variables);
    };

    useEffect(() => {
        const waitVariables = extractVariables(form.templete || '');
        setSmstemvariables(waitVariables);
    }, [form.templete]);

    useEffect(() => {
        if (smstemvariables.length > 0) {
            if (location.state.row.templete === form.templete) {
                setSmsvarbleMaping(location.state.row.smsvariableMaping);
            } else {
                setSmsvarbleMaping((prevMappings) => {
                    const existingMappings = new Map(prevMappings.map(mapping => [mapping.smsvariable, mapping]));
                    const newMappings = smstemvariables.map(varName => (
                        existingMappings.get(varName) || { smsvariable: varName, applicationvariable: '' }
                    ));
                    return newMappings;
                });
            }
        } else {
            setSmsvarbleMaping([]);
        }
    }, [smstemvariables]);

    const [previewTemplate, setPreviewTemplate] = useState("");
    const [inputValues, setInputValues] = useState({});
    const [preview, setPreview] = useState(false);

    /**
    * replaceTemplateVariables,handlePreviewClick,handleInputChange,updateTemplete functions .
    * created by vamshi
    * @function
    * 
    * @description
    * This replaceTemplateVariables,handlePreviewClick,handleInputChange,updateTemplete functions has been created on [12-7-2024].It will collect the data from the input fields and replace the variable value to the template in the preview.
    */
    const replaceTemplateVariables = (template = "", mappings = [], values = {}) => {
        if (!template) return "";
        let result = template;
        mappings.forEach(mapping => {
            const regex = new RegExp(`%%\\|${mapping.smsvariable}\\^\\{[^}]*\\}%%`, 'g');
            const replacement = values[mapping.applicationvariable] || `{${mapping.applicationvariable}}`;
            result = result.replace(regex, replacement);
        });
        return result;
    };
    const handlePreviewClick = () => {
        const values = {};
        smsvableMaping.forEach(mapping => {
            values[mapping.applicationvariable] = form[mapping.applicationvariable] || `{${mapping.applicationvariable}}`;
        });
        console.log(form.templete, smsvableMaping, values);
        const updatedTemplate = replaceTemplateVariables(form.templete, smsvableMaping, values);
        console.log(updatedTemplate)

        setPreview(updatedTemplate)
        setPreviewTemplate(updatedTemplate);

        setPrevCheck(true);
    };


    const handleInputChange = (e, variable) => {
        const value = e.target.value;
        const newInputValues = { ...inputValues, [variable]: value };
        console.log(newInputValues);
        setInputValues(newInputValues);
        updateTemplete(newInputValues);
    };

    const updateTemplete = (values) => {
        let newTemplate = preview;
        Object.keys(values).forEach(variable => {
            console.log(variable, values);
            const regex = new RegExp(`{${variable}}`, 'g');
            newTemplate = newTemplate.replace(regex, values[variable] !== "" ? values[variable] : `{${variable}}`);
        });

        setPreviewTemplate(newTemplate);
    };

    return (
        <React.Fragment>
            <>
                <LeftPane isactive="4" />
                <Header />
                <div className="gym-main gym-main-div rightContent">
                    <div className="gym-container gym-cnr" id="showcase">
                        <div className="d-flex space-between align-center">
                            <h3 className="gym-large">
                                <MDBIcon fas icon="arrow-left" className="me-2" onClick={goBack} /> {form.smscategory}
                            </h3>
                            <div>
                                <MDBBtn color='secondary' className='me-2' onClick={hadlecancel}>Cancel</MDBBtn>
                                <MDBBtn onClick={saveFunction}>Save</MDBBtn>
                            </div>
                        </div>

                        <div className="my-4 templateEditor">
                            <MDBRow>
                                <MDBCol size={9}>
                                    <div>

                                        <MDBCard className='p-3 border border-1 border-secondary mt-4 shadow-0'>
                                            <div className="mt-0">
                                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                                    <h6>SMS Template</h6>
                                                    <div className='d-flex align-items-center justify-content-between mb-2 mob-block'>
                                                        <div className='d-flex align-items-center '>
                                                            <label className="switch">
                                                                <input
                                                                    className="form-control"
                                                                    type="checkbox"
                                                                    disabled={form.templete != "" ? false : true}
                                                                    checked={form.toggle}
                                                                    onChange={() => updateForm({ toggle: !form.toggle })}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            <p className='m-0 me-3'>Enable/Disable</p>
                                                            {(form.smscategory && !(form.smscategory.includes("ADDED") || form.smscategory.includes("SUSPENDED"))) &&
                                                                <MDBCheckbox
                                                                    name='flexCheck'
                                                                    checked={form.addontoggle}
                                                                    onChange={() => updateForm({ addontoggle: !form.addontoggle })}
                                                                    id='flexCheckDefault'
                                                                    label='Addon(s)'
                                                                />
                                                            }
                                                        </div>
                                                        {/* <div>
                                                            <MDBBtn size='sm' color='link' floating onClick={togglePanel} className='shadow-0'><MDBIcon fas icon="arrows-alt" /></MDBBtn>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {smsapi.length > 0 ? <textarea className='p-2 w-100 textarea'
                                                    id='form6Example7'
                                                    label=''
                                                    rows='5'
                                                    placeholder='Please copy & paste the template from the TextLocal platform.'
                                                    name='templete'
                                                    value={form.templete || ""}
                                                    onChange={(e) => updateForm({ templete: e.target.value })}
                                                    inputRef={textAreaRef}

                                                /> : <p className='mt-2'>To Edit the templates, you have to add the access keys in the sms settings. Also, create the templates in TextLocal and paste here.</p>}
                                            </div>
                                            <div>

                                                {smstemvariables.length > 0 && <p className='mt-2'><b>Note:</b> N/A will be show if there is no value for application variable.</p>}
                                                {smstemvariables.length > 0 && <div className='py-2 d-flex align-items-center'>
                                                    <div className='ps-0 fw-500 w-30'><b>SMS Variable</b></div>
                                                    <div className='fw-500 w-25'><b>Application Variable</b></div>
                                                </div>}
                                                {smsvableMaping.length > 0 && smsvableMaping.map((mapping, index) => (
                                                    <> <div key={index} className='d-flex align-items-center mt-2'>
                                                        <div className='ps-0 fw-bold w-30'>
                                                            <span>{mapping.smsvariable}</span>
                                                        </div>
                                                        <div className='ps-0 fw-bold w-30'>
                                                            <select
                                                                className='form-select w-80'
                                                                value={mapping.applicationvariable}
                                                                onChange={(e) => handleSmsSelectChange(index, 'applicationvariable', e.target.value)}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {appVariables && appVariables.length > 0 && appVariables.map((item) => (
                                                                    <option key={item.value} value={item.value}>{item.name}</option>
                                                                ))}

                                                            </select>
                                                        </div>

                                                    </div>
                                                        {mappingErrors[index] && (
                                                            <span className='text-danger'>{mappingErrors[index]}</span>
                                                        )}</>
                                                ))}

                                            </div>
                                            <div className='d-flex justify-content-between mt-2'>

                                                {smsvableMaping.length > 0 && <div className='pt-3 fw-bold'>
                                                    No of variables: {smsvableMaping.length}, mapped: {numSmsMapped}
                                                </div>}

                                                {/* <MDBBtn onClick={() => setPrevCheck(true)}><MDBIcon fas icon="eye" className='me-2' />Preview</MDBBtn> */}
                                                {smsvableMaping.length > 0 && <MDBBtn onClick={handlePreviewClick}>
                                                    <MDBIcon fas icon="eye" className='me-2' />Preview
                                                </MDBBtn>}
                                            </div>

                                        </MDBCard>


                                        <MDBCard className='p-3 border border-1 border-secondary mt-4 shadow-0'>


                                            <div className="mt-0">
                                                <div className='d-flex align-items-center justify-content-between mb-2'>

                                                    <h6>WhatsApp Template</h6>

                                                    <div className='d-flex align-items-center '>
                                                        <label className="switch">
                                                            <input className="form-control" type="checkbox"
                                                                checked={form.wtsapptoggle}
                                                                disabled={(templateData.bodyOriginal ? templateData.bodyOriginal : templateData.body) ? false : true}
                                                                onChange={() => updateForm({ wtsapptoggle: !form.wtsapptoggle })} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className='m-0 me-3' >Enable/Disable</p>
                                                        {(form.smscategory && !(form.smscategory.includes("ADDED") || form.smscategory.includes("SUSPENDED"))) &&
                                                            <MDBCheckbox name='flexCheck' checked={form.wtsaddontoggle} onChange={() => updateForm({ wtsaddontoggle: !form.wtsaddontoggle })} id='flexCheckDefault' label="Addon(s)" disabled={!form.wtsapptoggle} />}
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center mb-2'>
                                                    <h6 className='me-3 mb-0'>Select Template:</h6>
                                                    <MDBSelect
                                                        className='w-50'
                                                        data={selectData}
                                                        search
                                                        placeholder='Select here..'
                                                        value={selectedTemplate != "" ? selectedTemplate : ""}
                                                        onChange={(e) => handleSelectTemplate(e)}
                                                    />
                                                </div>
                                                {templateinfo.length > 0 ? <MDBTextArea rows={5}
                                                    value={templateData.bodyOriginal ? templateData.bodyOriginal : templateData.body}
                                                    readOnly
                                                /> : <p className='mt-2'>To view the templates, you have to add the access keys in the WhatsApp settings. Also, add the templates in 3rd party.</p>}
                                                {whatsappvar.length > 0 && <p className='mt-2'><b>Note:</b> N/A will be show if there is no value for application variable.</p>}
                                                {whatsappvar.length > 0 && <div className='py-2 d-flex align-items-center'>
                                                    <div className='ps-0 fw-500 w-30'><b>WhatsApp Variable</b></div>
                                                    <div className='fw-500 w-25'><b>Application Variable</b></div>
                                                </div>}
                                                {(templateData.bodyOriginal ? templateData.bodyOriginal : templateData.body) && variableMappings.length > 0 && variableMappings.map((mapping, index) => (
                                                    <> <div key={index} className='d-flex align-items-center mt-2'>
                                                        <div className='ps-0 fw-bold w-30'>
                                                            <span>{mapping.whatsappvariable}</span>
                                                        </div>
                                                        <div className='ps-0 fw-bold w-30'>
                                                            <select
                                                                className='form-select w-80'
                                                                value={mapping.applicationvariable}
                                                                onChange={(e) => handleSelectChange(index, 'applicationvariable', e.target.value)}
                                                            >
                                                                <option value="" disabled>Select</option>
                                                                {appVariables && appVariables.length > 0 && appVariables.map((appVar, i) => (
                                                                    <option key={i} value={appVar.value}>{appVar.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                    </div>
                                                        {mappingErrors[index] && (
                                                            <span className='text-danger'>{mappingErrors[index]}</span>
                                                        )}</>
                                                ))}
                                                {(templateData.bodyOriginal ? templateData.bodyOriginal : templateData.body) && <div className='pt-3 fw-bold'>
                                                    No of variables: {whatsappvar.length}, mapped: {numMapped}
                                                </div>}
                                            </div>

                                        </MDBCard>

                                        <MDBCard className='p-3 border border-1 border-secondary mt-4 shadow-0'>

                                            <div className="mt-0">
                                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                                    <h6>Email Template</h6>

                                                    <div className='d-flex align-items-center '>
                                                        <label className="switch">
                                                            <input className="form-control" type="checkbox"
                                                                disabled={form.smscategory === "MEMBERSHIP_PAYMENT_ALERT" ? false : true}
                                                                checked={form.emailtoggle}
                                                                onChange={() => updateForm({ emailtoggle: !form.emailtoggle })} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className='m-0 me-3' >Enable/Disable</p>
                                                        {/* <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label="Addon(s)" /> */}
                                                    </div>
                                                </div>
                                                {form.smscategory === "MEMBERSHIP_PAYMENT_ALERT" ? <EmailTempletes /> : <div>Not applicable.</div>}
                                            </div>
                                        </MDBCard>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </div>
                <MDBAlert
                    color="success"
                    autohide
                    position='top-center'
                    delay={5000}
                    appendToBody
                    open={Alerttxt != ""}
                    onClose={() => setAlerttxt("")}
                >
                    {Alerttxt}
                </MDBAlert>


                <MDBModal staticBackdrop tabIndex='-1' open={prevCheck} onClose={() => setPrevCheck(false)}>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>
                                    <h6 className="m-0">Preview Template</h6>
                                </MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => setPrevCheck(false)}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className='d-flex mb-2'>
                                    <div className='d-flex align-items-center border w-100'>
                                        <p className='p-3 mb-0' dangerouslySetInnerHTML={{ __html: previewTemplate.replace(/%n/g, '<br />') }}></p>
                                    </div>
                                </div>
                                <h6 className='my-3'>Custom Variables</h6>
                                {smsvableMaping.map((mapping, index) => (
                                    <div className='d-flex align-items-center mb-2' key={index}>
                                        <p className='mb-0 me-2 w-20'><strong>{mapping.applicationvariable}:</strong></p>
                                        <input
                                            type='text'
                                            placeholder='Enter value'
                                            value={inputValues[mapping.applicationvariable] || ''}
                                            onChange={(e) => handleInputChange(e, mapping.applicationvariable)}
                                        />
                                    </div>
                                ))}
                            </MDBModalBody>


                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </>
        </React.Fragment>
    );
};

export default EditMessage;
