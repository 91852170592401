import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import srcImg from "./../components/images/search.svg";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { getActiveMembers } from "../services/apiServices";
import Loader from "../components/Loader";
import { MDBDatatable } from "mdb-react-ui-kit";

const ActiveMembers = () => {
  const location = useLocation();
  const searchRef = useRef();
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(true);
  const [userData, setData] = useState([]);
  const navigate = useNavigate();
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };


  async function fetchData() {

    const response = await getActiveMembers(
      localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
      location
    );

    if (!response) {

      return;
    }
    const usersData = await response.json();

    if (!usersData) {

      navigate("/");
      return;
    }
    function getDateTime(tdbtime) {
      var now = new Date(tdbtime);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (hour.toString().length == 1) {
        hour = "0" + hour;
      }
      if (minute.toString().length == 1) {
        minute = "0" + minute;
      }
      if (second.toString().length == 1) {
        second = "0" + second;
      }
      var dateTime = {
        date: day + "-" + month + "-" + year,
        time: hour + ":" + minute,
      };
      return dateTime;
    }
    let dataToshow = [];
    if (location && location.state && location.state.data == 0) {
      dataToshow = usersData.filter((item) => item.payment.balance === 0);
      setData(dataToshow);
    } else if (location && location.state && location.state.data == 1) {
      dataToshow = usersData.filter(
        (item) =>
          item.payment.balance == 0 &&
          getDateTime(item.startdate).date == getDateTime(item.createdAt).date
      );
      setData(dataToshow);
    } else if (location && location.state && location.state.data == 2) {
      dataToshow = usersData.filter(
        (item) =>
          item.payment.balance == 0 &&
          getDateTime(item.startdate).date > getDateTime(item.createdAt).date
      );
      setData(dataToshow);
    } else if (location && location.state && location.state.data == 3) {
      dataToshow = usersData.filter((item) => item.payment.balance != 0);
      setData(dataToshow);
    } else if (location && location.state && location.state.data == 4) {
      dataToshow = usersData.filter(
        (item) =>
          item.payment.balance != 0 &&
          getDateTime(item.expirydate).date < getDateTime(new Date()).date
      );
      setData(dataToshow);
    } else if (location && location.state && location.state.data == 5) {
      dataToshow = usersData.filter(
        (item) =>
          item.payment.balance != 0 &&
          getDateTime(item.expirydate).date > getDateTime(new Date()).date
      );
      setData(dataToshow);
    } else {
      setData(usersData);
    }
    //setData(usersData);
    console.log(usersData);
    // setLoading(false);
  }
  const showMemberInfo = (event, tId) => {
    console.log("data ::::::::::::::::: onlcick");
    let selMember = usersData.filter(
      (tmdata) => tmdata.members._id + " " == tId + ""
    )[0].members;

    console.log(selMember);
    navigate("/mdetails", {
      state: selMember,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  const usersData = userData.filter((el) => {
    if (inputText === "") {
      return userData;
    } else {
      return (
        (el.member[0] &&
          el.member[0].fname.toLowerCase().includes(inputText)) ||
        (el.member[0] && el.member[0].lname.toLowerCase().includes(inputText))
      );
    }
  });

  let tmrow = [];
  usersData.map((data, index) => {
    let rarray = [];
    rarray.push(index + 1);
    rarray.push(
      <a href="#" onClick={(event) => showMemberInfo(event, data.members._id)}>
        {data.members.fname} {data.members.lname}
      </a>
    );
    rarray.push(data.members.sex);
    rarray.push(data.count);

    tmrow.push(rarray);
  });
  const basicData = {
    columns: ["S.no", "Member", "sex", "Number of Login(s)"],
    rows: tmrow,
  };

  return (
    <>
      <LeftPane isactive="8" />
      <Header />
      <div
        className="gym-main rightContent"
      >
        <div
          className="gym-container"
          id="showcase"
        >
          <h3 className="gym-large">Active Members</h3>
          <h3
            className="gym-xlarge gym-text-red"
            style={{ position: "relative" }}
          >
            <input
              type="text"
              ref={searchRef}
              className="gym-input forminput"
              placeholder="Search"
              onChange={inputHandler}
            />
            <img
              src={srcImg}
              width="20px"
              className="searchicon"
              alt="Search"
            />
          </h3>
        </div>
        {loading && (
          <h4>
            <Loader />
          </h4>
        )}

        <div className="tableContainer">
          <MDBDatatable
            fixedHeader
            entries={50}
            maxHeight="88vh"
            data={basicData}
          />
        </div>
      </div>
    </>
  );
};

export default ActiveMembers;
