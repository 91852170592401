import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from "react-ga";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CardBody, Col, Row } from "reactstrap";
import {
  logEvent, loginUser
} from "../services/apiServices.js";
// import logImg from "./../components/images/GymLogo.png";
import logImg from "./../components/images/Easy-MembrLogo.png";
import logBg from "./../components/images/login-page-compressed.png";
import PageError from "./PageError.js";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon } from "mdb-react-ui-kit";
import { googleAnalytic } from "../components/googleAnalytics.js";

const Login = ({ setToken }) => {
  const userRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState(false);
  const CLIENT_ID =
    "68288337026-6p3096b5r2ukcpvbvggtrgsinp72eegd.apps.googleusercontent.com";
  const navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, [navigate]);

  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {
    setError("");
  }, [username, password]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  function setUserSession(userInfo) {
    localStorage.setItem("loggedUserInfo", JSON.stringify(userInfo));
  }

  const submitHandler = async (e, credentials) => {
    if (e) e.preventDefault();
    let userInfo = {};
    if (credentials) {
      console.log(credentials);
      userInfo = await loginUser(credentials);
    } else {
      userInfo = await loginUser({
        username,
        password,
      });
    }
    console.log(userInfo);
    const jOBj = await userInfo.json();
    console.log(jOBj);
    try {
      if (userInfo === "false") {
        setApiError(true)
        return;
      }
      console.log(jOBj);
      console.log(jOBj.userInfo);
      if (userInfo.status !== 200) {
        console.log("Error occured :: " + userInfo.response.json.error);
        setError(userInfo.jsonjson.error);
      } else {
        if (!credentials) {
          setWithExpiry("username", username, 0);
          setWithExpiry("password", password, 0);
        }
        afterLogin(jOBj);
        setToken(jOBj.token);
        setUserSession(jOBj.userInfo);
        // navigate("/dashboard");
      }
      googleAnalytic("Login", "Staff", 'Login Button', 'Staff Login')
    } catch (e) {
      setError("Invalid Username/password");
      console.log("Error::::::::::::" + e);
    }
  };
  async function afterLogin(jOBj) {
    let gymid = jOBj.userInfo.gymid;

    navigate("/dashboard");

    try {
      const url = process.env.REACT_APP_IP_ADDRESS_URL || 'https://api.ipify.org?format=json';
      const res = await axios.get(url);
      localStorage.setItem('ipaddress', res.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
    let ip = localStorage.getItem("ipaddress") || "--";
    let browserinfo = browserName + "-" + browserVersion + ".0.0";
    let Eventtype = "login";
    let flag = "Login Successfull";
    let username1 = jOBj.userInfo.email;
    logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
  }
  useEffect(() => {
    let tsesson = false;
    async function fetchData() {
      try {
        if (JSON.parse(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo"))._id) {
          console.log(JSON.parse(localStorage.getItem("loggedUserInfo"))._id);
          tsesson = true;
        }
      } catch (e) {
        console.log("errore ::::::::::::::: " + e);
      }

      if (tsesson) {
        navigate("/dashboard");
      } else {
        if (
          localStorage.getItem("username") ||
          localStorage.getItem("password")
        ) {
          console.log(localStorage.getItem("username"));
          console.log(JSON.parse(localStorage.getItem("username")));

          setUsername(JSON.parse(localStorage.getItem("username")).value);
          setPassword(JSON.parse(localStorage.getItem("password")).value);

          let credentials = {
            username: JSON.parse(localStorage.getItem("username")).value,
            password: JSON.parse(localStorage.getItem("password")).value,
          };
          console.log(credentials);
          submitHandler(null, credentials);
        } else {
          /**
           * Session error handling!
           */
          if (localStorage.getItem("SError")) {
            setError(localStorage.getItem("SError"));
            setTimeout(() => {
              setError("");
              localStorage.removeItem("SError");
            }, 5000);
          }
        }
      }
    }
    fetchData();
  }, [navigate]);

  function setWithExpiry(key, value, expiration) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expiration,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  function responseGmail(resp) {
    console.log("response from gmail..");
    console.log(resp);
    submitHandler(null, {
      ...jwt_decode(resp.credential),
      username: jwt_decode(resp.credential).email,
      loginsource: "Google",
      password: "d3fault",
    });
  }
  try {
    useEffect(() => {
      try {

        /* global google*/
        google.accounts.id.initialize({
          client_id: CLIENT_ID + "",
          callback: responseGmail,
        });
        google.accounts.id.renderButton(document.getElementById("signinDiv"), {
          theme: "outline",
          size: "large",
        });
      } catch (e) { }
    }, []);
  } catch (e) { }

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/">
          <MDBBtn>
            <MDBIcon fas icon="arrow-left" />
          </MDBBtn>
        </Link>
      </div>
      {/* Hero Start */}
      {apiError ? <PageError handleReload={handleReload} /> : <section className="cover-user bg-white">
        <div className="container-fluid px-0">
          <Row className="g-0 position-relative">
            <Col lg={4} className="cover-my-30 ">
              <div className="cover-user-img d-flex">
                <Row className="w-100">
                  <div className="col-12">
                    <div className="d-flex flex-column auth-hero">
                      <div className="my-md-5 text-center">
                        <Link to="#" >
                          <img src={logImg} className="gymlogo1 mt-md-5" alt="" />
                        </Link>
                      </div>
                      <MDBCard className="border border-success w-100">
                        <MDBCardBody>
                          <MDBCardTitle>Login </MDBCardTitle>
                          <div className="title-heading mt-0 my-lg-auto">
                            <div
                              className="login-page border-0 p-0"
                              style={{ zIndex: 1 }}
                            >
                              <CardBody className="p-0">
                                {/* <h4 className="card-title">Login</h4> */}
                                <form
                                  onSubmit={submitHandler}
                                  className="login-form mt-4"
                                >
                                  <div style={{ color: "red" }}>{error}</div>

                                  <Row>
                                    <Col lg={12}>
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Email /Mobile Number
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Email / Mobile Number."
                                          name="number"
                                          value={username}
                                          ref={userRef}
                                          onChange={(e) =>
                                            setUsername(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Password{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          placeholder="Password"
                                          onChange={(e) =>
                                            setPassword(e.target.value)
                                          }
                                          value={password}
                                          required
                                        />
                                      </div>
                                    </Col>

                                    <Col lg={12}>
                                      <div className="d-flex justify-content-between">
                                        <div className="mb-3">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            placeholder="Remember me"
                                            value="Remember me"
                                            id="flexCheckDefault"
                                          />
                                          <label className="form-check-label">
                                            Remember me
                                          </label>
                                        </div>
                                        {/* <p className="forgot-pass mb-0"><Link to="/auth-reset-password" className="fw-bold px-0 ps-1 text-primary">Forgot password?</Link></p> */}
                                      </div>
                                    </Col>
                                  </Row>

                                  <div className="mb-3">
                                    <div
                                      id="signinDiv"
                                      className="google-email"
                                    ></div>
                                  </div>
                                  <div className="col-lg-12 mb-0">
                                    <div className="d-grid">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Login
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </CardBody>
                              <div className="col-12">
                                <p className="mb-0 mt-3">
                                  <span className="text-dark me-2 font-14px">
                                    Don't have an account ?
                                  </span>
                                  <Link
                                    to="/OtpValidation"
                                    className="fw-bold px-0 ps-1 fs-6 text-primary"
                                  >
                                    Signup
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                      <div className="footer mb-md-5 text-center">
                        <p className="mt-2 text-muted">
                          © {new Date().getFullYear()} Zencruz.
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>

            <div
              className="col-lg-8  padding-less img"
              style={{ backgroundImage: `url(${logBg})` }}
              data-jarallax='{"speed": 0.5}'
            >
              <div className="greenoverlay"></div>
            </div>
          </Row>
        </div>
      </section>}
      {/* end section */}
    </React.Fragment>
  );
};
export default Login;
