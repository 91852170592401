import React from "react";
import fofImg from "./../components/images/404.png";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";

import { useNavigate } from "react-router";
export default function FileNotFound(props) {
    const navigate = useNavigate();

    function gotohome(){
      navigate("/dashboard");
    }
  return (
    <>
      <LeftPane isactive="150" />
      <Header />
      <div className="gym-main gym-main-div">
        <div className="gym-container gym-cnr" id="showcase">
      
      <div className="d-flex justify-center ">
        <img className="w-600" src={fofImg} alt="" />
      </div>
      <h5 className="font-bold text-center mt-10">OOPS!!</h5>
      <div className="text-center ft-sz mt-10">
      Sorry, the page you were trying to reach could not be found.
      </div>
      <div className="d-flex justify-center mt-10">
        <button type="button" className="btn btn-primary w-110 ft-sz" onClick={() => gotohome()}>
          Home
        </button>
      </div>
    </div>
    </div>
    </>
  );
}
