import React, { useEffect, useRef, useState, forwardRef } from "react";
import PageError from "./PageError";
import { MDBBtn, MDBDatatable, MDBIcon, MDBModalFooter, MDBSelect } from "mdb-react-ui-kit";
import { getSMSTEMP, getsmsEvents, updatesmsEvents } from "../services/apiServices";
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import ExcelExportButton from "../components/exportToExcel.js";
import Popup from "../components/Popup";
import Loader from "../components/Loader";

function SmsReports() {
    const [smsData, setSmsData] = useState([])
    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [loading, setLoading] = useState(false)
    const [basicData, setBasicData] = useState({
        columns: ['S.No', "Meassage", 'Recipient', "Date", "Template", "Memeber/Owner", 'Status'],
        rows: [],
    });
    const searchRef = useRef();
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const [sendsms, setSendsms] = useState(null)

    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [AllSMS, setAllSMS] = useState([]);
    const [mfilter, setMfilter] = useState({
        tidName: "",
        tempNme: [],
        mType: "",
    })
    const [selectData, setSelectData] = useState([
        { text: 'Select', value: "Select" },
    ]);

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };


    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }
    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setMfilter({
            tidName: "",
            tempNme: [],
            mType: "",
        })
        setResetFilterClicked(true)

    }
    let filter = {}
    // console.log(mfilter);
    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}
        filter.name = mfilter.tidName
        filter.tempNme = mfilter.tempNme
        filter.mtype = mfilter.mType

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            // location.filter.startDate = utcstartDate;
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();

            //             location.filter.endDate = utcEndDate;
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        filter.dateobj = Dateobj
        const response = await getsmsEvents(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, filter
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
        } else {


            const record = await response.json();
            if (!record) {


                return;
            }

            setSmsData(record);

        }
        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }
    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked])

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);

    async function fetchPData() {
        if (localStorage.getItem("loggedUserInfo")) {
            const response = await getSMSTEMP(JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
            if (!response) {
                return;
            }
            if (response === "false") {
                setApiError(true)
                return;
            }
            const templetes = await response.json();
            setAllSMS(templetes);
        }
    }
    useEffect(() => {

        fetchPData();
    }, []);

    useEffect(() => {

        const itemSet = new Set();

        AllSMS.length > 0 && AllSMS.forEach(item => {

            itemSet.add({ text: item.smscategory, name: item.smscategory });

        });

        const uniqueitem = Array.from(itemSet);
        // console.log(uniqueitem);
        const formatteditem = uniqueitem.map(item => ({
            text: item.text,
            value: item.name
        }));
        // console.log(formatteditem);

        setSelectData(formatteditem);
    }, [AllSMS]);
    // console.log(smsData);
    useEffect(() => {
        const popupRow = [];
        smsData && smsData.length > 0 && smsData.forEach((item, index) => {

            const rarray = [];

            rarray.push(index + 1);
            rarray.push(<div className="ps-0" dangerouslySetInnerHTML={{ __html: item.message.replace(/%n/g, "<br/>") }} />);
            rarray.push(item.member && `${item.member.number}(${item.member.fname + " " + item.member.lname})`);
            rarray.push(<span className='trans-date'>{new Date(item.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            rarray.push(item.smscategory);
            rarray.push(item.smstype === "staff" ? "Owner" : item.smstype);

            rarray.push(item.status === "success" ? <span className="txt-success"><b>Success</b></span> : <div className="d-flex flex-column justify-content-center align-items-center"><span className="text-danger"><b>Fail</b></span><MDBBtn size="sm" onClick={() =>
                setSendsms({ message: item.message, number: item.member.number, id: item._id })
            }>Retry</MDBBtn></div>);

            popupRow.push(rarray);
        })
        setBasicData({
            columns: ['S.No', "Message", 'Recipient', "Sent On", "Template", "Member/Owner", 'Status'],
            rows: popupRow
        })
        setLoading(false)
    }, [smsData])


    const resendsmsmessage = async (message, number, id) => {
        const obj = {
            message: message,
            number: number,
            id: id,
        }
        let gymid = JSON.parse(localStorage.getItem("loggedUserInfo")).gymid;
        // console.log(obj);

        await updatesmsEvents(gymid, obj)

        setSendsms(null)
        setApplyFilterClicked(true);
    }

    const handleReload = () => {
        window.location.reload();
    }

    const downloaddata = (data) => {
        const popupRow = [];
        smsData && smsData.length > 0 && smsData.forEach((item, index) => {

            const rarray = [];

            rarray.push(index + 1);
            rarray.push(item.message);
            rarray.push(item.member && `${item.member.number}(${item.member.fname + " " + item.member.lname})`);
            rarray.push(<span className='trans-date'>{new Date(item.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", })}</span>);
            rarray.push(item.smscategory);
            rarray.push(item.smstype === "staff" ? "Owner" : item.smstype);

            rarray.push(item.status);

            popupRow.push(rarray);
        })
        const obj = {
            columns: ['S.No', "Message", 'Recipient', "Sent On", "Template", "Member/Owner", 'Status'],
            rows: popupRow
        }
        ExcelExportButton(obj, "sms_Reports");
    }
    // console.log(mfilter);
    return (
        <>
            {apiError ? <PageError handleReload={handleReload} /> : <div className="mt-4">
                <div className=" d-flex flex-wrap">
                    <div className="gym-section w-100-mobile">
                        <label className="fw-bold">Search by Recipient/Number:</label>
                        <div>
                            <input
                                type="text"
                                ref={searchRef}
                                value={mfilter.tidName}
                                className="form-control select-input placeholder-active form-controlMobile"
                                // className="form-control"
                                placeholder="search here..."
                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    tidName: e.target.value.toLowerCase()
                                })}
                            />
                        </div>
                    </div>
                    <div className="gym-section w-300 w-100-mobile ms-2">
                        <label className="fw-bold">Templates:</label>

                        <div>

                            <MDBSelect
                                clearButton
                                placeholder="All"
                                multiple search
                                data={selectData}
                                value={mfilter.tempNme}
                                onChange={(e) => {
                                    const selectedValues = e.map(item => item.value);
                                    setMfilter({
                                        ...mfilter,
                                        tempNme: selectedValues
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="gym-section w-100-mobile ms-2">
                        <label className="fw-bold">Member/Owner:</label>
                        <div>
                            <select
                                name="emailReminder"
                                id="emailReminder"
                                className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                value={mfilter.mType}

                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    mType: e.target.value
                                })}
                            >
                                <option value="">All</option>
                                <option value="member">Member</option>
                                <option value="staff">Owner</option>
                            </select>
                        </div>
                    </div>
                    <div className="gym-section w-100-mobile ms-2">
                        <label className="me-2 fw-bold"> Day: </label>
                        <div className="mob-w-100 ">
                            <select
                                name="shrs"
                                id="shr"
                                className="form-control select-input form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                onChange={(e) => setDayFilter(e.target.value)}
                                value={DayFilter}
                            >
                                <option value="7">All</option>
                                <option value="1">Today</option>
                                <option value="2">Yesterday</option>
                                <option value="3">This Week</option>
                                <option value="4">This Month</option>
                                <option value="5">This Year</option>
                                {/* <option value="Week">Weekly</option> */}
                                {/* <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option> */}
                                <option value="6">Custom</option>

                            </select>
                        </div>
                    </div>
                    {customdate && <div className="gym-section w-100-mobile ms-3">
                        <span className="customRange align-items-center">
                            <label className="fw-bold">Custom Range</label>
                            <div className="gym-xlarge gym-larg-pos">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update)
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </span>
                    </div>}
                    <div className="gym-section w-100-mobile ms-3">
                        <div className="mt-3 pt-1 filterApplyMobile">
                            <button
                                type="submit"
                                className="me-3 btn btn-primary"
                                onClick={() => handleApplyAndToggle()}
                            >
                                Apply
                            </button>
                            <button
                                type="submit"
                                className="mt-2 btn btn-secondary"
                                onClick={() => { handleResetAndToggle() }}
                            >
                                Clear
                            </button>
                            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                onClick={(e) => downloaddata(basicData)}
                            >
                                <MDBIcon fas icon="download" className="d-inline me-1" />Export
                            </button>}
                        </div>
                    </div>
                </div>
                <div className="sms-settings-table custon_DT">
                    {loading ? (<div className="loader-height"> <Loader /></div>) : <MDBDatatable className="sno" fixedHeader maxHeight='calc(100vh - 223px)' entries={25} paging={true} data={basicData} />}
                </div>
            </div>

            }
            {sendsms !== null && (
                <Popup
                    title="Confirm Send notification"
                    content={
                        <>
                            <div className="pb-3">
                                <span className="fs-6 pb-3">Are you sure you want to send notification again?</span>
                            </div>
                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={(e) => setSendsms(null)}>
                                    Cancel
                                </MDBBtn>
                                <MDBBtn color="success" onClick={(e) => resendsmsmessage(sendsms.message, sendsms.number, sendsms.id)}>Send</MDBBtn>
                            </MDBModalFooter>
                        </>
                    }
                    handleClose={() => setSendsms(null)}
                />
            )}
        </>
    )
}

export default SmsReports