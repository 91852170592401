import { MDBIcon } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";

function Discount({ index, handleDiscountChange1, ...props }) {
    const [discountValues, setDiscountValues] = useState();
    const [discountUnit, setDiscountUnit] = useState("");

    useEffect(() => {
        if (props.data.discount) {
            setDiscountUnit(props.data.discount.discountType !== "percent" ? "₹" : "%");
            setDiscountValues(props.data.discount.EnterValue);
        }
    }, [])
    const totalPrice = Number(props.obj.Item.price);
    // const taxPercentage =
    // props.obj.taxPercent &&
    //     props.obj.taxPercent.length > 0 &&
    //     props.obj.taxPercent != "include"
    //     ? Number(props.obj.taxPercent[0])
    //     : 0;

    let taxPercentage = props.obj.taxamounts &&
        props.obj.taxamounts.length > 0 &&
        props.obj.taxamounts[props.obj.taxamounts.length - 1].TaxCheck === "Included"
        ?
        Number(props.obj.taxamounts.reduce((acc, obj) => {
            if (obj.percentage) {
                return acc + parseFloat(obj.percentage);
            }
            return acc;
        }, 0)
        )
        : 0;
    console.log(taxPercentage);
    const discountPercentage = discountValues;
    const taxAmount = totalPrice / (1 + (taxPercentage / 100));
    console.log(taxAmount);
    const totalPriceWithoutTax = taxAmount;
    console.log("&&&&&&%%%%%%%%%%%%%%%%%%%%%%%%%%", totalPriceWithoutTax);
    const discountAmount =
        discountUnit === "₹"
            ? discountValues
            : totalPrice * (discountPercentage / 100);
    let finalPrice = ((totalPrice - discountAmount) || 0);

    const handleDiscountChange = (e) => {
        const enteredValue = e.target.value;
        const maxDiscount = Number(
            props.obj.Item.discount === "0"
                ? "0"
                : props.obj.Item.discount === "1"
                    ? "5"
                    : props.obj.Item.discount === "2"
                        ? "10"
                        : props.obj.Item.discount === "3"
                            ? "15"
                            : "50"
        );
        const calculatedPercentage = (enteredValue / Number(props.obj.Item.price)) * 100;
        const rsDiscount = maxDiscount > calculatedPercentage
            ? enteredValue
            : ((maxDiscount / 100) * Number(props.obj.Item.price)).toFixed(0);
        const perDiscount = maxDiscount > Number(e.target.value) ? e.target.value : maxDiscount;

        // let dataObj = {
        //     Mid: props.data._id,
        //     discount: discountUnit === "₹" ? rsDiscount : perDiscount,
        //     discounttype: discountUnit,
        //     planId: props.data.planid,
        //     discountAmount: discountAmount

        // }
        if (discountUnit === "₹") {
            setDiscountValues(rsDiscount);
            props.handleDiscountChange(index, rsDiscount, "rupees", discountAmount, props.data._id,);
        } else {
            setDiscountValues(perDiscount);
            props.handleDiscountChange(index, perDiscount, "percent", discountAmount, props.data._id,);
        }
        // props.handleDiscountChange(index, dataObj, discountUnit)
    };

    // if (props.usersInfo && props.usersInfo.length > 0) {
    //     const totalDiscount = props.usersInfo.reduce((acc, _, index) => {
    //         console.log(Number(discountUnit[index] === "rs" ? discountValues[index] : ((discountValues[index] * props.actualprice / props.quantity) / 100) || 0));
    //         return acc + Number(discountUnit[index] === "rs" ? discountValues[index] : ((discountValues[index] * props.actualprice / props.quantity) / 100) || 0);
    //     }, 0);
    //     const averageDiscount = totalDiscount / props.usersInfo.length;
    //     tdiscount(averageDiscount);
    // }

    return (
        <>
            <div className="d-flex align-items-end justify-content-end w-400">
                <p className="m-0 w-100">
                    Discount: &nbsp;
                    <select
                        name=""
                        id=""
                        className="inp-clrdate-Small2"
                        onChange={(e) => {
                            setDiscountUnit(e.target.value);
                        }}
                        value={discountUnit || ""}
                    >
                        <option value="%">%</option>
                        <option value="₹">₹</option>
                    </select>{" "}
                    <input
                        type="number"
                        name=""
                        className="inp-clrdate-Small me-2"
                        value={discountValues}
                        onChange={(e) => {
                            handleDiscountChange(e);
                        }}
                    />
                    {discountValues && (
                        <span className="ms-1 text-success">
                            <del>
                                <MDBIcon fas icon="rupee-sign" className="me-1" />
                                {totalPrice}
                            </del>

                            <strong className="ms-2">₹{(finalPrice).toFixed(2)}</strong>
                        </span>
                    )}
                </p>
            </div>
        </>
    );
}

export default Discount;
