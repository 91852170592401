import { React, useEffect, useState } from "react";
import loadImg from "./../components/images/loading.gif";
import {  verifyEmail } from "../services/apiServices.js";
import { useLocation, useNavigate } from "react-router";

const VEmails = () => {
  const location = useLocation();
  console.log(location);
  console.log(getQueryVariable("mailid"));
  const [success, setSucess] = useState(false);
  const [error, setError] = useState(false);
  function getQueryVariable(variable) {
    var query = location.search.substring(1);
    console.log(query);
    var vars = query.split("&");
    console.log(vars);
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair);
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  const navigate = useNavigate();
  async function verifyEmailId() {
    const response = await verifyEmail(getQueryVariable("mailid"));
    if (!response) {
 
      return;
    }
    const updateData = await response.json();
    console.log(updateData);
    if (!updateData) {

      navigate("/");
      return;
    }
    if (updateData.sucess) {
      setSucess(true);
      setError(false);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      // navigate("/login");
    }
    if (updateData.error) {
      setError(true);
      setSucess(false);
      //navigate("/fileerror");
    }
  }
  useEffect(() => {
    setTimeout(() => {
      verifyEmailId();
    }, 2000);
  }, []);
  return (
    <>
      {!success && !error && (
        <h4 className="loading-center" style={{ textAlign: "center" }}>
           Please wait, while your email is being verified<br /> 
          <img src={loadImg} height={"200px"} />
        </h4>
      )}{" "}
      {error && (
        <h4 className="loading-center" style={{ textAlign: "center", color:"red" }}>
          Invalid/Expired URL!
        </h4>
      )}
      {success && (
        <h4
          className="loading-center"
          style={{ textAlign: "center", color: "green" }}
        >
          Email verfied sucessfully!
        </h4>
      )}
    </>
  );
};

export default VEmails;
