import React, { Component } from "react";

class Terms extends Component {
    render() {
        return (
            <div>
                <div className="container p-14 font">

                <section>
                  <h5>Non-Refundable:

                  </h5>
                  <p> 
                    All payments made for the usage of Gym Admin Online are non-refundable. This means that once the payment is made, it will not be refunded, even in the event of cancellation or termination of the service.
                  </p>
                </section>

                <section>
                  <h5>Standard SaaS Software Usage License: </h5><p>By using Gym Admin Online, you are granted a standard Software-as-a-Service (SaaS) usage license. This license allows you to access and utilize the features and functionalities of the software specifically for your gym administration purposes.

                  </p>
                </section>

                <section>
                  <h5>Restrictions:</h5><p>You are strictly prohibited from copying, modifying, distributing, or reverse engineering any part of the Gym Admin Online software. The license granted is for your personal and non-commercial use only, and you may not transfer or sublicense it to any third party.

                  </p>
                </section>

                <section>
                  <h5>Intellectual Property:</h5><p>All intellectual property rights, including copyrights and trademarks, associated with Gym Admin Online are owned by the respective owners or licensors. You may not use any of these intellectual property rights without prior written permission.

                  </p>
                </section>

                <section>
                  <h5>Data Security: </h5><p>Gym Admin Online takes reasonable measures to ensure the security of your data. However, it is your responsibility to maintain the confidentiality of your login credentials and protect your account from unauthorized access.

                  </p>
                </section>

                <section>
                  <h5>Termination: </h5><p>Either party may terminate the usage of Gym Admin Online at any time. Upon termination, you will no longer have access to the software, and any data associated with your account may be deleted.

                  </p>
                </section>

                <section>
                  <h5>Limitation of Liability:</h5><p>Gym Admin Online and its owners or licensors are not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the software.
                  </p>
                </section>
            </div>
            </div>
            
        )
    }
}

export default Terms;
