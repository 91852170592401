import React, { useEffect, useRef, useState, forwardRef } from "react";
import { getWhatsAppMemberNotification, getWhatsAppNotification } from '../services/apiServices'
import { MDBBtn, MDBDatatable, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import DatePicker from 'react-datepicker';
import calImg from "./../components/images/calendar.svg";
import PageError from "./PageError";
import Loader from "../components/Loader";
import ExcelExportButton from "../components/exportToExcel";

function WtsAppNotificationRpts() {
    const [whatsAppData, setwhatsAppData] = useState([])
    const [apiError, setApiError] = useState(false);
    const [DayFilter, setDayFilter] = useState("4");
    const [loading, setLoading] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [basicData, setBasicData] = useState({
        columns: [
            { label: 'S.No', field: 'sno' },
            { label: 'Template Name', field: 'campaignName' },
            { label: 'Sent On', field: 'sentOn' },
            { label: 'Number of Recipients', field: 'numRecipients' },
            { label: 'Template', field: 'template' },
        ],
        rows: [],
    });
    const [mBasicData, setMBasicData] = useState({
        columns: [
            { label: 'S.No', field: 'sno' },
            { label: 'Name', field: 'memberName' },
            { label: 'Number', field: 'number' },
            { label: 'latest Sent On', field: 'lastsentOn' },
            { label: 'Number of sent messages', field: 'numReceived' },
            { label: "Membership Start Date", field: "startdate" },
            { label: "Membership End Date", field: "enddate" }


        ],
        rows: [],
    });
    const [dataFormat, setDataFormat] = useState("template")
    const [dataCheck, setDataCheck] = useState(false)
    const [openTogle, setOpenTogle] = useState(false)
    const searchRef = useRef();
    const [customdate, setcustomdate] = useState(DayFilter === "4");
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    today.setHours(23, 59, 0, 0);

    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Member', 'Sent On', 'Recipient Number', 'Message'],
        rows: [],
    })
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [mfilter, setMfilter] = useState({
        tidName: "",
        tempNme: [],
        mType: "",
    })

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    const getDates = (obj) => {
        setDateRange(obj);
    };




    /**
    * handleApplyAndToggle function is used for applying filters .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleApplyAndToggle function has been create on [24-7-2024] used to set the filters.
    */
    const handleApplyAndToggle = () => {
        setMBasicData({
            columns: [
                { label: 'S.No', field: 'sno' },
                { label: 'Member Name', field: 'memberName' },
                { label: 'Number', field: 'number' },
                { label: 'last Sent On', field: 'lastsentOn' },
                { label: 'Number of sent messages', field: 'numReceived' },
                { label: "Membership Start Date", field: "startdate" },
                { label: "Membership End Date", field: "enddate" }


            ],
            rows: [],
        });
        setBasicData({
            columns: [
                { label: 'S.No', field: 'sno' },
                { label: 'Template Name', field: 'campaignName' },
                { label: 'Sent On', field: 'sentOn' },
                { label: 'Number of Recipients', field: 'numRecipients' },
                { label: 'Template', field: 'template' },
            ],
            rows: [],
        });
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }
        if (dataFormat === "member") {
            setDataCheck(true)
        } else {
            setDataCheck(false)
        }


        setApplyFilterClicked(true)
    }


    /**
    * handleResetAndToggle function is used to clear filters .
    * created by vamshi
    * @function
    * 
    * @description
    * This handleResetAndToggle function has been create on [24-8-2024] used for reset the filters .
    */
    const handleResetAndToggle = () => {

        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setMfilter({
            tidName: "",
            tempNme: [],
            mType: "",
        })
        setDataCheck(false)
        setResetFilterClicked(true)
        setDataFormat("template")
    }


    /**
     * fetchData function is used to get notification data respected to the notification name.
     * created by vamshi
     * @function
     * 
     * @description
     * This fetchData function has been create on [24-8-2024] used get the notification data .
     */
    let filter = {}
    async function fetchData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}
        filter.name = mfilter.tidName
        filter.tempNme = mfilter.tempNme
        filter.mtype = mfilter.mType

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        filter.dateobj = Dateobj
        const response = await getWhatsAppNotification(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, filter
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        } else {


            const record = await response.json();
            if (!record) {


                return;
            }

            console.log(record);
            setwhatsAppData(record);

        }
        setLoading(true)
        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }


    /**
     * fetchMData function is used to get data of all member data with notification events.
     * created by vamshi
     * @function
     * 
     * @description
     * This fetchMData function has been create on [24-8-2024] used get member notification data.
     */
    let filter_m = {}
    async function fetchMData() {
        setLoading(true)
        let startOfDate = startDate
        let endOfDate = endDate
        let Dateobj = {}
        filter_m.name = mfilter.tidName
        filter_m.tempNme = mfilter.tempNme
        filter_m.mtype = mfilter.mType

        if ((startOfDate && endOfDate)) {
            const startDatef = new Date(startOfDate)
            startDatef.setHours(0, 0, 0, 0)
            const utcstartDate = startDatef.toISOString();
            let lastoftheday = new Date(endOfDate);
            lastoftheday.setHours(23, 59, 59, 999);
            const utcEndDate = lastoftheday.toISOString();
            Dateobj = {
                startDate: utcstartDate,
                endDate: utcEndDate,
            }
        }
        filter_m.dateobj = Dateobj
        setLoading(true)
        const response = await getWhatsAppMemberNotification(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid, filter_m
        );

        // console.log(response);
        if (!response) {
            return;
        }
        if (response === "false") {
            setApiError(true)
            return;
        } else {
            const record = await response.json();
            if (!record) {
                return;
            }
            const sortedData = record.sort((a, b) => {
                if (a.whtsappevent.length === 0 && b.whtsappevent.length !== 0) {
                    return 1;
                }
                if (a.whtsappevent.length !== 0 && b.whtsappevent.length === 0) {
                    return -1;
                }
                if (a.whtsappevent.length > 0 && b.whtsappevent.length > 0) {
                    const dateA = new Date(a.whtsappevent[0].updatedAt);
                    const dateB = new Date(b.whtsappevent[0].updatedAt);
                    return dateB - dateA;
                }
                return 0;
            });
            // console.log(record, sortedData, '77777777765');
            setwhatsAppData(sortedData);

        }
        setApplyFilterClicked(false)
        setResetFilterClicked(false)

    }

    useEffect(() => {

        if (dataFormat === "template") {
            fetchData();
        } else {
            fetchMData()
        }

    }, [applyFilterClicked, resetFilterClicked])

    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);

    useEffect(() => {

        const popupRow = [];
        if (dataFormat === "template") {

            whatsAppData && whatsAppData.length > 0 && whatsAppData.forEach((item, index) => {
                // console.log(item.template,index);

                const rowData = {
                    sno: index + 1,
                    campaignName: item.campainname ? item.campainname : "",
                    sentOn: new Date(item.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
                    numRecipients: item.templatevalues.length,
                    template: <div
                        className='m-0 ms-2'
                        dangerouslySetInnerHTML={{
                            __html: item.template.replace(/\n/g, "<br/>")
                        }}
                    ></div>,
                    originalItem: item
                };
                popupRow.push(rowData);
            });
            setBasicData(prevData => ({
                ...prevData,
                rows: popupRow
            }));
            setLoading(false)
        } else {


            whatsAppData && whatsAppData.length > 0 && whatsAppData.forEach((item, index) => {
                const rowData = {
                    sno: index + 1,
                    memberName: item.fname ? item.fname + " " + item.lname : "--",
                    number: item.number ? item.number : '--',
                    lastsentOn: item.whtsappevent && item.whtsappevent.length > 0 ? new Date(item.whtsappevent[0].updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) : "--",
                    numReceived: item.whtsappevent.length,
                    startdate: item.startdate ? new Date(item.startdate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) : "--",
                    enddate: item.expirydate ? new Date(item.expirydate).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }) : "--",
                    originalItem: item
                };
                popupRow.push(rowData);
            })
            setMBasicData(prevData => ({
                ...prevData,
                rows: popupRow
            }));
            setLoading(false)
        }

    }, [whatsAppData]);

    // console.log(basicData);


    /**
    * handleRowClick function is used to get data of row which is clicked.
    * created by vamshi
    * @function
    * 
    * @description
    * This handleRowClick function has been create on [24-8-2024] used get the data of row slected.
    */
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData.originalItem ? rowData.originalItem : rowData);
        console.log(rowData.originalItem ? rowData.originalItem : rowData);
        setOpenTogle(true)
    };

    /**
      * mappingTemplate function is used to map the custom value to the template.
      * created by vamshi
      * @function
      * 
      * @description
      * This mappingTemplate function has been create on [24-8-2024] used for get the message.
      */
    const mappingTemplate = (params, template) => {
        let result = template;
        params.forEach(param => {
            if (param.name && param.value) {
                const placeholder = `{{${param.name}}}`;
                const boldPlaceholder = `*${placeholder}*`;
                if (result.includes(boldPlaceholder)) {
                    result = result.replace(boldPlaceholder, `<b>${param.value}</b>`);
                } else {
                    result = result.replace(placeholder, param.value);
                }
            }
        });
        return result;
    };

    useEffect(() => {
        const popupRow = [];
        let snumber = 1

        if (selectedRowData != null) {
            if (dataFormat === "template") {
                selectedRowData.templatevalues && selectedRowData.templatevalues.length > 0 && selectedRowData.templatevalues.forEach((item, index) => {
                    const memberDetails = item.memberDetails ? item.memberDetails : []

                    const memberName = memberDetails.fname + " " + memberDetails.lname

                    let message = selectedRowData.template

                    if (item.customParams.length > 0) {
                        message = mappingTemplate(item.customParams, selectedRowData.template);
                    }
                    console.log(memberName);
                    const rarray = [];
                    rarray.push(snumber++)
                    rarray.push(memberDetails.fname !== undefined ? memberName : "(Member deleted)");
                    rarray.push(selectedRowData.updatedAt ? new Date(selectedRowData.updatedAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                    }) : "NA");
                    rarray.push(item.whatsappNumber);
                    rarray.push(
                        <div
                            className='m-0 ms-2'
                            dangerouslySetInnerHTML={{
                                __html: message.replace(/\n/g, '<br />')
                            }}
                        ></div>
                    );
                    popupRow.push(rarray);
                })

                setBasicData2({
                    columns: ["S.no", 'Member', 'Sent On', 'Recipient Number', 'Message'],
                    rows: popupRow
                })
            } else {
                selectedRowData.whtsappevent && selectedRowData.whtsappevent.length > 0 && selectedRowData.whtsappevent.forEach((item, index) => {
                    const memberinfo = item.templatevalues && item.templatevalues.length > 0 && item.templatevalues.filter((data) => data.memberid === selectedRowData._id
                    )

                    let message = item.template
                    if (memberinfo && memberinfo.length > 0 && memberinfo[0].customParams.length > 0) {
                        message = mappingTemplate(memberinfo[0].customParams, item.template);
                    }
                    // console.log(message);

                    const rarray = [];
                    rarray.push(snumber++)
                    rarray.push(item.campainname)
                    rarray.push(item.updatedAt ? new Date(item.updatedAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                    }) : "NA");
                    rarray.push(memberinfo && memberinfo.length > 0 ? memberinfo[0].whatsappNumber : selectedRowData.number)
                    rarray.push(
                        <div
                            className='m-0 ms-2'
                            dangerouslySetInnerHTML={{
                                __html: message.replace(/\n/g, '<br />')
                            }}
                        ></div>
                    );
                    popupRow.push(rarray);
                })

                // console.log(selectedRowData);
                setBasicData2({
                    columns: ["S.no", 'Template Name', 'Sent On', 'Recipient Number', 'Message'],
                    rows: popupRow
                })

            }
        }
    }, [selectedRowData])

    const handleReload = () => {
        window.location.reload();
    }


    /**
  * downloaddata function is used to download data in to excel.
  * created by vamshi
  * @function
  * 
  * @description
  * This downloaddata function has been create on [28-8-2024] used for export data to in to excel file.
  */
    const downloaddata = (data, title) => {
        let obj = {}
        console.log(data, '', mBasicData, basicData);
        if (dataFormat !== "template") {
            obj = {
                columns: mBasicData.columns.map(column => column.label),
                rows: mBasicData.rows.map(row => [
                    row.sno,
                    row.memberName,
                    row.number,
                    row.lastsentOn,
                    row.numReceived,
                    row.startdate,
                    row.enddate
                ])
            };
        } else {
            obj = {
                columns: basicData.columns.map(column => column.label),
                rows: basicData.rows.map(row => [
                    row.sno,
                    row.campaignName,
                    row.sentOn,
                    row.numRecipients,
                    row.template.props.dangerouslySetInnerHTML.__html,
                ])
            };
        }


        console.log(obj);
        ExcelExportButton(obj, title);
    }

    const handleCloseModal = () => {
        setOpenTogle(false);
    };

    return (
        <div>
            {apiError ? <PageError handleReload={handleReload} /> : <div className="mt-4">
                <div className=" d-flex flex-wrap">
                    <div className="gym-section w-100-mobile w-10 me-2">
                        <label className="fw-bold"> Breakdown: </label>
                        <div className="mob-w-100">
                            <select
                                name="shrs"
                                id="shr"
                                className="form-select form-control active mob-w-100"
                                onChange={(e) => {
                                    setDataFormat(e.target.value)
                                    // setDataCheck(false)
                                }}
                                value={dataFormat}
                            >
                                <option value="template">Templates</option>
                                <option value="member">Members</option>
                            </select>
                        </div>
                    </div>
                    <div className="gym-section w-100-mobile">
                        <label className="fw-bold">Search:</label>
                        <div>
                            <input
                                type="text"
                                ref={searchRef}
                                value={mfilter.tidName}
                                className="form-control select-input placeholder-active form-controlMobile"
                                // className="form-control"
                                placeholder={dataFormat !== "template" ? "Search by Name/Number" : "Search by Template Name"}
                                onChange={(e) => setMfilter({
                                    ...mfilter,
                                    tidName: e.target.value
                                })}
                            />
                        </div>
                    </div>

                    <div className="gym-section w-100-mobile ms-2">
                        <label className="me-2 fw-bold">{dataFormat !== "template" ? "Latest Sent On" : "Day:"}</label>
                        <div className="mob-w-100 ">
                            <select
                                name="shrs"
                                id="shr"
                                className="form-control select-input form-control form-select placeholder-active me-3 shadow-1 form-selectMobile"
                                onChange={(e) => setDayFilter(e.target.value)}
                                value={DayFilter}
                            >
                                <option value="7">All</option>
                                <option value="1">Today</option>
                                <option value="2">Yesterday</option>
                                <option value="3">This Week</option>
                                <option value="4">This Month</option>
                                <option value="5">This Year</option>
                                <option value="6">Custom</option>

                            </select>
                        </div>
                    </div>
                    {customdate && <div className="gym-section w-100-mobile ms-3">
                        <span className="customRange align-items-center">
                            <label className="fw-bold">Custom Range</label>
                            <div className="gym-xlarge gym-larg-pos">
                                <DatePicker
                                    dateFormat={"d/M/yyyy"}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        getDates(update)
                                    }}
                                    isClearable={false}
                                    customInput={<CalenderInput />}
                                />
                            </div>
                        </span>
                    </div>}
                    <div className="gym-section w-100-mobile ms-3">
                        <div className="mt-3 pt-1 filterApplyMobile">
                            <button
                                type="submit"
                                className="me-3 btn btn-primary"
                                onClick={() => handleApplyAndToggle()}
                            >
                                Apply
                            </button>
                            <button
                                type="submit"
                                className="mt-2 btn btn-secondary"
                                onClick={() => { handleResetAndToggle() }}
                            >
                                Clear
                            </button>
                            {(localStorage.getItem("loggedUserInfo")) && JSON.parse(localStorage.getItem("loggedUserInfo")).role === "admin" && <button type="submit" className="ms-3 btnheight btn btn-primary"
                                onClick={(e) => downloaddata(dataFormat !== "template" ? mBasicData : basicData, 'wtsAppNotificationRpt')}
                            >
                                <MDBIcon fas icon="download" className="d-inline me-1" />Export
                            </button>}
                        </div>
                    </div>
                </div>
                <div>

                    {dataCheck ? < div className="">
                        {loading ? (<div className="loader-height"> <Loader /></div>) :
                            <MDBDatatable className="WhatsAppMembersTable"
                                fixedHeader
                                maxHeight='calc(100vh - 223px)'
                                entries={25}
                                paging={true}
                                data={(mBasicData)}
                                onRowClick={handleRowClick}
                            />
                        }
                    </div> :
                        < div className="">
                            {loading ? (<div className="loader-height"> <Loader /></div>) :
                                <MDBDatatable className="whatsapptable"
                                    fixedHeader
                                    maxHeight='calc(100vh - 223px)'
                                    entries={25}
                                    paging={true}
                                    data={basicData}
                                    onRowClick={handleRowClick}
                                />
                            }
                        </div>}
                </div>

            </div>

            }

            <MDBModal staticBackdrop tabIndex='-1' open={openTogle} onClose={handleCloseModal}>
                <MDBModalDialog size="lg">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                <h6 className="m-0">{dataFormat === "template" ? "Recipients Details" : selectedRowData && selectedRowData.fname ? `${selectedRowData.fname + " " + selectedRowData.lname} Details` : ""}</h6>
                            </MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={handleCloseModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {openTogle
                                && <MDBDatatable className="whatsapptable"
                                    fixedHeader
                                    maxHeight='calc(100vh - 223px)'
                                    entries={25}
                                    paging={true}
                                    data={basicData2}
                                />}
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div >
    )
}

export default WtsAppNotificationRpts