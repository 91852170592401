import React from 'react';
import ExcelJS from 'exceljs';

const ExcelExportButton = (data, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');

    const columns = data.columns.filter(column => column !== 'Actions');

    worksheet.addRow(columns);

    data.rows.forEach((row) => {
        const rowData = row.map((item) => {
            if (item instanceof Date) {
                return item;
            } else if (item && item.$$typeof && (item.$$typeof === Symbol.for('react.element'))) {
                if (typeof item === 'object' && item !== null && item.props.children.length != 3) {
                    return item.props.children;
                }
            } else {
                return item;
            }
        });

        const filteredRowData = rowData.filter((item, index) => data.columns[index] !== 'Actions');
        worksheet.addRow(filteredRowData);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();

        URL.revokeObjectURL(url);
    });
};

export default ExcelExportButton;
