import { MDBRadio } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateWaiverSettings } from "../services/apiServices";
import PageError from './PageError';
import ReactGA from 'react-ga';
import { googleAnalytic } from '../components/googleAnalytics';

function WaiverSettings(props) {
    const [apiError, setApiError] = useState(false);
    const [businessForm, setBusinessForm] = useState({
        uniqueNumber: "",
        emergencycontact: 2,
        bloodgroup: 2,
        address: 2,
        phone: 1,
        email: 1,
        title: `Digital Waiver Form`,
        checkboxcontent: `By checking this box, I acknowledge that I have read, understood, and agree to the terms and conditions
outlined in the waiver form. I accept full responsibility for my participation, or for the participation of the
minor I am legally responsible for, in climbing activities at Crag Studio, and recognize the inherent risks
involved.`,
        agreement:
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
deserunt mollit anim id est laborum`
    });

    useEffect(() => {
        setBusinessForm(props.data);
    }, [props.data])
    function updateForm(value) {
        return setBusinessForm((prev) => {
            return { ...prev, ...value };
        });
    }
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    function DigitalWaverShow() {
        setBusinessForm(props.data);
        props.toggleShow();
    }
    async function onSaveButton() {
        const updates = {
            gymid: localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "",
            title: businessForm.title,
            checkboxcontent: businessForm.checkboxcontent,
            emergencycontact: businessForm.emergencycontact,
            bloodgroup: businessForm.bloodgroup,
            address: businessForm.address,
            phone: businessForm.phone,
            email: businessForm.email,
            agreement: businessForm.agreement,
        };
        try {
            const saverep = await updateWaiverSettings(updates, localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "");
            if (saverep === "false") {
                setApiError(true)
            } else {
                const recinfo = await saverep.json();
                console.log(recinfo);
                props.onSave(recinfo);
            }
            await googleAnalytic("WaiverSettings", 'BussinessInfo', 'Save Button', 'Digital Waiver Updated')
           
        } catch (error) {
            console.error("Error updating waiver settings:", error);
        }
    }
    const handleReload = () => {
        window.location.reload();
    }
    return (
        <React.Fragment>
            {apiError ? <PageError handleReload={handleReload} /> : <React.Fragment>
                <div className="d-flex align-items-center">
                    <label className="fw-bold me-2" >Title: </label>
                    <input type="text" name="" id="" className="BusinessPage__contentNew w-100 "
                        value={businessForm.title}
                        onChange={(e) => updateForm({ title: e.target.value })}
                    />
                </div>
                <div className="d-flex flex-column Consentcheckbox mt-2">
                    <label className="fw-bold">Consent checkbox text</label>
                    <textarea name="" id="" rows="4"
                        value={businessForm.checkboxcontent}
                        onChange={(e) => updateForm({ checkboxcontent: e.target.value })}
                    ></textarea>
                    <label className="fw-bold mt-3">Agreement text</label>
                    <ReactQuill
                        value={businessForm.agreement}
                        onChange={(value) => updateForm({ agreement: value })}
                    />
                </div>

                <div className="d-flex flex-column mt-3">

                    <label className="fw-bold">Optional fields </label>
                    <div className="ms-2 mt-2 ">

                        <div className="mob-block d-flex align-items-center justify-content-between">
                            <label>Email</label>
                            <div className="d-flex">
                                <MDBRadio name='inlineRadio' id='inlineRadio1' value='option1' label='Mandatory' inline
                                    checked={businessForm.email === 1}
                                    onChange={() => updateForm({ email: 1 })}
                                />
                                <MDBRadio name='inlineRadio' id='inlineRadio2' value='option2' label='Optional' inline
                                    checked={businessForm.email === 2}
                                    onChange={() => updateForm({ email: 2 })}
                                />
                                <MDBRadio name='inlineRadio' id='inlineRadio3' value='option3' label='Exclude' inline
                                    checked={businessForm.email === 3}
                                    onChange={() => updateForm({ email: 3 })}
                                />
                            </div>
                        </div>

                        <div className="mob-block d-flex align-items-center justify-content-between mt-2">
                            <label>Phone</label>
                            <div className="d-flex">
                                <MDBRadio name='inlineRadio5' id='inlineRadio4' value='option4' label='Mandatory' inline
                                    checked={businessForm.phone === 1}
                                    onChange={() => updateForm({ phone: 1 })}
                                />
                                <MDBRadio name='inlineRadio5' id='inlineRadio5' value='option5' label='Optional' inline
                                    checked={businessForm.phone === 2}
                                    onChange={() => updateForm({ phone: 2 })}
                                />
                                <MDBRadio name='inlineRadio5' id='inlineRadio6' value='option6' label='Exclude' inline
                                    checked={businessForm.phone === 3}
                                    onChange={() => updateForm({ phone: 3 })}
                                />
                            </div>
                        </div>


                        <div className="mob-block d-flex align-items-center justify-content-between mt-2">
                            <label>Emergency contact</label>
                            <div className="d-flex">
                                <MDBRadio name='inlineRadio2' id='inlineRadio7' value='option7' label='Mandatory' inline
                                    checked={businessForm.emergencycontact === 1}
                                    onChange={() => updateForm({ emergencycontact: 1 })}
                                />
                                <MDBRadio name='inlineRadio2' id='inlineRadio8' value='option8' label='Optional' inline
                                    checked={businessForm.emergencycontact === 2}
                                    onChange={() => updateForm({ emergencycontact: 2 })}
                                />
                                <MDBRadio name='inlineRadio2' id='inlineRadio9' value='option9' label='Exclude' inline
                                    checked={businessForm.emergencycontact === 3}
                                    onChange={() => updateForm({ emergencycontact: 3 })}
                                />
                            </div>
                        </div>

                        <div className="mob-block d-flex align-items-center justify-content-between mt-2">
                            <label>Blood group</label>
                            <div className="d-flex">
                                <MDBRadio name='inlineRadio3' id='inlineRadio10' value='option10' label='Mandatory' inline
                                    checked={businessForm.bloodgroup === 1}
                                    onChange={() => updateForm({ bloodgroup: 1 })}
                                />
                                <MDBRadio name='inlineRadio3' id='inlineRadio11' value='option11' label='Optional' inline
                                    checked={businessForm.bloodgroup === 2}
                                    onChange={() => updateForm({ bloodgroup: 2 })}
                                />
                                <MDBRadio name='inlineRadio3' id='inlineRadio12' value='option12' label='Exclude' inline
                                    checked={businessForm.bloodgroup === 3}
                                    onChange={() => updateForm({ bloodgroup: 3 })}
                                />
                            </div>
                        </div>

                        <div className="mob-block d-flex align-items-center justify-content-between my-2">
                            <label>Address</label>
                            <div className="d-flex">
                                <MDBRadio name='inlineRadio4' id='inlineRadio13' value='option13' label='Mandatory' inline
                                    checked={businessForm.address === 1}
                                    onChange={() => updateForm({ address: 1 })}
                                />
                                <MDBRadio name='inlineRadio4' id='inlineRadio14' value='option14' label='Optional' inline
                                    checked={businessForm.address === 2}
                                    onChange={() => updateForm({ address: 2 })}
                                />
                                <MDBRadio name='inlineRadio4' id='inlineRadio15' value='option15' label='Exclude' inline
                                    checked={businessForm.address === 3}
                                    onChange={() => updateForm({ address: 3 })}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer pb-0">
                    <button class="ripple ripple-surface btn btn-secondary" onClick={DigitalWaverShow}>
                        Cancel
                    </button>
                    <button class="ripple ripple-surface btn btn-primary" onClick={onSaveButton}>
                        Save
                    </button>
                </div>
            </React.Fragment>}</React.Fragment>
    )
}
export default WaiverSettings

