import React, { Component } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Rupee from '../assets/images/rupee.svg';
class Pricing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pricing: [
                {
                    id: 1,
                    title: 'Free Trial',
                    price: '30 Days Free',
                    pricetxt: '',
                    price1: '',
                    pricetxt1: '',
                    value: 'Basic',
                    features: ['Unlimied Members', 'Unlimited Plans', '2 Users', 'Chekins', 'Intuitive PoS', 'Slot based plans', 'Dashboard and reports', 'Addons', 'Free for 30 days']
                },
                {
                    id: 2,
                    title: 'Starter',
                    price: 'Free',
                    pricetxt: '',
                    price1: '',
                    pricetxt1: '',
                    value: 'Basic',
                    features: ['15 Active Members ', '5 Plans', '2 Users', 'Chekins', 'Intuitive PoS', 'Slot based plans', 'Dashboard and reports', 'Free', 'SMS  1000 pack @Rs.300.']
                },
                {
                    id: 3,
                    title: 'Growth',
                    price: '400/',
                    pricetxt: 'month (Yearly)',
                    price1: '500/',
                    pricetxt1: 'month (Monthly)',
                    value: 'All in starter +',
                    features: ['50 Active Members ', 'Unlimited Plans', '5 Users', 'Advanced Reports', 'Add ons', 'SMS  1000 pack @Rs.300.']
                },
                {
                    id: 4,
                    title: 'Flourish',
                    price: '900/',
                    pricetxt: 'month (Yearly)',
                    price1: '1000/',
                    pricetxt1: 'month (Monthly)',
                    value: 'All in growth + ',
                    features: ['250 Active Members ', 'Unlimited Plans', '20 Users', 'SMS  1000 pack @Rs.300.']
                }
                ,
                {
                    id: 5,
                    title: 'Premium',
                    price: '2250/',
                    pricetxt: 'month (Yearly)',
                    price1: '2500/',
                    pricetxt1: 'month (Monthly)',
                    value: 'All in flourish +',
                    features: ['Unlimited members', 'Unlimited Plans', 'Unlimited Users', 'SMS  1000 pack @Rs.300.']
                }
            ],
        }
    }


    render() {
        return (
            <React.Fragment>
                {/* Start Pricing  */}
                <section className="section bg-light" id="pricing">
                    <div className="container-fluid">
                        <Row className="justify-content-center ">
                            <div className="col-12">
                                <div className="section-title text-center mb-4">
                                    <h4 className="title fw-semibold mb-4">Pricing Plans</h4>
                                    {/* <p className="para-desc text-muted mx-auto mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                                */}
                                </div>
                            </div>
                        </Row>

                        <Row className="d-flex justify-content-center pricingDiv">
                            {this.state.pricing.map((item, key) => (
                                <Col lg={2} md={6} className="col-12 mt-3 pricingPlan" key={key}>
                                    <Card className="border-0 shadow prisingdiv">
                                        <div className="p-4 pb-2 border-bottom border-light">
                                            <h6 className="fw-semibold mb-3 text-uppercase">{item.title}</h6>
                                            {/* <p className="text-muted mb-0">For individuals looking for a simple Startups solution</p> */}

                                            <div className="d-flex my-4 pricingh">
                                                {item.title == 'Free Trial' || item.title == 'Starter' ? " " :
                                                    <div>
                                                        <span className="text-muted align-self-start mb-1 rupee"><img src={Rupee} width={26} /></span>
                                                        <span className="text-muted align-self-start mb-1 rupee"><img src={Rupee} width={26} /></span>
                                                    </div>
                                                }
                                                <span className="price h3 fw-semibold mb-0">{item.price}<small>{item.pricetxt}</small>
                                                    <br></br>{item.price1}<small>{item.pricetxt1}</small></span>
                                            </div>
                                            <Link to={`/signup?id=${key}&name=${item.title}`} className="btn btn-primary w-100"
                                            >Buy Now</Link>
                                            <p className="price h6 text-center mt-1 mb-0">{item.value}</p>
                                        </div>

                                        <div className="p-4">
                                            <h6 className="mb-3">Features:</h6>
                                            <ul className="mb-0 list-unstyled planlist"> {item.features.map((subitem, subkey) => (
                                                <li className="text-muted " key={subkey}><span className={item.price < 90 && subkey > 2 ?
                                                    "text-danger h5 me-1" : ' text-success h5 me-1'}><i className={item.price < 90 && subkey > 2 ? "uil uil-times-circle align-middle" : ' uil uil-check-circle align-middle'}></i></span> {subitem}</li>
                                            ))}
                                            </ul>
                                        </div>
                                    </Card>
                                </Col>

                            ))}

                            {/* <div className="col text-center mt-2">
                                <p className="mt-3 mb-0 text-muted">Note: <span className="text-danger fs-5">*</span>No credit card required</p>
                            </div> */}
                        </Row>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}

export default Pricing;
