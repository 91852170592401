import { React, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from 'react-ga';
import { Link, } from "react-router-dom";
import Addons from "../components/Addons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftPane from "../components/LeftPane";
import PATemplate from "../components/PlanAddonTemplate";
import Popup from "../components/Popup";
import Price from "../components/Price";
import SearchBar from "../components/SearchBar";
import { getGBussinessInfo, getGymAddonsList, getGymPlansList } from "../services/apiServices.js";
import AddUsers from "./AddUsers";
import Payments from "./Payments";
import ReceiptPrint from "./ReicptPrint";
import PageError from "./PageError";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from 'mdb-react-ui-kit';

export default function HomePage(props) {
  // let plansInf = localStorage.getItem("planInfo") ? JSON.parse(localStorage.getItem("planInfo")) : null;
  const [startDate, setStartDate] = useState(new Date());

  const [openPayment, setOpenPayment] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const [curIndex, setCurIndex] = useState(0);
  const [submitFlag, setsubmitFlag] = useState(0);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [inputText, setInputText] = useState("");
  const [usersInfo, setUsersInfo] = useState([]);
  const [mquantity, setMquantity] = useState([]);

  const [plansInfo, setPlans] = useState([]);
  const [loadedflag, setLoadedflag] = useState(false);
  const [selectedPlans, setSelectPlans] = useState([]);
  const [selectedAddons, setselectedAddons] = useState([]);
  const [selectPlansIds, setSelectPlansIds] = useState([]);
  const [showMainResults, setshowMainResults] = useState(false);
  const [price, setPrice] = useState(0);
  const [addons, setAddons] = useState([]);
  const [Alreadysel, setAlreadysel] = useState([]);
  const [showAddons, setshowAddons] = useState(false);
  const [data, setData] = useState("");
  const [DiscountsValues, setDiscountsValues] = useState(0);
  const [transDate, setTransDate] = useState(new Date())
  const [apiError, setApiError] = useState(false);
  const [selectAlertValue, setSelectAlertValue] = useState(false);
  const selectAlert = () => setSelectAlertValue(!selectAlertValue);

  useEffect(() => {

    async function fetchData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGBussinessInfo(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const record = await response.json();
          if (!record) {
        
            return;
          }
          setData(record);
        }
      }
    }
    if (localStorage.getItem("loggedUserInfo")) {
      fetchData();
    } else {
      window.location.href = "/login"
    }
  }, []);
  function setSDate(date, id) {
    let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
    tselObj.idate = date;
    setStartDate(date);
  }

  function handleTransdate(date) {
    console.log("in homepage handleTransDate", date);
    setTransDate(date)
  }

  const togglePopup = (flag) => {
    // console.log("Flage :::::::::::::::: " + flag);
    switch (flag) {
      case "1":
        setOpenAddMember(!openAddMember);
        break;
      case "2":

        // console.log(openPayment);
        setOpenPayment(true);
        break;
      default:
        setOpenAddMember(!openAddMember);
    }
  };

  async function checkUserAndForward(event) {
    if (!usersInfo || usersInfo.length <= 0) {
      window.alert("To complete the transaction, at least one member must be selected.");
      // selectAlert();
    } else {
      console.log(selItems);
      let filteredData = selItems.filter((item) => !item.members || item.members.length === 0);
      if (filteredData.length > 0) {
        // selectAlert();
        window.alert("To complete the transaction, at least one member must be selected.");
      } else {
        await setState();
        setOpenPayment(true);
      }
    }
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  function cancelThis(event) {
    clearAll();
    setInputText("");
  }


  function setState() {
    let tPrice = 0;
    for (const item of selItems) {
      let allMemberDiscounts = item.members && item.members.length > 0 && item.members.reduce((memberAcc, member) => memberAcc + (member.discount ? member.discount.amount : 0), 0);
      let planPrice = item.Item.price * item.quantity;
      if (item.Item.taxcheck) {
        let withoutTaxAmount = parseFloat((planPrice /
          (1 + item.taxAmount && item.taxamounts.length > 0 && item.taxamounts.reduce((acc, obj) => acc + (obj.percentage ? (parseFloat(obj.percentage) / 100) : 0), 0))).toFixed(2));
        let afterDiscount = withoutTaxAmount - allMemberDiscounts;
        tPrice = tPrice + afterDiscount + (item.taxAmount && item.taxamounts.length > 0 && item.taxamounts.reduce((acc, obj) => acc + (obj.amount ? obj.amount : 0), 0))
      } else {
        let afterDiscount = planPrice - allMemberDiscounts;
        tPrice = tPrice + afterDiscount + (item.taxamounts && item.taxamounts.length > 0 && item.taxamounts.reduce((acc, obj) => acc + (obj.amount ? obj.amount : 0), 0))
      }
      tPrice = localStorage.getItem("Tprice")
    }
    let allselectedData = {
      plans: selectedPlans,
      plansid: selectPlansIds,
      addons: selectedAddons,
      price: !isNaN(tPrice) ? Math.round(tPrice) : price,
      userinfo: usersInfo,
      tId: getToken(),
      totalPrice: tPrice,
      quantity: mquantity,
      transDate: transDate
    };
    console.log(allselectedData);
    //***********************************************************************************************************************************************************/
    localStorage.setItem("selItems", JSON.stringify(selItems));
    localStorage.setItem("allData", JSON.stringify(allselectedData));
    return;
  }

  function createUser() {
    setsubmitFlag(0);
    togglePopup();
  }
  function incrementCount(event, id) {
    event.preventDefault();
    let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
    if (tselObj.quantity * 1 >= 30) {
      return;
    }
    tselObj.quantity = tselObj.quantity * 1 + 1;
    if (tselObj.Item.taxcheck) {
      setPrice((Math.round(price * 1 + tselObj.Item.price * 1, 2)));
    }
    else {
      setPrice(Math.round(price * 1 + tselObj.Item.price * 1
        + (data && data.dgsttax[0] === 1 ? (((tselObj.Item.price * 1) * 0.18, 2)) : 0)
        + (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? ((tselObj.Item.price * 1) * (parseFloat(obj.label)) / 100) : 0), 0) : 0)
        , 2));
    }
  }

  function decrementCount(event, id) {
    event.preventDefault();
    let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
    tselObj.quantity = tselObj.quantity * 1 - 1;
    if (tselObj.Item.taxcheck) {
      setPrice(Math.round(price * 1 - tselObj.Item.price * 1, 2));
    }
    else {
      setPrice(Math.round(price * 1 - tselObj.Item.price * 1
        - (data && data.dgsttax[0] === 1 ? (Math.round((tselObj.Item.price * 1) * 0.18, 2)) : 0)
        - (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? Math.round((tselObj.Item.price * 1) * (parseFloat(obj.label)) / 100) : 0), 0) : 0)
        , 2));
    }
    let allMemberDiscounts = tselObj.members.reduce((memberAcc, member) => memberAcc + (member.discount ? member.discount.amount : 0), 0);
    let taxamounts = [];
    let planPrice = tselObj.Item.price * tselObj.quantity;
    if (tselObj.Item.taxcheck) {
      let withoutTaxAmount = parseFloat((planPrice / (1 + (data && data.dgsttax[0] === 1 ? 0.18 : 0)
        + (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? (parseFloat(obj.label) / 100) : 0), 0) : 0))).toFixed(2));

      let afterDiscount = withoutTaxAmount - allMemberDiscounts;

      if (data && data.dgsttax[0] === 1) {
        taxamounts.push({
          percentage: '18%',
          title: 'GST',
          amount: parseFloat((afterDiscount * 0.18).toFixed(2))
        });
      }
      if (data.ugsttax) {
        data.ugsttax.forEach((obj) => {
          if (obj.check) {
            const percentageNumber = parseFloat(obj.label);
            taxamounts.push({
              percentage: `${percentageNumber}%`,
              title: `${obj.title}`,
              amount: parseFloat((afterDiscount * (percentageNumber / 100)).toFixed(2))
            });
          }
        });
      }
      taxamounts.push({ TaxCheck: "Included" });
    } else {
      let afterDiscount = planPrice - allMemberDiscounts;

      if (data && data.dgsttax[0] === 1) {
        taxamounts.push({
          percentage: '18%',
          title: "GST",
          amount: parseFloat((afterDiscount * 0.18).toFixed(2))
        });
      }
      if (data.ugsttax) {
        data.ugsttax.forEach((obj) => {
          if (obj.check) {
            const percentageNumber = parseFloat(obj.label);
            taxamounts.push({
              percentage: `${percentageNumber}%`,
              title: `${obj.title}`,
              amount: parseFloat((afterDiscount * (percentageNumber / 100)).toFixed(2))
            });
          }
        });
      }
      taxamounts.push({ TaxCheck: "Excluded" });
    }
    tselObj.taxamounts = taxamounts;
    if (tselObj.quantity * 1 === 0) {
      let remainselItems = selItems.filter((tObj) => tObj._id !== id);
      setSelItems(remainselItems);
      localStorage.setItem("selItems", JSON.stringify(remainselItems));
      return;
    }
  }

  function Extend(objjj, id) {
    let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
    tselObj.extendmember = objjj;
  }

  function discountValues(mid, planID, dType, dEnterValue, dAmount) {
    console.log("Dicountttt")
    try {
      let allmembers = selItems;
      let tselObj = selItems.find((tObj) => tObj._id === planID);
      console.log(tselObj);
      if (tselObj && tselObj.members && tselObj.members.length > 0) {
        const selectedMember = tselObj.members.find((tObj) => tObj._id === mid);
        if (selectedMember) {
          let value = {
            discountType: dType,
            EnterValue: dEnterValue,
            amount: dAmount,
          };
          console.log(value);
          selectedMember.discount = value;
        }
        let allMemberDiscounts = tselObj.members.reduce((memberAcc, member) => memberAcc + (member.discount ? member.discount.amount : 0), 0);
        let taxamounts = [];
        let planPrice = tselObj.Item.price * tselObj.quantity;
        if (tselObj.Item.taxcheck) {
          let withoutTaxAmount = parseFloat(
            (planPrice /
              (1 + (data && data.dgsttax[0] === 1 ? 0.18 : 0) +
                (data.ugsttax
                  ? data.ugsttax.reduce(
                    (acc, obj) => acc + (obj.check ? (parseFloat(obj.label) / 100) : 0),
                    0
                  )
                  : 0))
            ).toFixed(2)
          );
          let afterDiscount = withoutTaxAmount - allMemberDiscounts;
          if (data && data.dgsttax[0] === 1) {
            taxamounts.push({
              percentage: '18%',
              title: 'GST',
              amount: parseFloat((afterDiscount * 0.18).toFixed(2))
            });
          }

          if (data.ugsttax) {
            data.ugsttax.forEach((obj) => {
              if (obj.check) {
                const percentageNumber = parseFloat(obj.label);
                taxamounts.push({
                  percentage: `${percentageNumber}%`,
                  title: `${obj.title}`,
                  amount: parseFloat((afterDiscount * (percentageNumber / 100)).toFixed(2))
                });
              }
            });
          }

          taxamounts.push({ TaxCheck: "Included" });
          tselObj.taxamounts = taxamounts;

          const updatedSelItems = selItems.map((item) => (item._id === tselObj._id ? tselObj : item));
          console.log(updatedSelItems)
          setSelItems(updatedSelItems);
          // localStorage.setItem("selItems", JSON.stringify(updatedSelItems));
          var totalDiscountAmount_1 = allmembers.reduce((acc, item) => {
            if (item.members && item.members.length > 0) {
              return acc + item.members.reduce((memberAcc, member) => memberAcc + (member.discount ? member.discount.amount : 0), 0);
            } else {
              return acc;
            }
          }, 0);
          const dprice = parseFloat((totalDiscountAmount_1 / (1 + (data && data.dgsttax[0] === 1 ? (0.18) : 0)
            + ((data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? (parseFloat(obj.label) / 100) : 0), 0) : 0)))).toFixed(2))
          setDiscountsValues(dprice);


        } else {
          let afterDiscount = planPrice - allMemberDiscounts;
          if (data && data.dgsttax[0] === 1) {
            taxamounts.push({
              percentage: '18%',
              title: "GST",
              amount: parseFloat((afterDiscount * 0.18).toFixed(2))
            });
          }

          if (data.ugsttax) {
            data.ugsttax.forEach((obj) => {
              if (obj.check) {
                const percentageNumber = parseFloat(obj.label);
                taxamounts.push({
                  percentage: `${percentageNumber}%`,
                  title: `${obj.title}`,
                  amount: parseFloat((afterDiscount * (percentageNumber / 100)).toFixed(2))
                });
              }
            });
          }

          taxamounts.push({ TaxCheck: "Excluded" });
          tselObj.taxamounts = taxamounts;

          const updatedSelItems = selItems.map((item) => (item._id === tselObj._id ? tselObj : item));
          console.log(updatedSelItems)
          setSelItems(updatedSelItems);
          // localStorage.setItem("selItems", JSON.stringify(updatedSelItems));
          var totalDiscountAmount_2 = allmembers.reduce((acc, item) => {
            if (item.members && item.members.length > 0) {
              return acc + item.members.reduce((memberAcc, member) => memberAcc + (member.discount ? member.discount.amount : 0), 0);
            } else {
              return acc;
            }
          }, 0);

          setDiscountsValues(totalDiscountAmount_2);
        }
      }
    } catch (e) {
      console.error('An error occurred:', e);
      // Handle the error
    }
  }
  function taxAmounts(planObj, qty) {
    let taxamounts = [];
    let planPrice = planObj.price * qty;
    if (planObj.taxcheck) {
      let withoutTaxAmount = parseFloat((planPrice /
        (1 + (data && data.dgsttax[0] === 1 ? 0.18 : 0) +
          (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? (parseFloat(obj.label) / 100) : 0), 0) : 0))
      ).toFixed(2)
      );

      if (data && data.dgsttax[0] === 1) {
        taxamounts.push({
          percentage: '18%',
          title: "GST",
          amount: parseFloat((withoutTaxAmount * 0.18).toFixed(2))
        });
      }

      if (data.ugsttax) {
        data.ugsttax.forEach((obj) => {
          if (obj.check) {
            const percentageNumber = parseFloat(obj.label);
            taxamounts.push({
              percentage: `${percentageNumber}%`,
              title: `${obj.title}`,
              amount: parseFloat((withoutTaxAmount * (percentageNumber / 100)).toFixed(2))
            });
          }
        });
      }
      taxamounts.push({ TaxCheck: "Included" });
    } else {

      if (data && data.dgsttax && data.dgsttax.length > 0 && data.dgsttax[0] === 1) {
        taxamounts.push({
          percentage: '18%',
          title: "GST",
          amount: parseFloat((planPrice * 0.18).toFixed(2))
        });
      }

      if (data.ugsttax) {
        data.ugsttax.forEach((obj) => {
          if (obj.check) {
            const percentageNumber = parseFloat(obj.label);
            taxamounts.push({
              percentage: `${percentageNumber}%`,
              title: `${obj.title}`,
              amount: parseFloat((planPrice * (percentageNumber / 100)).toFixed(2))
            });
          }
        });
      }
      taxamounts.push({ TaxCheck: "Excluded" });
    }
    return taxamounts;
  }
  async function addMemToLineItems(id, members, sObj, addflg) {
    try {
      console.log("addMemToLineItems::::::::::::::::::::start")
      let tselItems = selItems;
      console.log(id + "," + { members });
      console.log(tselItems);
      let updatedAlreadysel = [...Alreadysel];
      let foundIndex = updatedAlreadysel.findIndex(item => item[0] === id);
      if (foundIndex !== -1) {
        updatedAlreadysel[foundIndex][1] = members;
      } else {
        updatedAlreadysel.push([id, members]);
      }
      console.log("chinaaaaaaaaaaaa%%%%%%%%%%a", updatedAlreadysel);
      setAlreadysel(updatedAlreadysel);
      let tselObj = tselItems.filter((tObj) => tObj._id === id)[0];
      console.log(tselObj);
      if (!tselObj) {
        tselObj = sObj;
      }
      console.log(tselObj);

      tselObj.members = members;

      const nItems = tselItems.filter((item) => item._id !== id);
      console.log(nItems);
      //**** New column in array for tax amount 
      //****Chinna --24-10-2023 ***/
      let taxamounts = taxAmounts(tselObj.Item, tselObj.quantity);
      tselObj.taxamounts = taxamounts;
      //****Chinna --Jan 16 2023 for tax calculations ***/
      tselItems = [...nItems, tselObj];
      tselItems = tselItems.sort((a, b) =>
        a.Item.name.localeCompare(b.Item.name)
      );
      console.log(members.length);
      tselObj.quantity = members.length;
      // if (addflg) {
      let taddons = tselItems.filter((item) => item.isplan == "0");
      let tplans = tselItems.filter((item) => item.isplan == "1");
      console.log(taddons);
      console.log(tplans);
      if (taddons.length > 0 && tplans.length === 1) {
        let mems = tplans[0].members;
        if (mems && mems.length > 0) {
          let filterAddonMembers = taddons.filter((member) => !member.members || member.members.length === 0);
          if (filterAddonMembers.length > 0 && window.confirm("Are you sure you want to add the selected plan member(s) to this addon?")) {

            let modifiedMembers = mems.map((member) => {
              const { discount, ...newMember } = member;
              return newMember;
            });

            taddons.forEach((taitem) => {
              console.log(taitem);
              let tamem = (taitem.members && taitem.members.length > 0) ? taitem.members : [];

              let filteMember = modifiedMembers.filter((obj) =>
                !tamem.some(modMember => modMember._id === obj._id)
              )
              if (tamem.length == 0) {
                taitem.members = modifiedMembers;
                taitem.quantity = modifiedMembers.length;
                let taxamounts = taxAmounts(taitem.Item, taitem.quantity);
                taitem.taxamounts = taxamounts;
              } else {
                // taitem.members = tamem.concat(filteMember);
                // taitem.quantity = taitem.members.length;
                // let taxamounts = taxAmounts(taitem.Item, taitem.quantity);
                // taitem.taxamounts = taxamounts;
              }
              let memberAlreadysel = [...updatedAlreadysel];
              let foundIndex = memberAlreadysel.findIndex(item => item[0] === taitem.Item._id);
              if (foundIndex !== -1) {
                memberAlreadysel[foundIndex][1] = taitem.members;
              } else {
                memberAlreadysel.push([taitem.Item._id, taitem.members]);
              }
              setAlreadysel(memberAlreadysel);
            });

          }
        }
        tselItems = [...tplans, ...taddons];
      }
      setSelItems(tselItems);
      setUsersInfo([...usersInfo, members]);
      if (tselObj.Item.taxcheck) {
        setPrice(Math.round(price * 1 + tselObj.Item.price * 1, 2));
      } else {
        setPrice(Math.round(price * 1 + tselObj.Item.price * 1
          + (data && data.dgsttax[0] === 1 ? (Math.round((tselObj.Item.price * 1) * 0.18, 2)) : 0)
          + (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? Math.round((tselObj.Item.price * 1) * (parseFloat(obj.label) / 100)) : 0), 0) : 0)
          , 2));
      }
      let totalDiscountAmount_3 = tselItems.reduce((acc, item) => {
        if (item.members && item.members.length > 0) {
          return acc + item.members.reduce((memberAcc, member) => memberAcc + (member.discount ? member.discount.amount : 0), 0);
        } else {
          return acc;
        }
      }, 0);
      setDiscountsValues(totalDiscountAmount_3);
      let Totalprice = 0;
      console.log(tselItems);
      tselItems.forEach((tempItem) => {
        let tmpprice = 0;
        if (tempItem.Item.taxcheck) {
          tmpprice = Math.round(
            tmpprice * 1 + tempItem.Item.price * tempItem.quantity
          );
        } else {
          tmpprice = Math.round(
            tmpprice * 1 + Math.round(
              tempItem.Item.price * 1 +
              (data && data.dgsttax[0] === 1 ? Math.round(tempItem.Item.price * 0.18, 2) : 0) +
              (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? Math.round(tempItem.Item.price * (parseFloat(obj.label)) / 100) : 0), 0) : 0)
            ) * tempItem.quantity
          );
        }
        Totalprice += tmpprice;
      });
      if (Totalprice > 0) {
        setPrice(Totalprice);
      }
      console.log("addMemToLineItems::::::::::::::::::::Endd")
    } catch (e) { }
  }
  const [selItems, setSelItems] = useState([]);
  let addAddons = (eObj, id, checked) => {
    // let tselObj1 = selItems.filter((tObj) => tObj._id === id);
    // // console.log(tselObj.quantity);
    // // if (tselObj.quantity && tselObj.quantity >= 1) {
    // if (tselObj1.length === 0) {
    //   processSelect(eObj, id, checked);
    // } else {
    //   let remainselItems = selItems.filter((tObj) => tObj._id !== id);
    //   setSelItems(remainselItems);
    // }


    if (!checked) {

      let tselObj = selItems.filter((tObj) => tObj._id === id)[0];
      console.log(tselObj);
      if (tselObj && tselObj.quantity) {
        tselObj.quantity = tselObj.quantity * 1 - 1;
        setPrice(Math.round(price * 1 - tselObj.Item.price * 1, 2));
        let remainselItems = selItems.filter((tObj) => tObj._id !== id);
        setSelItems(remainselItems);
      }
    } else {
      processSelect(eObj, id, checked);
    }
  };

  let processSelect = async (eObj, id, checked,) => {
    console.log("processSelect :::::::::::::::start");
    if (eObj) {
      eObj.preventDefault();
    }
    setshowMainResults(false);
    let tselItems = selItems;
    console.log("Firstttttttt" + tselItems)
    setSelItems([]);
    let tselObj = { quantity: 1, idate: startDate, _id: id };
    console.log("*********************************************")
    if (tselItems.filter((tObj) => tObj._id === id).length > 0) {
      tselObj = tselItems.filter((tObj) => tObj._id === id)[0];
      tselObj.quantity = tselObj.quantity * 1 + 1;
      const nItems = tselItems.filter((item) => item._id !== id);
      console.log(nItems);
      tselItems = [...nItems, tselObj];
      tselItems = tselItems.sort((a, b) => a.Item.name.localeCompare(b.Item.name));
      setSelItems(tselItems);

      setshowMainResults(true);
      generateToken();
      setshowAddons(true);
      if (tselObj.Item.taxcheck) {
        setPrice(Math.round(price * 1 + tselObj.Item.price * 1, 2));
      }
      else {
        setPrice(Math.round(price * 1 + tselObj.Item.price * 1
          + (data && data.dgsttax[0] === 1 ? (Math.round((tselObj.Item.price * 1) * 0.18, 2)) : 0)
          + (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? Math.round((tselObj.Item.price * 1) * (parseFloat(obj.label)) / 100) : 0), 0) : 0)
          , 2));
      }
      return;
    }
    let planOBj = plansInfo.filter((item) => item._id === id)[0];
    if (planOBj) {
      tselObj.Item = planOBj;
      tselObj.isplan = 1;
    } else {
      try {
        let addonObj = {}
        if (addons.length > 0) {
          addonObj = addons.filter((item) => item._id === id)[0];
        } else {
          if (localStorage.getItem("loggedUserInfo")) {
            const response = await getGymAddonsList(
              JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );
            if (response === "false") {
              setApiError(true)
            } else {
              let resp = await response.json();
              addonObj = resp.filter((item) => item._id === id)[0];
            }
          }
        }
        if (addonObj) {
          tselObj.isplan = 0;
          tselObj.Item = addonObj;
          tselObj.Item.price = addonObj.price ? addonObj.price : addonObj.props[0].propvalue;
          if (!localStorage.getItem("preadd_member") && (tselItems.filter((tObj) => tObj.isplan === 1)?.length == 1)) {
            let planArray = tselItems.filter((tObj) => tObj.isplan === 1);
            let membersArray = []
          
            addMemToLineItems(tselObj._id, membersArray, tselObj);
          } 
        }
      } catch (e) { }
    }

    try {
      let tmpitems = [...selItems, tselObj];
      tmpitems = tmpitems.sort((a, b) =>
        a.Item.name.localeCompare(b.Item.name)
      );
      localStorage.setItem("selItems", JSON.stringify(tmpitems));
      setSelItems(tmpitems);

      setshowMainResults(true);
      generateToken();
      setshowAddons(true);
      if (tselObj.Item.taxcheck) {
        setPrice(Math.round(price * 1 + tselObj.Item.price * 1, 2));
      }
      else {
        setPrice(Math.round(price * 1 + tselObj.Item.price * 1
          + (data && data.dgsttax[0] === 1 ? (Math.round((tselObj.Item.price * 1) * 0.18, 2)) : 0)
          + (data.ugsttax ? data.ugsttax.reduce((acc, obj) => acc + (obj.check ? Math.round((tselObj.Item.price * 1) * (parseFloat(obj.label)) / 100) : 0), 0) : 0)
          , 2));
      }
      setInputText("");
    } catch (e) { }
    let preadd = localStorage.getItem("preadd_member");
    if (preadd) {
      let preaddOBJ = JSON.parse(preadd);
      addMemToLineItems(preaddOBJ.planId, [preaddOBJ.member], tselObj);
      console.log("added member processed");
      localStorage.removeItem("preadd_member");
    }
    console.log("processSelect :::::::::::::::Enddddd");
  };
  function oncancelpayment() {
    setOpenPayment(false);
    setOpenReceipt(false);
  }
  function gotohome() {
    cancelThis();
  }

  function onSubmitUser() {
    try {
      togglePopup();
      console.log(localStorage.getItem(getToken() + "_users"));
      let tempusersInfo = localStorage.getItem(getToken() + "_users");
      let tempIndx = JSON.parse(tempusersInfo).index;

      if (!usersInfo[tempIndx]) {
        usersInfo[tempIndx] = [tempusersInfo];
      } else {
        usersInfo[tempIndx] = [...usersInfo[tempIndx], tempusersInfo];
      }

      if (mquantity[tempIndx] < usersInfo[tempIndx].length) {
        mquantity[tempIndx] = usersInfo[tempIndx].length;
      }
    } catch (e) {
      console.log("Error :::::::::::::::::::: " + e);
    }
  }

  function setToken(tTransactionId) {
    try {
      localStorage.setItem("transactionId", tTransactionId);
      setUniqueId(tTransactionId);
    } catch (e) {
      console.log("Error while creating session.. " + e);
    }
  }

  function getToken() {
    try {
      const tokenString = localStorage.getItem("transactionId");
      return tokenString;
    } catch (e) {
      console.log("error :::::: " + e);
      return;
    }
  }
  function generateToken() {

    if (!getToken()) {
      const transactionId = Math.random().toString().substring(2, 8);
      setToken(transactionId);
    }
  }
  function showAdduser(id, event) {
    //setShowAssociateCheck((selItems.filter((item) => item.isplan == 1).length >0 && selItems.filter((item) => item.isplan == 0).length > 0 ))

    event.preventDefault();
    setCurIndex(id);
    createUser();
  }



  let inputHandler = (e) => {
    //convert input text to lower case
    e.preventDefault();

    try {
      var lowerCase = e.target.value.toLowerCase();
      setInputText(lowerCase);

    } catch (e) { }
  };



  function selectedOptions() { }
  function onPayemntDone(event) {
    setOpenReceipt(true);
  }
  async function checkForPreAdd() {
    if (!plansInfo || plansInfo.length === 0) {
      return;
    }
    let preadd = localStorage.getItem("preadd_plan");
    if (preadd) {
      let preaddOBJ = JSON.parse(preadd);
      processSelect(null, preaddOBJ.planId, "");
      addMemToLineItems(preaddOBJ.planId, [preaddOBJ.member],);
      localStorage.removeItem("preadd_plan");
    }
  }

  useEffect(() => {
    // checkForPreAdd();
    document.body.style.minHeight = "100vh";
    if (localStorage.getItem("loggedUserInfo")) {
      fetchPlans();
      fetchAddons();
    } else {
      window.location.href = "/login"
    }

    async function fetchPlans() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGymPlansList(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          const tempData = await response.json();
          tempData.length > 0 && tempData.forEach(plan => {
            plan.type = "plan";
          });
        setPlans(tempData);
        
          setLoadedflag(true);
        }
      }
    }

    async function fetchAddons() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getGymAddonsList(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
        } else {
          let resp = await response.json();
          resp.length > 0 && resp.forEach(plan => {
            plan.type = "addons";
          });
          // if (plansInf === null || (plansInf.planFeature && plansInf.planFeature.addons)) {
          setAddons(resp);
          // }
        }
      }
    }

    clearAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Remove clearAll from the dependency array
  function clearAll() {
    // setItemsIndex(0);
    setMquantity([]);

    setSelectPlans([]);
    setSelectPlansIds([]);
    setPrice(0);
    setshowMainResults(false);
    setshowAddons(false);
    setUsersInfo([]);
    setselectedAddons([]);
    setSelItems([]);
    localStorage.removeItem("selItems");
    localStorage.removeItem("selectedusers");
    localStorage.removeItem(uniqueId + "_users");
    localStorage.removeItem("transactionId");
    localStorage.removeItem("allData");
    setOpenPayment(false);
    setOpenReceipt(false);
    setAlreadysel([]);
    //checkForPreAdd();
  }

  let dateTosearch = plansInfo.concat(addons);
  console.log(dateTosearch)
  checkForPreAdd();
 

  function handleDeleteMember(e, mid, planid) {
    //**** Function To remove the selected members***// 
    //****Chinna --24-10-2023 ***/ 
    const selectedItems = selItems.filter((tObj) => tObj._id !== planid);
    let remainselItems = selItems.filter((tObj) => tObj._id === planid)[0];
    remainselItems.members = remainselItems.members.filter((obj) => obj._id !== mid)
    setSelItems([...selectedItems, remainselItems]);
    let updatedAlreadysel = [...Alreadysel];
    let foundIndex = updatedAlreadysel.findIndex(item => item[0] === planid);
    if (foundIndex !== -1) {
      updatedAlreadysel[foundIndex][1] = remainselItems.members;
    } else {
      updatedAlreadysel.push([planid, remainselItems.members]);
    }
    setAlreadysel(updatedAlreadysel);
    decrementCount(e, planid)
  }

  const handleReload = () => {
    window.location.reload();
  }
  return (
    <>
      <LeftPane isactive="1" />
      <Header />
      {apiError ? <PageError handleReload={handleReload} /> : <div
        className="gym-main rightContent"
      >
        <SearchBar
          selItems={selItems}
          viewName="Point of Sale"
          inputText={inputText}
          inputHandler={inputHandler}
          placeholder="Search Plan/Addon"
          data={dateTosearch}
          onselect={processSelect}
          handleTransdate={handleTransdate}
          value={price}
        />
      
        {loadedflag && plansInfo.length === 0 && (
          <>
            No plan created yet. <Link to="/plansandaddons">Click here</Link> to
            create
          </>
        )}
        <div className="container-fluid px-1 mx-1">
          <div className="row grid">
            {showMainResults && (
              <div
                id="user-myBtn"
                className="col-lg-10 col-sm-12 col-md-7 g-col-8"
              >
                <div className="searchbar-cart">
                  <div className="pointonsale-widgets overflow_inh">
                    <div className="searchbar-cart point_sale_addOn">
                      {selItems.length > 0 &&
                        selItems.map((obj, index) => (
                          < >
                            <PATemplate key={index}
                              obj={obj}
                              item={obj.Item}
                              index={index}
                              quantity={obj.quantity}
                              showAdduser={showAdduser}
                              setStartDate={setSDate}
                              decrementCount={decrementCount}
                              incrementCount={incrementCount}
                              tdiscount={discountValues}
                              actualprice={obj.actualPrice}
                              onAddMembers={addMemToLineItems}
                              nonselectmem={Extend}
                              Alreadyselmem={Alreadysel}
                              handleDeleteMember={handleDeleteMember}
                              usersInfo={obj.members}
                              selItems={selItems}
                              taxamount={obj.taxamt}

                              tax={(data && (data.dgsttax[0] === 1 || (data.ugsttax && data.ugsttax.filter((obj) => obj.check === true).length > 0)))}
                            />
                          </>
                        ))}

                      {selectedAddons && selectedAddons.length > 0 && (
                        <>
                          {selectedAddons.map((item, index) => (
                            <>
                              <PATemplate
                                key={index}
                                item={item}
                                obj={item}
                                index={index}
                                price={item.propvalue}
                                tdiscount={discountValues}
                                quantity={item.quantity}
                                showAdduser={showAdduser}
                                setStartDate={setSDate}
                                decrementCount={decrementCount}
                                incrementCount={incrementCount}
                                handleDeleteMember={handleDeleteMember}
                                onAddMembers={addMemToLineItems}
                                usersInfo={item.members}
                                selItems={selItems}
                              />
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="">
                  <Price Discounts={DiscountsValues} price={price} obj={selItems} />
                </div>
              </div>
            )}
            {showAddons && (
              <Addons
                selItems={selItems}
                addons={addons}
                addonaddCallback={addAddons}
                cancelThis={cancelThis}
                checkUserAndForward={checkUserAndForward}
              />
            )}
          </div>
        </div>
        {openAddMember && (
          <Popup
            title={<h3 className="gym-xlarge">Add Member(s)</h3>}
            content={
              <>
                <div>
                  <AddUsers
                    id={getToken()}
                    index={curIndex}
                    onsubmit={onSubmitUser}
                    submit={submitFlag}
                    onAdd={addMemToLineItems}
                  />
                </div>
              </>
            }
            handleClose={togglePopup}
          />
        )}
        {openPayment && (
          <Popup
            title="Payment Information"
            ptype="modal-lg"
            from="transactions"
            content={
              <>
                <div>
                  <Payments
                    onselect={selectedOptions}
                    onsubmit={onPayemntDone}
                    oncancel={oncancelpayment}
                    onhome={gotohome}

                    // discount={discount}
                    Discounts={DiscountsValues}
                    obj={selItems}
                  />
                </div>
              </>
            }
            handleClose={oncancelpayment}
          />
        )}
        {openReceipt && (
          <Popup
            title="Print Receipt"
            from="transactions"
            ptype="modal-lg"
            content={
              <>
                <div>
                  <ReceiptPrint gotohome={gotohome} discount={DiscountsValues} />
                </div>
              </>
            }
            handleClose={gotohome}
          />
        )}
        
        <MDBModal tabIndex='-1' open={selectAlertValue} onClose={() => setSelectAlertValue(false)}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>  <MDBIcon fas icon="exclamation-triangle" className="me-3 fs-5 mt-1" color="warning" />Alert</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={selectAlert}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                To complete the transaction, at least one member must be selected
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn className="me-3 mb-3" color='secondary' onClick={selectAlert}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

      </div>}
      <Footer />
    </>
  );
}
