import React, { forwardRef, useEffect, useState } from "react";
import { MDBDatatable, MDBIcon } from 'mdb-react-ui-kit';
import DatePicker from 'react-datepicker';
import { getCheckStaff, getTrainerSummary } from '../services/apiServices';
import calImg from "./../components/images/calendar.svg";
import ReactGA from 'react-ga';
import { format, parseISO } from "date-fns";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBSelect
} from 'mdb-react-ui-kit';
import ExcelExportButton from "../components/exportToExcel";
import PageError from "./PageError";
import ReportsGraph from "../components/ReportsGraph";
import Loader from "../components/Loader";

function TrainerSummary() {
    const [basicModal, setBasicModal] = useState(false);
    const [graphCheck, setGraphCheck] = useState(false)
    const toggleOpen = () => setBasicModal(!basicModal);
    const [card, setCard] = useState({})
    const [dateArray, setDateArray] = useState({});
    const [trainerdata, setTrainerdata] = useState([]);
    const [DayFilter1, setDayFilter1] = useState("Date");
    const [DayFilter, setDayFilter] = useState("4");
    const [applyFilterClicked, setApplyFilterClicked] = useState(false)
    const [resetFilterClicked, setResetFilterClicked] = useState(false)
    const [selectedTrans, setSelectedTrans] = useState([])
    const [graphinfo, setGraphinfo] = useState({})
    const [graphData, setGraphData] = useState({})
    const [loading, setLoading] = useState()
    const [basicData2, setBasicData2] = useState({
        columns: ["S.no", 'Member', "Trainer", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
        rows: [],
    })
    const [selectData, setSelectData] = useState([
        { text: 'Select', value: "Select" },

    ]);
    const [selectValue, setSelectValue] = useState([]);
    const [customdate, setcustomdate] = useState(DayFilter === "6");
    const today = new Date();
    const currentTime = new Date();
    const startOfToday = new Date(currentTime);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(currentTime);
    endOfToday.setHours(23, 59, 59, 999);

    const yesterday = new Date(currentTime);
    yesterday.setDate(currentTime.getDate() - 1);
    const startOfYesterday = new Date(yesterday);
    startOfYesterday.setHours(0, 0, 0, 0);

    const endOfYesterday = new Date(yesterday);
    endOfYesterday.setHours(23, 59, 59, 999);

    const startOfWeek = new Date(currentTime);
    startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
    startOfWeek.setHours(12, 0, 0, 0);
    const endOfWeek = new Date(currentTime);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(12, 0, 0, 0);

    const startOfMonth = new Date(currentTime);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const endOfMonth = new Date(currentTime);

    const startOfYear = new Date(currentTime);
    startOfYear.setMonth(0, 1);
    startOfYear.setHours(0, 0, 0, 0);

    const endOfYear = new Date(currentTime);
    endOfYear.setMonth(11, 31);
    endOfYear.setHours(23, 59, 59, 999);

    today.setHours(23, 59, 0, 0);
    const [dateRange, setDateRange] = useState([startOfMonth, endOfMonth]);
    const [startDate, endDate] = dateRange;
    const [apiError, setApiError] = useState(false);
    const [basicData, setBasicData] = useState({
        columns: [],
        rows: [],
    });

    const handleReload = () => {
        window.location.reload();
    }

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <span className="gym-input forminput h-40">
                {value === "" ? "Custom Range" : value}
                &nbsp;
                <img
                    src={calImg}
                    onClick={onClick}
                    ref={ref}
                    alt="Custom Range"
                    title="Select Custom Range"
                />
            </span>
        </>
    ));

    async function fetchData() {
        try {
            setLoading(true)
            let startOfDate = startDate
            let endOfDate = endDate
            let Dateobj = {}

            if ((startOfDate && endOfDate)) {
                const startDatef = new Date(startOfDate)
                startDatef.setHours(0, 0, 0, 0)
                const utcstartDate = startDatef.toISOString();
                // location.filter.startDate = utcstartDate;
                let lastoftheday = new Date(endOfDate);
                lastoftheday.setHours(23, 59, 59, 999);
                const utcEndDate = lastoftheday.toISOString();

                //             location.filter.endDate = utcEndDate;
                Dateobj = {
                    startDate: utcstartDate,
                    endDate: utcEndDate,
                }
            }
            const saverep = await getTrainerSummary(
                JSON.parse(localStorage.getItem('loggedUserInfo')).gymid,
                Dateobj
            );
            if (!saverep) {
                return;
            }
            if (saverep === "false") {
                setApiError(true)
                return;
            }
            const responseData = await saverep.json();


            const response = await getCheckStaff(
                JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
            );

            if (!response) {

                return;
            }
            const usersData = await response.json();

            let filteredData = usersData
            if (selectValue.length > 0) {
                filteredData = usersData.filter(item => selectValue.includes(item.firstname + " " + item.lastname));
            }
            setTrainerdata(usersData);
            processData(responseData, DayFilter1, filteredData)
            setApplyFilterClicked(false)
            setResetFilterClicked(false)
            // setBasicData(processedData);
        } catch (error) {
            console.error(error);

        }
    }
    // console.log(trainerMembers);


    const processData = (checkIns, dateformat, trainer) => {
        let formattedData = {};
        // setTrainerdata(trainer);
        if (dateformat === "Month" || dateformat === "Week" || dateformat === "Year") {
            formattedData = checkIns.reduce((acc, cur) => {
                const date = new Date(cur.date);
                const key = getDateKey(date, dateformat);
                if (!acc[key]) {
                    acc[key] = { date: key, data: [] };
                }
                acc[key].data.push(cur);
                return acc;
            }, {});
        } else {
            // Default to daily format
            formattedData = checkIns.reduce((acc, cur) => {
                const dateKey = format(new Date(parseISO(cur.date)), "dd-MM-yyyy");
                if (!acc[dateKey]) {
                    acc[dateKey] = { date: dateKey, data: [] };
                }
                acc[dateKey].data.push(cur);
                return acc;
            }, {});
        }

        const finalResponse = Object.values(formattedData);

        generateTableData(finalResponse, trainer)
    };

    const getDateKey = (date, format) => {
        if (format === "Month") {
            return date.toLocaleString('default', { month: 'short' }) + "-" + date.getFullYear();
        } else if (format === "Week") {
            const month = date.toLocaleString('default', { month: 'short' });
            const weekOfMonth = getWeekOfMonth(date);
            return `${month} ${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} week ${date.getFullYear()}`;
        } else if (format === "Year") {
            return date.getFullYear().toString();
        }
    };

    const getOrdinalSuffix = (weekOfMonth) => {
        if (weekOfMonth === 1) return "st";
        else if (weekOfMonth === 2) return "nd";
        else if (weekOfMonth === 3) return "rd";
        else return "th";
    };

    const getWeekOfMonth = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayOfWeek = firstDayOfMonth.getDay();
        const firstWeekStart = firstDayOfMonth - (dayOfWeek * 86400000);
        const weekNumber = Math.floor((date - firstWeekStart) / (7 * 86400000)) + 1;
        return weekNumber;
    };
    useEffect(() => {
        fetchData();
    }, [applyFilterClicked, resetFilterClicked])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const generateTableData = (data, trainer) => {
        const trainerNames = trainer.map(item => item.firstname + " " + item.lastname);
        const trainerIds = trainer.map(item => item._id);
        const otherIndex = trainerIds.length;
        const columns = ['Date', ...trainerNames, "Others", 'Total'];

        const rows = {};

        // Populate rows with initial zero values
        data.forEach(data2 => {
            const date = data2.date;
            if (!rows[date]) {
                rows[date] = Array(trainerIds.length + 1).fill(0);
            }
            data2.data.forEach(transaction => {
                const index = trainerIds.indexOf(transaction.trainerid);
                if (index !== -1) {
                    rows[date][index]++;
                } else {
                    rows[date][otherIndex]++;
                }
            });
        });

        // Calculate totals for each date
        Object.entries(rows).forEach(([date, counts]) => {
            rows[date].push(counts.reduce((acc, count) => acc + count, 0));
        });

        // Identify non-zero columns
        const nonZeroColumns = columns.filter((_, index) => {
            return index === 0 || // Always keep the "Date" column
                Object.values(rows).some(rowCounts => rowCounts[index - 1] !== 0); // Keep columns with non-zero values
        });

        // Filter the data rows to match the non-zero columns
        const filteredTableData = Object.entries(rows).map(([date, counts]) => {
            return [date, ...counts.filter((_, index) => {
                return nonZeroColumns.includes(columns[index + 1]);
            })];
        });

        // Update state with filtered data
        setBasicData({
            columns: nonZeroColumns,
            rows: filteredTableData.map((rowData, rowIndex) => {
                return rowData.map((cellData, cellIndex) => {
                    if (cellIndex === 0) {
                        return cellData;
                    } else {
                        const trainerName = nonZeroColumns[cellIndex];
                        const trainerId = trainerIds[columns.indexOf(trainerName) - 1];
                        const date = filteredTableData[rowIndex][0];
                        return cellData !== null ? (
                            <button className="popupmember button-link link-color"
                                key={trainerName}
                                onClick={() => handleClik({ date, trainer: trainerName, trainerId, trainerData: trainer, data: data.filter(item => item.date === date) })}
                            >
                                {cellData}
                            </button>
                        ) : null;
                    }
                });
            })
        });
        setLoading(false);
    };


    const handleClik = (tran) => {
        // console.log(tran);


        if (tran.trainer === 'Total') {
            // console.log(tran.data[0].data);
            setSelectedTrans(tran.data[0].data)
        } else if (tran.trainer === "Others") {
            const trainerNames = tran.trainerData.map(trainer => trainer._id);
            // console.log(trainerNames);

            const filteredData = tran.data[0].data.filter(item => {

                const trainerName = item.trainerid;


                return !trainerNames.includes(trainerName);
            });
            // console.log(filteredData);
            setSelectedTrans(filteredData);
        } else {
            // console.log(tran.data[0].data);
            // console.log(tran.trainer);
            const alldata = tran.data[0].data.filter(item => item.trainerid === tran.trainerId)
            // console.log(alldata);
            setSelectedTrans(alldata)
        }
        setDateArray(tran)
        setBasicModal(true)

    }


    useEffect(() => {

        const popupRow = [];
        let snumber = 1

        selectedTrans && selectedTrans.length > 0 && selectedTrans.forEach((item, index) => {

            const memberName = item.member[0].fname + " " + item.member[0].lname
            const attendance = item
            const rarray = [];

            rarray.push(snumber++)
            rarray.push(memberName);
            rarray.push(attendance.trainer ? attendance.trainer : "--");

            rarray.push(attendance.intime ? format(parseISO(attendance.intime), "yyyy-MM-dd") : "NA");
            rarray.push(
                new Date(attendance.intime ? attendance.intime : "NA")
                    .toLocaleTimeString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })
            );
            rarray.push(
                (new Date(attendance.outtime)
                    .toLocaleTimeString("en-IN", {
                        timeZone: "Asia/Kolkata",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })) === "Invalid Date" ? "NA" : new Date(attendance.outtime)
                        .toLocaleTimeString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })
            );
            if (attendance.intime) {

                const intime = new Date(attendance.intime);
                const outtime = new Date(attendance.outtime);

                if (!isNaN(intime) && !isNaN(outtime)) {
                    const timeDiff = Math.abs(outtime - intime);
                    const hours = Math.floor(timeDiff / (60 * 60 * 1000));
                    const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));


                    rarray.push(`${hours.toString().padStart(2, '0')}: ${minutes.toString().padStart(2, '0')}`);

                } else {
                    rarray.push("--");
                }
            } else {
                rarray.push("--");
            }

            popupRow.push(rarray);

        })
        setBasicData2({
            columns: ["S.no", 'Member', "Trainer", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
            rows: popupRow
        })
    }, [selectedTrans])

    const handleClickOutside = () => {
        setBasicData2({
            columns: ["S.no", 'Member', "Trainer", 'Date', 'Check-In', 'Check-Out', 'Hours Spent'],
            rows: []
        })
        setSelectedTrans([]);

        toggleOpen();

    }


    useEffect(() => {

        const generatingGraphData = () => {
            const basrow = basicData.rows;
            const columns = basicData.columns;

            // Extracting labels (months)
            const labels = basrow.map(row => row[0]);
            // console.log(basrow);
            // Extracting datasets
            const datasets = [];
            for (let i = 1; i < columns.length - 1; i++) {
                const columnName = columns[i];
                const data = basrow.map(row => parseInt(row[i].props.children));
                datasets.push({
                    label: columnName,
                    data,
                    backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                });
            }

            setGraphinfo({
                title: "Trainer Summary",
                bar: "Member(s)",
                xtitle: "Member(s)",
                ytitle: `${DayFilter1}(s)`,
            })
            setGraphData(
                {
                    labels,
                    datasets,
                });
        };


        const mergeLabelsAndData = (labels, data) => {
            const mergedData = {};
            labels.forEach((label, index) => {
                mergedData[label] = data[index];
            });
            return mergedData;
        };

        const maxmin = () => {
            const sums = {};
            const basrow = basicData.rows;
            const columns = basicData.columns;
            // console.log(basrow, columns);

            for (let i = 1; i < columns.length - 2; i++) {
                const columnName = columns[i];
                sums[columnName] = 0;

                for (let j = 0; j < basrow.length; j++) {
                    const columnValue = basrow[j][i];

                    sums[columnName] += parseInt(columnValue.props.children);
                }
            }
            const labels = Object.keys(sums);
            const data = Object.values(sums);

            const mergedData = mergeLabelsAndData(labels, data);
            let { max = 0, maxLabel, min = Number.MAX_SAFE_INTEGER, minLabel } = findMaxMinValuesWithLabels(mergedData);


            setCard({
                max: { label: maxLabel, value: max > 0 ? max : "--" },
                min: { label: minLabel, value: min < Number.MAX_SAFE_INTEGER ? min : "--" }
            })
        }

        generatingGraphData()
        maxmin()
    }, [basicData, DayFilter1])



    const findMaxMinValuesWithLabels = (data) => {
        let maxLabel, minLabel;
        let max = 0;
        let min = Number.MAX_SAFE_INTEGER;
        for (const label in data) {
            if (data.hasOwnProperty(label)) {
                const value = data[label];
                if (value > max) {
                    max = value;
                    maxLabel = label;
                }
                if (value < min && value > 0) {
                    min = value;
                    minLabel = label;
                }
            }
        }

        return { max, maxLabel, min, minLabel };
    };

    const downloaddata = (data, title) => {
        ExcelExportButton(data, title);
    }



    const handleApplyAndToggle = () => {
        const sDate = DayFilter === "1" ? startOfToday : (DayFilter === "2" ? startOfYesterday : (DayFilter === "3" ? startOfWeek : (DayFilter === "4" ? startOfMonth : (DayFilter === "5" ? startOfYear : (DayFilter === "6" ? startDate : null)))))
        const eDate = DayFilter === "1" ? endOfToday : (DayFilter === "2" ? endOfYesterday : (DayFilter === "3" ? endOfWeek : (DayFilter === "4" ? endOfMonth : (DayFilter === "5" ? endOfYear : (DayFilter === "6" ? endDate : null)))))
        if (sDate !== null && eDate !== null) {
            setDateRange([sDate, eDate])
        } else {
            setDateRange([])
        }

        setApplyFilterClicked(true)
    }

    const handleResetAndToggle = () => {
        setDayFilter("4")
        setDateRange([startOfMonth, endOfMonth])
        setDayFilter1("Date")
        setResetFilterClicked(true)

    }

    useEffect(() => {
        const trainerSet = new Set();

        trainerdata.forEach(trainer => {
            if (trainer._id && trainer._id.length > 0) {
                trainerSet.add(trainer.firstname + " " + trainer.lastname);
            }
        });

        const uniqueTrainers = Array.from(trainerSet);
        const formattedTrainers = uniqueTrainers.map(trainer => ({
            text: trainer,
            value: trainer
        }));
        if (resetFilterClicked) {
            setSelectValue(uniqueTrainers.map(trainer => (
                trainer
            )))
        }
        setSelectData(formattedTrainers);
    }, [trainerdata, resetFilterClicked, selectValue]);


    useEffect(() => {
        setcustomdate(DayFilter === "6" ? true : false);
    }, [DayFilter]);
    return (
        <React.Fragment>
            {apiError ? <PageError handleReload={handleReload} /> : <div className="mt-2">
                <div className="d-flex align-items-center TrainerSummeryCustomHead">
                    <div className="d-flex">
                        <div class="filters_Count bg-success bg-opacity-10"><p class="text-center mb-1 fw-600">Highest Checkin's</p> {card.max ? card.max.label : "--"}: <b>{card.max ? card.max.value : 0} </b></div>
                        <div class="filters_Count bg-danger bg-opacity-10"><p class="text-center mb-1 fw-600">Lowest Checkin's</p>{card.min ? card.min.label : "--"}: <b> {card.min ? card.min.value : 0}</b></div>
                    </div>
                </div>
                <h6 className="m-0 mt-2 pb-2">The below data represent the summary of the <b>Trainers</b>.</h6>
                <div className="trainerSummeryFilters d-flex align-items-center mb-3">
                    <div className="mob-w-100">
                        <div className="gym-section w-100-mobile">
                            <label className="me-2 fw-bold"> Trainer: </label>
                            <MDBSelect
                                multiple
                                placeholder="All"
                                data={selectData}
                                value={selectValue}
                                onChange={(e) => {
                                    const selectedValues = e.map(item => item.value);
                                    setSelectValue(selectedValues);
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center ms-2 mob-w-100 ">
                        <div className="gym-section w-100-mobile ms-2">
                            <label className="me-2 fw-bold"> Breakdown: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-select placeholder-active active mob-w-100"
                                    onChange={(e) => setDayFilter1(e.target.value)}
                                    value={DayFilter1}
                                >
                                    <option value="Date">Date</option>
                                    <option value="Week">Weekly</option>
                                    <option value="Month">Monthly</option>
                                    <option value="Year">Yearly</option>

                                </select>
                            </div>
                        </div>
                        <div className="gym-section w-100-mobile ms-2">
                            <label className="me-2 fw-bold"> Days: </label>
                            <div className="mob-w-100 ">
                                <select
                                    name="shrs"
                                    id="shr"
                                    className="form-select placeholder-active active mob-w-100"
                                    onChange={(e) => setDayFilter(e.target.value)}
                                    value={DayFilter}
                                >
                                    <option value="7">All</option>
                                    <option value="1">Today</option>
                                    <option value="2">Yesterday</option>
                                    <option value="3">This Week</option>
                                    <option value="4">This Month</option>
                                    <option value="5">This Year</option>
                                    {/* <option value="Week">Weekly</option> */}
                                    {/* <option value="Month">Monthly</option>
                                        <option value="Year">Yearly</option> */}
                                    <option value="6">Custom</option>

                                </select>
                            </div>
                        </div>
                    </div>

                    {customdate && <div className="gym-section w-100-mobile ms-3">
                        <label className="me-2 fw-bold"> Select Custom Date: </label>
                        <div className="gym-xlarge  gym-larg-pos mob-w-100">
                            <DatePicker
                                dateFormat={"d/M/yyyy"}
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    // getDates(update);
                                    setDateRange(update)
                                }}
                                isClearable={false}
                                customInput={<CalenderInput />}
                            />
                        </div>
                    </div>}
                    <div className="mob-div-center mt-3 pt-1 filterApplyMobile">
                        <MDBBtn className="ms-2" onClick={(e) => handleApplyAndToggle()}>Apply</MDBBtn>
                        <MDBBtn className="ms-2" color="secondary" onClick={(e) => handleResetAndToggle()}>Clear</MDBBtn>
                        <MDBBtn className="ms-2" onClick={() => setGraphCheck(!graphCheck)}>{graphCheck ? <span><MDBIcon fas icon="table" className="me-1" />Table </span> : <span ><MDBIcon fas icon="chart-bar" className="me-1" />Graph</span>}</MDBBtn>
                        {!graphCheck && <MDBBtn className="ms-2" onClick={() => downloaddata(basicData, "Trainer Summary")}>Export</MDBBtn>}
                    </div>





                </div>
                {!graphCheck && <div className="trainerSummeryDataTable">
                    {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                        <MDBDatatable entries={25} paging={true} data={basicData} /></>}
                </div>}
                {/* {graphCheck && <div className={graphData.label && graphData.label.length < 10 ? "w-50 border p-4 mb-4 shadow-4" : (graphData.label && graphData.label.length < 20 ? `w-${Math.round((graphData.label && graphData.label.length) / 2)}0 border p-4 mb-4 shadow-4` : "w-100 border p-4 mb-4 shadow-4")}>
                    <ReportsGraph info={graphData} />
                </div>
                } */}    {graphCheck && <div className="w-100 border p-4 mb-4 shadow-4 mob-w-100 mob-p-0">

                    {loading ? (<div className="loader-height"> <Loader /></div>) : <>
                        <ReportsGraph data={graphData} info={graphinfo} /></>}
                </div>}

                <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog size="lg">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{dateArray.trainer === "Total" ? "Total check-In Members" : dateArray.trainer}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={handleClickOutside}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {basicModal
                                    && <div className="trainerMemberSummeryDataTable">
                                        <MDBDatatable entries={25} data={basicData2} />

                                    </div>}
                            </MDBModalBody>
                            <MDBModalFooter className="pe-4 pb-3">
                                <MDBBtn className="ms-2" onClick={() => downloaddata(basicData2, dateArray.trainer === "Total" ? "Total Trainersummary" : dateArray.trainer)}>download</MDBBtn>
                            </MDBModalFooter>

                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

            </div>



            }
        </React.Fragment>
    );
}

export default TrainerSummary;