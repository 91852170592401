import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from "react-router";
import { getAllMembers, getMemeberProfile, logEvent, saveUser } from "../services/apiServices.js";
import { isEmpty } from "../validators/helper.js";
import srcImg from "./../components/images/search.svg";
import PageError from '../views/PageError.js';
import { googleAnalytic } from './googleAnalytics.js';

export default function CreareUser(props) {
  const [error, setError] = useState({});
  const [popup1, setPopup1] = useState(true);
  const [popup2, setPopup2] = useState(false);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputtxt, setInputtxt] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState(['']);
  const [apiError, setApiError] = useState(false);
  const [userForm, setuserForm] = useState({
    fname: "",
    lname: "",
    number: "",
    sex: "",
    age: "",
    email: "",
    height: "",
    weight: "",
    bloodgroup: "",
    address: "",
    country: "",
    city: "",
    referral: "member",
    member: "",
    others: "",
  });
  console.log(userForm)
  const navigate = useNavigate();
  useEffect(() => {
    fetchData1()
    async function fetchData() {
      console.log(props.mId);
      const response = await getMemeberProfile(props.mId);
      if (!response) {
        return;
      }
      if (response == "false") {
        setApiError(true)
        return;
      }
      const record = await response.json();
      if (!record) {

        navigate("/");
        return;
      }
      console.log(record);
      setuserForm(record);
      if (record.member != "") {
        setInputValue(record.member)
      }
    }
    if (props.mId) {
      fetchData();
    }
  }, [navigate]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  async function fetchData1() {
    if (localStorage.getItem("loggedUserInfo")) {
      const resp1 = await getAllMembers(
        JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
      );
      if (resp1 == "false") {
        setApiError(true)
        return;
      }
      const membersData = await resp1.json();
      let tmpData = membersData.map((obj) => obj.fname + " " + obj.lname)
      setData(tmpData);
      console.log(membersData);
      if (membersData) {
        setDropdownOptions(tmpData.filter((item) => item.toLowerCase().includes(inputValue)))
      } else {
        setDropdownOptions([])
      }
    }
  }
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    filterdata(value)
    setShowDropdown(value != ''); // Show the dropdown only if the input is not empty
  };
  console.log(inputValue);
  const handleDropdownSelect = (value) => {
    setInputValue(value);
    updateForm({ member: value });
    setShowDropdown(false);
  };
  let loadTimer = null;
  const filterdata = (value) => {
    //console.log(data.filter((item) => item.toLowerCase().includes(value)));
    if (loadTimer) {
      clearTimeout(loadTimer);
    }
    if (data) {
      setDropdownOptions(data.filter((item) => item.toLowerCase().includes(value)))
    } else {
      setDropdownOptions(["Loading.."])
    }
  }

  function updateForm(value) {
    return setuserForm((prev) => {
      return { ...prev, ...value };
    });
  }
  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};

    // if (userForm.email != "" && !isEmail(userForm.email)) {
    //   errors.email = "Please enter valid email";
    // }
    if (isEmpty(userForm.fname)) {
      errors.fname = "First Name can't be blank";
    }
    if (isEmpty(userForm.lname)) {
      errors.lname = "Last Name can't be blank";
    }
    // if (isEmpty(userForm.number)) {
    //   errors.number = "Contact Number can't be blank";
    // } else
    if (!isEmpty(userForm.number) && !isValidPhoneNumber(userForm.number)) {
      errors.number = "Please enter valid Contact Number";
    }
    // if (isEmpty(userForm.email)) {
    //   errors.email = "Mail  can't be blank";
    // }
    if (isEmpty(userForm.height)) {
      // errors.height = "Height can't be blank";
    }
    if (isEmpty(userForm.weight)) {
      // errors.weight = "Weight can't be blank";
    }
    if (isEmpty(userForm.bloodgroup)) {
      // errors.bloodgroup = "Blood group can't be blank";
    }

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  const memberfunction = (event) => {
    let event1 = event.target.value
    setInputtxt(event1);
    updateForm({ referral: event1 });
    event1 == "member" ? setPopup1(true) : setPopup1(false);
    event1 == "others" ? setPopup2(true) : setPopup2(false);
  }
  async function onSubmit(e) {
    e.preventDefault();
    // const mID = await memberIdValue(); // Await the result of memberIdValue()
    const updates = {
      fname: userForm.fname,
      lname: userForm.lname,
      number: userForm.number,
      sex: userForm.sex,
      age: userForm.age,
      email: userForm.email,
      height: userForm.height,
      weight: userForm.weight,
      city: userForm.city,
      country: userForm.country,
      address: userForm.address,
      referral: userForm.referral,
      member: userForm.member,
      others: userForm.others,
      bloodgroup: userForm.bloodgroup,
      _id: props.mId == 0 ? -1 : props.mId,
    };
    console.log(userForm);
    console.log(updates);

    let errors = validateForm(e);
    if (errors == true) {
      if (localStorage.getItem("loggedUserInfo")) {
        let saverep = await saveUser(updates, JSON.parse(localStorage.getItem("loggedUserInfo")).gymid);
        if (saverep == "false") {
          setApiError(true)
          return;
        }
        const recinfo = await saverep.json();
        console.log(recinfo, "00000000000000000000000000000000000000");

        let ip = localStorage.getItem("ipaddress");
        let browserinfo = browserName + "-" + browserVersion + ".0.0";
        let Eventtype = "member";
        let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
        let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
        if (!props.mId) {
          let flag = `Member Created Successfull (${updates.fname} ${updates.lname})`;
          await googleAnalytic("CreateMember", 'Member', 'Add Member Button', 'Create Member')
          
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        }
        else {
          let flag = `Member Updated Successfull (${updates.fname} ${updates.lname})`;
          await googleAnalytic("CreateMember", 'Member', 'Save Member Button', 'Updated Member')
         
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        }
        if (props.from == "reload" || props.from == "memberdetails") {
          window.location.reload(false);
        }
        localStorage.setItem(
          props.mId + "_users",
          JSON.stringify({ ...saverep, index: props.index })
        );
        props.backallback(e, recinfo.val);
      }
    } else {
      setError(errors);
    }

  }

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <form
        action="#"
        target="_blank"
        className="business-form"
        onSubmit={onSubmit}
      >
        <MDBRow>
          <MDBCol sm={12} md={3}>
            <div className="mob-mt-4">
              <label>First Name<span className="text-danger">*</span></label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.fname}
                onChange={(e) => updateForm({ fname: e.target.value })}
              />
              {error.fname && (
                <>
                  <p className="clr-red">{error.fname}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={3}>
            <div className="mob-mt-4">
              <label>Last Name<span className="text-danger">*</span></label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.lname}
                onChange={(e) => updateForm({ lname: e.target.value })}
              />
              {error.lname && (
                <>
                  <p className="clr-red">{error.lname}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={3}>
            <div className="mob-mt-4">
              <label>Mobile No</label>
          

              <div className="d-flex align-items-center">
                <PhoneInput
                  className="gym-input user-pdng input-select d-flex p-0"
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  value={userForm.number}
                  // onInput={(e) => e.target.value = e.target.value.slice(0, 15)}
                  onChange={(value) => updateForm({ number: value })}
                />
              </div>
              {error.number && (
                <>
                  <p className="clr-red">{error.number}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={3}>
            <div className="mob-mt-4">
              <label>Gender</label>
              <select
                name="gender"
                id="gender"
                className="gym-input user-pdng input-select"
                value={userForm.sex}
                onChange={(e) => updateForm({ sex: e.target.value })}
              >
                <option value="">Select</option>
                <option value="Male" >
                  Male
                </option>
                <option value="Female">Female</option>
              </select>

              {error.sex && (
                <>
                  <p className="clr-red">{error.sex}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={4}>
            <div className="mt-4">
              <label>Email</label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Email"
                required=""
                value={userForm.email}
                onChange={(e) => updateForm({ email: e.target.value })}
              />
              {/* {error.email && (
                <>
                  <p className="clr-red">{error.email}</p>
                </>
              )} */}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={2}>
            <div className="mt-4">
              <label>Age</label>
              <input
                className="gym-input user-pdng"
                type="number"
                name="Age"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 2); // Limit value to 2 characters
                }}
                value={userForm.age}
                onChange={(e) => updateForm({ age: e.target.value })}
              />
              {error.age && (
                <>
                  <p className="clr-red">{error.age}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={2}>
            <div className="mt-4">
              <label>Height (Cm)</label>
              <input
                className="gym-input user-pdng"
                // type="text"
                // name="Name"
                // required=""
                type="number"
                name="Age"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 3); // Limit value to 2 characters
                }}
                value={userForm.height}
                onChange={(e) => updateForm({ height: e.target.value })}
              />
              {error.height && (
                <>
                  <p className="clr-red">{error.height}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={2}>
            <div className="mt-4">
              <label>Weight (Kg)</label>
              <input
                className="gym-input user-pdng"
                name="Name"
                required=""
                type="number"
                value={userForm.weight}
                onChange={(e) => updateForm({ weight: e.target.value })}
              />
              {error.weight && (
                <>
                  <p className="clr-red">{error.weight}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={2}>
            <div className="mt-4">
              <label>Blood Group</label>
              <select name="" id="" value={userForm.bloodgroup}
                className="gym-input user-pdng input-select  "
                onChange={(e) => updateForm({ bloodgroup: e.target.value })}>
                <option value="">Select</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </select>
          
              {error.bloodgroup && (
                <>
                  <p className="clr-red">{error.bloodgroup}</p>
                </>
              )}
            </div>
          </MDBCol>
          <MDBCol sm={12} md={4}>
            <div className="mt-4">
              <label>City</label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.city}
                onChange={(e) => updateForm({ city: e.target.value })}
              />
            </div>
          </MDBCol>
          <MDBCol sm={12} md={4}>
            <div className="mt-4">
              <label>Country</label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.country}
                onChange={(e) => updateForm({ country: e.target.value })}
              />
            </div>

          </MDBCol>
          <MDBCol sm={12} md={4}>
            <div className="mt-4">
              <label>Address</label>
              <input
                className="gym-input user-pdng"
                type="text"
                name="Name"
                required=""
                value={userForm.address}
                onChange={(e) => updateForm({ address: e.target.value })}
              />
            </div>
          </MDBCol>
          {(props.from !== "memberdetails") ? <><MDBCol sm={12} md={4}>
            <div className="mt-4">
              <label>Referral</label>
              <select
                name="referral"
                id="referral"
                className="gym-input user-pdng input-select"
                value={inputtxt}
                onChange={memberfunction}
              >
                <option value="member" default>Member</option>
                <option value="searchedonline" >Searched Online</option>
                <option value="instagram"> Instagram</option>
                <option value="facebook" >Facebook</option>
                <option value="friends">Friends</option>
                <option value="direct" >Direct</option>
                <option value="others" >Other</option>

              </select>
            </div>
          </MDBCol>
            <MDBCol sm={12} md={4}>
              {popup1 &&
                <div className="mt-4">
                  <label>Member</label>
                  <input className="gym-input user-pdng" placeholder="Search Member" type="text" value={inputValue} onChange={handleInputChange} />
                  <img src={srcImg} width="15px" className="searchicon searchIcon-position" alt="Search" />
                  {showDropdown && (
                    <div className="dropdown usersearch position-absolute left-0">
                      {dropdownOptions.map((option, index) => (
                        <div
                          key={option.id}
                          className="d-flex home-select-drp"
                          onClick={() => handleDropdownSelect(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>}
              {popup2 &&
                <div className="mt-4">
                  <label>Others</label>
                  <input
                    className="gym-input user-pdng"
                    type="text"
                    name="Name"
                    required=""
                    value={userForm.others}
                    onChange={(e) => updateForm({ others: e.target.value })}
                  />
                </div>}
            </MDBCol></> : <></>}
        </MDBRow>


        {!props.mId && <></>}
        <div className="clearfix mt-4">
          <button
            type="button"
            className="btn btn-secondary"
            data-mdb-dismiss="modal"
            onClick={props.backallback}
          >
            Cancel
          </button>
          &nbsp;
          <button
            className="btn btn-primary"
            data-mdb-dismiss="modal"
            type="submit"
          >
            {props.mId ? "Save" : "Add"}
          </button>
        </div>
      </form >}

    </>
  );
}