import { MDBDatatable, MDBIcon, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';

function DefaultTempletes({ handleUpdateSmsTemplete, AllSMS }) {
 
    const [basicActive2, setBasicActive2] = useState('tab1');
    let tmrow2 = [];

    const membersinfo = AllSMS.length > 0 ? AllSMS.filter(item => item.type == "member") : []
    console.log(membersinfo);
    membersinfo.length > 0 && membersinfo.map((row) => {

        let rarray = [];
        rarray.push(row.smscategory);
        rarray.push(row.templete);
        rarray.push(
            <div onClick={() => { EditFunction(row) }} className='text-center'>
                <MDBIcon fas icon="edit" className='fs-5 text-center w-w-120px' />
            </div>
        );
        tmrow2.push(rarray);
    });
    const basicData2 = {
        columns: [
            "SMS CATEGORY",
            "CUSTOM MESSAGE",
            "ACTION",
        ],
        rows: tmrow2,
    };
    console.log(AllSMS);
    let tmrow1 = [];
    const staffinfo = AllSMS.length > 0 ? AllSMS.filter(item => item.type == "staff") : []
    staffinfo.length > 0 && staffinfo.map((row) => {
        let rarray = [];
        rarray.push(row.smscategory);
        rarray.push(row.templete);
        rarray.push(
            <>
                <div onClick={() => { EditFunction(row); }} className='text-center'>
                    <MDBIcon fas icon="edit" className='fs-5 w-w-120px' />
                </div>
            </>
        );
        tmrow1.push(rarray);
    });

    const basicData1 = {
        columns: [
            "SMS CATEGORY",
            "CUSTOM MESSAGE",
            "ACTION",
        ],
        rows: tmrow1,
    };

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
      }, []);
    const EditFunction = (value) => {
        // toggleShow();
        // setForm(value);
        handleUpdateSmsTemplete(value)

    };
    const handleBasicClick2 = (value) => {
        if (value === basicActive2) {
            return;
        }
        setBasicActive2(value);
    };
    return (
        <div>
            <MDBTabs className='mb-3'>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick2('tab1')} active={basicActive2 === 'tab1'}>
                        Owners
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick2('tab2')} active={basicActive2 === 'tab2'}>
                        Members
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>
            <MDBTabsContent>
                <MDBTabsPane show={basicActive2 === 'tab1'} open={basicActive2 === 'tab1'}>
                    <div className='sms-settings-table'>
                        <MDBDatatable
                            searchLabel="search here"
                            search fixedHeader maxHeight='70vh' hover data={basicData1} />
                    </div>
                </MDBTabsPane>
                <MDBTabsPane show={basicActive2 === 'tab2'} open={basicActive2 === "tab2"}>
                    <div className='sms-settings-table'>
                        <MDBDatatable search
                            searchLabel="search here"
                            fixedHeader maxHeight='70vh' hover data={basicData2} />
                    </div>
                </MDBTabsPane>
            </MDBTabsContent>
        </div>
    )
}

export default DefaultTempletes