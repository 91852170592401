import React, { useEffect, useState } from 'react';
import logtimeImg from "../components/images/log_timeGreen.svg";

const Timer = ({ endTime }) => {
    const [remainingTime, setRemainingTime] = useState('');

    useEffect(() => {
        const calculateRemainingTime = () => {
            const now = new Date();
            const timeDiff = endTime - now;

            if (timeDiff <= 0) {
                setRemainingTime("Inactive");
                return;
            }

            const hours = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
            const minutes = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            setRemainingTime(`${hours}h ${minutes}m ${seconds}s`);
        };

        calculateRemainingTime(); // Initial calculation
        const timer = setInterval(calculateRemainingTime, 1000);

        return () => clearInterval(timer); // Cleanup timer on component unmount
    }, [endTime]);

    return (
        <div style={styles.card}>
            {/* <img className="w-16 logaTimeIcon" src={logtimeImg} alt="logatime" /> */}
            <span style={styles.time}>{remainingTime}</span>
        </div>
    );
};

// Define styles
const styles = {
    card: {
        display: 'flex',
        alignItems: 'center',
        // background: "#ff6a6a",
        // border: "1px solid #ff6a6a",
        color: "#ff6a6a",
        // padding: '10px',
        // borderRadius: '5px',
        // color: 'white',
        fontWeight: 'bold',
        // boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    },
    icon: {
        marginRight: '8px',
    },
    time: {
        fontSize: '18px',
    },
};

export default Timer;
