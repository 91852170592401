import React, { useState, useEffect } from "react";
import trancsacImg from "./../components/images/Transactions.svg";

export default function AddonProps(props) {
  const [isChecked, setIsChecked] = useState(props.checked);
  const [selectedselItems, setSeletectedselItems] = useState(props.selItems);
  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked,]);

  useEffect(() => {
    setSeletectedselItems(props.selItems);
  }, [props, selectedselItems]);
  // console.log(selectedselItems, "5555555555555555555555555555555555555555555555555555555555555555555");

  useEffect(() => {
    // Update isChecked when selItems change
    const isSelected = selectedselItems.some(
      (item) => item._id === props.addonItem._id
    );
    setIsChecked(isSelected);
  }, [selectedselItems, props.addonItem._id]);

  function addAddon(e, selItem) {
    e.preventDefault();

    try {
      selItem = { ...selItem, propName: props.propName };
      setIsChecked(!isChecked);

      sendAddonsuUpdate(selItem, !isChecked);
    } catch (e) {
      console.log("Error: ", e);
    }
  }

  function sendAddonsuUpdate(selItem, ischecked) {
    console.log(selItem);
    props.addonaddCallback(null, props.addonItem._id, ischecked);
  }

  return (
    <div className="addOn-Details">
      <span>
        <span className="size">
          Validity :{" "}
          {props.rentbasis === "0"
            ? "Hourly"
            : props.rentbasis === "1"
              ? "Daily"
              : props.rentbasis === "2"
                ? "Monthly"
                : "Yearly"}{" "}
        </span>
      </span>
      <div className="d-flex">
        <img src={trancsacImg} width="9" className="me-1" />
        {props.item.propvalue}
        <div className="user-prev-details ms-1">
          <a
            href="#"
            onClick={(event) => addAddon(event, props.item)}
          >
            <input
              type="checkbox"
              id={`${props.propName}_${props.item.propvalue}_ap_chk`}
              checked={isChecked}
            />
            <label
              htmlFor={`${props.propName}_${props.item.propvalue}_ap_chk`}
            ></label>
          </a>
        </div>
      </div>
    </div>
  );
}
