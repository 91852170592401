import React, { useState } from 'react';

import uploadIcon from "../components/images/upload.svg"
import { MDBBtn } from 'mdb-react-ui-kit';
import axios from 'axios';
function UploadLogo(props) {

    const [selectedImage, setSelectedImage] = useState(null);
    const [error, setError] = useState("");
    const [closech, setclosech] = useState(false)

    const close = () => {
        setSelectedImage(null);

        setclosech(true)
    }

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        try {
            console.log('Selected file:', selectedFile);

            console.log('File size:', selectedFile.size);

            const reader = new FileReader();

            reader.onload = async (e) => {
                const img = new Image();
                img.onload = async () => {
                    let MAX_WIDTH = 1920;
                    let MAX_HEIGHT = 1080;
                    if (props.from === "banner") {
                        MAX_WIDTH = 800;
                        MAX_HEIGHT = 600;
                    }

                    let width = img.width;
                    let height = img.height;
                    if (selectedFile.size > 10000000) {
                        setError("Please select a file below 10MB.");
                        return;
                    } else if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                        setError(`Image dimensions should be within ${MAX_WIDTH}x${MAX_HEIGHT}.`);
                        return;
                    } else {
                        uploadLogoHandler(selectedFile);
                    }
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    console.log(width, height, "width,heigth");
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
                    canvas.toBlob(async (blob) => {
                        console.log('Converted PNG blob:', blob);

                        setSelectedImage(URL.createObjectURL(blob));
                    }, 'image/png');
                };
                img.src = e.target.result;
            };

            reader.readAsDataURL(selectedFile);

        } catch (error) {
            setError('Error converting image to PNG format.');
            console.error('Error converting image:', error);
        }
    };

    // useEffect(() => {
    //     if (file) {
    //         uploadLogoHandler();
    //     }
    // }, [file]);

    const uploadLogoHandler = async (selectedFile) => {

        if (!selectedFile) {
            console.error('No file selected');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            let response = ""
            console.log(props.Gid[0]);
            if (props.from === "banner") {
                response = await axios.post(`${process.env.REACT_APP_PRIMARY_SERVER_URL}api/smstemplete/uploadbanner/${props.Gid[0]}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } else {
                response = await axios.post(`${process.env.REACT_APP_PRIMARY_SERVER_URL}api/businessinfo/uploadlogo/${props.Gid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            handleUrl(response.data.url)
            console.log(response.data);
        } catch (error) {
            console.error('Error uploading logo:', error);
        }
    };

    const handleUrl = (data) => {
        console.log(data);
        props.handleUrl(data)
    }


    return (
        <div className="mt-2" onClick={props.onClick}>
            {props.from !== "banner" && <div className="d-flex align-items-center justify-content-between">
                <h5 className='m-0'>Upload Logo</h5>

            </div>}

            {(selectedImage || (closech ? false : props.imageurl)) ? (
                <>
                    <div className="mt-2 d-flex align-items-center justify-content-center border p-2 position-relative" onClick={() => props.from === "banner" ? close() : null}>
                        {/* <MDBBtn color="link" onClick={close} className='position-absolute  top-0 end-0 mt-2 me-2'>
                            <MDBIcon fas icon="times" className="fs-5" />
                        </MDBBtn> */}
                        <img src={selectedImage ? selectedImage : props.imageurl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '120px' }} />
                    </div>
                    {props.from !== "banner" && <div className="mt-2 float-end">
                        <MDBBtn onClick={close}>Change Logo</MDBBtn>
                    </div>}
                </>
            ) : (
                <>
                    <div className="uploadOuter mt-3 w-100 cursor-pointer">
                        <span className="dragBox2 upload-content cursor-pointer">
                            <span>
                                <img src={uploadIcon} className="upload-img" alt="Upload Icon" />
                            </span>
                            <span>Click here or Drag and Drop to upload {props.from === "banner" ? "Banner" : "Logo"}.</span>
                            <input
                                type="file"
                                id="uploadFile"
                                className="upload__img LOA cursor-pointer"
                                onChange={handleFileChange}
                                multiple
                            />
                        </span>
                    </div>
                    <span className=" upload-content d-block mt-2"><b>Note: </b>Recommended to upload with transparent background</span>
                </>
            )}

            {error && <p className="text-danger">{error}</p>}
        </div>
    );
}
export default UploadLogo;
