import React from "react"
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import unplug from "../components/images/unplug.jpg"
function PageError(props) {
    const handleReload=()=>{
props.handleReload()
    }
    return (
        <>
            <div className="ErrorPage">
                <img src={unplug} alt="" className="unplug_img" />
                <div className="text-center">
                    <h3>Oops, that's our bad</h3>
                    <h6 className="lh-base">We're not exactly sure what happened, but something went wrong. <br /> If you need immediate help, please let us know.</h6>
                    <MDBBtn outline color="secondary mt-2" onClick={handleReload}> <MDBIcon fas icon="redo" className="me-2" />Try again</MDBBtn>
                </div>
            </div>
        </>
    )
}
export default PageError