import React, { useEffect, useState, useRef } from "react";
import PageError from "./PageError.js";
import { MDBDatatable } from "mdb-react-ui-kit";
import { GetBussinessEvents } from "../services/apiServices.js";
import Loader from "../components/Loader.js";
import LeftPane from "../components/LeftPane.js";
import Header from "../components/Header.js";
import Timer from "../components/reamainingTimer.js";


function AccountsEventsReports() {
    const [bussinessEvents, setBussinessEvents] = useState([]);
    const [apiError, setApiError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [basicData, setBasicData] = useState({
        columns: [
            { label: 'S.no', field: 'Sno' },
            { label: 'Logged-In User', field: 'loggedInUser' },
            { label: 'Email', field: 'email' },
            { label: 'Number', field: 'number' },
            { label: 'Bussness Name', field: 'bussnessName' },
            { label: 'IP Address', field: 'iPAdress' },
            { label: 'login Time', field: 'sessionStart' },
            { label: 'logout Time', field: 'sessionEnd' },
            { label: 'Timeout for logout', field: 'remainingTime' },
        ],
        rows: [],
    });
    const fetchDataCalled = useRef(false); // Use useRef to ensure fetchData is called only once

    const fetchData = async () => {
        if (fetchDataCalled.current) return; // Prevent multiple API calls
        fetchDataCalled.current = true;

        setLoading(true);

        const response = await GetBussinessEvents(
            JSON.parse(localStorage.getItem("loggedUserInfo")).gymid,
        );

        if (!response) {
            setLoading(false);
            return;
        }

        if (response === "false") {
            setApiError(true);
        } else {
            const record = await response.json();
            if (!record) {
                setLoading(false);
                return;
            }

            const sortedRecords = record.length > 0 ? record.sort((a, b) => {
                // Assuming sessionevents is an array and you want to sort by the outdate of the first event
                const aOutDate = a.sessionevents.length > 0 ? new Date(a.sessionevents[0].outdate) : new Date(0); // Fallback to a date far in the past if no sessionevents
                const bOutDate = b.sessionevents.length > 0 ? new Date(b.sessionevents[0].outdate) : new Date(0); // Fallback to a date far in the past if no sessionevents

                return bOutDate - aOutDate; // Sort in descending order
            }) : [];

            setBussinessEvents(sortedRecords);
            console.log(sortedRecords);
            processBusinessEvents(sortedRecords); // Process data into rows
        }

        setLoading(false);
    };

    const processBusinessEvents = (events) => {
        const popupRow = [];

        events && events.length > 0 && events.forEach((item, index) => {

            const sessioninfo = item.sessionevents.length > 0 ? item.sessionevents[0] : {};
            const eventInfo = item.eventinfo ? item.eventinfo : {};

            const sessionStart = new Date(sessioninfo.indate);
            const sessionEnd = new Date(sessioninfo.outdate);

            const rowData = {
                Sno: index + 1,
                loggedInUser: item.email ? item.email : item.username,
                email: item.email ? item.email : "--",
                number: item.number ? item.number : "--",
                bussnessName: item.businessName ? item.businessName : "--",
                iPAdress: eventInfo.ipAddress ? eventInfo.ipAddress : "--",
                sessionStart: <span className='trans-date'>{sessionStart.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</span>,
                sessionEnd: <span className='trans-date'>{sessionEnd.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</span>,
                remainingTime: <Timer endTime={sessionEnd} />,
                sessionEndRaw: sessionEnd
            };
            popupRow.push(rowData);
        });

        setBasicData(prevData => ({
            ...prevData,
            rows: popupRow
        }));
    };

    useEffect(() => {
        fetchData(); // Ensure data is fetched only once on component mount
    }, []);

    const handleReload = () => {
        window.location.reload();
    }

    return (
        <>
            <LeftPane isactive="13" />
            <Header />
            {apiError ? <PageError handleReload={handleReload} /> :
                <div className="mt-4 rightContent">
                    <div className="">
                        {loading ? (
                            <div className="loader-height"><Loader /></div>
                        ) : (
                            <MDBDatatable
                                fixedHeader
                                search
                                maxHeight='calc(100vh - 223px)'
                                entries={25}
                                paging={true}
                                data={basicData}
                            />
                        )}
                    </div>
                </div>
            }
        </>
    );
}

export default AccountsEventsReports;