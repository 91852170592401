import React from "react";

export default function Toast(props) {
  return (
    <div className="text-center d-flex justify-content-center">
      <div id="snackbar-submit" className="show-submit alert alert-success alert-fixed">
        {props.message}
      </div>
    </div>
  );
}
