import React, { useEffect} from "react";

const SubPopup = (props) => {
  const className =("modal-dialog " + props.ptype);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        // if(window.confirm("Are you sure want to close this popup?")){
        props.handleClose();
        //}
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);


  return (
    <div
      className="modal fade show"
      id="staticBackdrop"
      data-mdb-backdrop="static"
      data-mdb-keyboard="false"
      tabindex="1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className={className}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {props.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-mdb-dismiss="modal"
              aria-label="Close"
              onClick={props.handleClose}
            ></button>
          </div>
          <div className="modal-body popupppp"> {props.content}</div>
        </div>
      </div>
    </div>
  );
};

export default SubPopup;
