import React from "react";
import Popup from "./Popup";
 function Privacy(props){
  
  return (
          <>


                <div className="container p-15 font " >

                <section>
                  <h5>Collection of Personal Information:</h5>
                  <p>When you use Gym Admin Online, we may collect certain personal information from you, such as your name, email address, and contact details. This information is necessary for the proper functioning of the software and to provide you with the requested services.
                  </p>
                </section>

                <section>
                  <h5>Use of Personal Information:</h5>
                  <p clas>The personal information collected will be used for the purposes of managing your gym administration activities, providing customer support, and improving the functionality of Gym Admin Online. We will not sell, rent, or disclose your personal information to any third parties without your consent, except as required by law.
                  </p>
                </section>

                <section>
                  <h5>Data Security:</h5>
                  <p>We take appropriate measures to protect your personal information from unauthorized access, loss, or disclosure. However, please note that no data transmission over the internet or storage system can be guaranteed to be 100% secure.
                  </p>
                </section>

                <section>
                  <h5>Cookies and Tracking Technologies:</h5>
                  <p>Gym Admin Online may use cookies and similar tracking technologies to enhance your user experience and collect usage information. These technologies may collect information such as your IP address, browser type, and operating system. You can choose to disable cookies in your browser settings, but this may affect certain functionalities of the software.
                  </p>
                </section>

                <section>
                  <h5>Third-Party Links:</h5>
                  <p>Gym Admin Online may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of these sites before providing any personal information.
                  </p>
                </section>

                <section>
                  <h5>Data Retention:</h5>
                  <p>We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by law.
                  </p>
                </section>

                <section>
                  <h5>Changes to the Privacy Policy:</h5>
                  <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective upon posting the revised Privacy Policy on the Gym Admin Online website. It is your responsibility to review this Privacy Policy periodically.
                  </p>
                </section>
            <p>
              <h5>Please note:</h5>Privacy Policy is a general example and may not cover all specific details or legal requirements. It is recommended to consult with a legal professional to create a comprehensive and tailored Privacy Policy for your gym's specific needs and jurisdiction.</p>
           </div>
        </>
        )
    }

export default Privacy;
