import { MDBCheckbox, MDBCol, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'

function VeiwWaiverForm({ data }) {
    console.log(data);
    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
        return formattedDate;
    }
    return (
        <>
            <MDBRow>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers w-100 mt-2">
                        <strong>First Name:</strong>
                    </div>
                    <div className="pt-1 w-100 ">
                        <label className="mt-2">{data.fname}</label>
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers w-100 mt-2">
                        <strong>Last Name:</strong>
                    </div>
                    <div className="pt-1 w-100 ">
                        <label className="mt-2">{data.lname}</label>
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-2">

                    <div className="tel-numbers mt-2">
                        <strong>Relation:</strong>
                    </div>
                    <div className="form pt-1 w-100">
                        <div className="d-flex align-items-start">
                            <label className="mt-2 fw-bold">{data.relation}</label>
                            <label className="mt-2 ms-1">{data.relationfirstname}</label>
                            <label className="mt-2 ms-1">{data.relationlastname}</label>

                        </div>
                    </div>
                </MDBCol>


                <MDBCol sm={12} md={3} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>DOB</strong>
                    </div>
                    <div className="form pt-1 w-100">
                        <label className="mt-2 ms-1">{data.dateofbirth ? data.dateofbirth : "--"}</label>
                    </div>

                </MDBCol>

                <MDBCol sm={12} md={3} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Blood Group</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.bloodgroup ? data.bloodgroup : "--"}</label>
                </MDBCol>

                <MDBCol sm={12} md={3} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Gender</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.gender ? data.gender : '--'}</label>
                </MDBCol>

                <MDBCol sm={12} md={3} className="mb-2">
                    <div className="tel-numbers mt-2 w-100">
                        <strong>Phone</strong>
                    </div>
                    <div className="form pt-1 w-100">
                        <label className="mt-2 ms-1">{data.phone ? data.phone : '--'}</label>
                    </div>
                </MDBCol>
                {data.consultingadultfirstname && <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers w-100 mt-2">
                        <strong>Consenting Adult:</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.consultingadultfirstname ? data.consultingadultfirstname
                        + " " + data.consultingadultlastname : "--"}</label>

                </MDBCol>}
                {data.consultingadultcontact && <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Consenting Adult Contact</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.consultingadultcontact ? data.consultingadultcontact : "--"}</label>
                </MDBCol>}

                {data.consultingadultrelation && <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers mt-2">
                        <strong>Consenting Adult Relation:</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.consultingadultrelation && data.consultingadultfirstname ? data.consultingadultrelation : "--"}</label>


                </MDBCol>}
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers mt-2 w-100">
                        <strong >Email</strong>
                    </div>
                    <div className="form pt-1 w-100">
                        <label className="mt-2 ms-1">{data.email ? data.email : "--"}</label>
                    </div>
                </MDBCol>

                <MDBCol sm={12} md={8} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Street/Apartment</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.street ? data.street : "--"}</label>
                </MDBCol>

                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Area</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.area ? data.area : "--"}</label>

                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>City</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.city ? data.city : "--"}</label>

                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Country</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.country ? data.country : "--"}</label>

                </MDBCol>
                <div className="tel-numbers mt-2">
                    <strong>Emergency contact</strong>
                </div>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Name</strong>
                    </div>
                    <div className="d-flex ">
                        <label className="mt-2 ms-1">{data.emergencyfirstname ? data.emergencyfirstname + " " + data.emergencylastname : "--"}</label>
                    </div>

                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Contact Number</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.emergencycontact ? data.emergencycontact : "--"}</label>
                </MDBCol>
                <MDBCol sm={12} md={4} className="mb-2">
                    <div className="tel-numbers  mt-2">
                        <strong>Relationship</strong>
                    </div>
                    <label className="mt-2 ms-1">{data.emergencyrelation ? data.emergencyrelation : "--"}</label>
                </MDBCol>
                <div className="tel-numbers mt-2">
                    <strong>Agreement</strong>
                </div>
                <div className="agreement-content ps-2" dangerouslySetInnerHTML={{ __html: data.agreement }} />

                <div className='d-flex mt-2'>
                    <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' className='mt-1' checked disabled
                    />
                    <div
                        className='m-0 ms-2'
                        dangerouslySetInnerHTML={{
                            __html: (data && data.checkboxcontent) ? (data.checkboxcontent.replace(/\n/g, '<br />')) :
                                ` By checking this box, I acknowledge that I have read, understood, and agree to the terms and conditions
                                        outlined in the waiver form. I accept full responsibility for my participation, or for the participation of the
                                        minor I am legally responsible for, in climbing activities at Crag Studio, and recognize the inherent risks
                                        involved.`
                        }}>
                    </div>
                </div>
            </MDBRow>

            <div className="mt-2">
                <MDBCol sm={12} md={6}>
                    <div className="tel-numbers">
                        <strong>Agreement Date: </strong>{formatDate(data.createdAt)}</div>
                </MDBCol>
            </div>
        </>
    )
}

export default VeiwWaiverForm