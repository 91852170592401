
import React, { useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import ReactGA from 'react-ga';
import { useNavigate } from "react-router";
import { getSlotByPId, getSlots, saveSlot } from "../services/apiServices";
import { logEvent } from "../services/apiServices.js";
import { isEmpty } from "../validators/helper.js";
import PageError from "./PageError";
import { googleAnalytic } from "../components/googleAnalytics";

const CreateSlot = (props) => {
  const [apiError, setApiError] = useState(false);
  const [form, setForm] = useState({
    name: "",
    shrs: "1",
    smins: "1",
    shflag: "1",
    ehrs: "1",
    emins: "1",
    ehflag: "1",
  });

  const [error, setError] = useState({});
  const [slots, setSlots] = useState([]);

  const navigate = useNavigate();


  const checkSlotOverlap = (newSlot, existingSlots) => {
    const newSlotStart = new Date(newSlot.shrs, newSlot.smins);
    const newSlotEnd = new Date(newSlot.ehrs, newSlot.emins);

    for (const existingSlot of existingSlots) {
      const existingSlotStart = new Date(existingSlot.shrs, existingSlot.smins);
      const existingSlotEnd = new Date(existingSlot.ehrs, existingSlot.emins);

      const isNameMatch = existingSlot.name.toLowerCase() === newSlot.name.toLowerCase();


      const isSameStartTime = newSlotStart.getTime() === existingSlotStart.getTime();
      const isSameEndTime = newSlotEnd.getTime() === existingSlotEnd.getTime();

      if (!(newSlot._id === existingSlot._id)) {
        if (isNameMatch && isSameStartTime && isSameEndTime) {
          return 'Slot name and time overlap with existing slots.';
        } else if (isNameMatch) {
          return 'Slot name matches with existing slots.';
        } else if (isSameStartTime && isSameEndTime) {
          return 'Slot time overlaps with existing slots.';
        }
      }
    }

    return '';
  };

  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  const handleReload = () => {
    window.location.reload();
  }


  let validateForm = (e) => {
    console.log("Validating form...");
    let errors = {};

    if (isEmpty(form.name)) {
      errors.name = "Name can't be blank";
    }

    console.log(errors);
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  console.log(form);

  async function onSubmit(e) {

    e.preventDefault();
    const updates = {
      name: form.name,
      shrs: form.shrs,
      smins: form.smins,
      shflag: form.shflag,
      ehrs: form.ehrs,
      emins: form.emins,
      ehflag: form.ehflag,
      _id: props.id && props.id !== "-1" ? props.id : null,
    };
    console.log(updates);
    let errors = validateForm(e);
    if (errors === true) {
      // Fetch existing slots
      const existingSlotsResponse = await getSlots(
        localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
      );
      if (existingSlotsResponse === "false") {
        setApiError(true)
        return;
      }
      const existingSlots = await existingSlotsResponse.json();


      const overlapErrorMessage = checkSlotOverlap(updates, existingSlots);

      if (overlapErrorMessage) {
        setError({ overlap: overlapErrorMessage });
      } else {
        // Proceed with saving the slot
        let saverep = await saveSlot(
          updates,
          localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : ""
        );
        console.log(saverep);
        if (saverep === "false") {
          setApiError(true)
          return;
        }
        let slot = await saverep.json();
        console.log(slot);
        let ip = localStorage.getItem("ipaddress");
        let browserinfo = browserName + "-" + browserVersion + ".0.0";
        let Eventtype = "slot";
        let gymid = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).gymid : "";
        let username1 = localStorage.getItem("loggedUserInfo") ? JSON.parse(localStorage.getItem("loggedUserInfo")).email : "";
        if (props.id === slot._id) {
          props.onsubmit(slot);
          let flag = `Slot Updated Successfull (${updates.name})`;
          await googleAnalytic("CreateSlot", 'Slot', 'Save Button', 'Slot Updated')
      
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
        } else {
          let flag = `Slot Created Successfull (${updates.name})`;
          await googleAnalytic("CreateSlot", 'Slot', 'create Button', 'Slot Created')
         
          logEvent({ Eventtype, username1, ip, flag, browserinfo, gymid }, gymid);
          localStorage.setItem("setting_save_slot", "Slot saved successfully");
          props.onsubmit(slot);
        }
      }
    } else {
      setError(errors);
    }
  };


  useEffect(() => {
    async function fetchSData() {
      if (localStorage.getItem("loggedUserInfo")) {
        const response = await getSlots(
          JSON.parse(localStorage.getItem("loggedUserInfo")).gymid
        );
        if (!response) {
          return;
        }
        if (response === "false") {
          setApiError(true)
          return;
        }
        const slots = await response.json();
        console.log(slots);
        if (!slots) {
          window.alert(`info with id  not found`);
          navigate("/");
          return;
        }
        setSlots(slots)
      }
    }
    fetchSData()
    async function fetchData() {
      const response = await getSlotByPId(props.id);
      console.log(response);
      if (!response) {
        return;
      }
      if (response === "false") {
        setApiError(true)
        return;
      }
      const record = await response.json();
      if (!record) {

        return;
      }
      setForm(record);
      console.log(record);
      //setSq(record.props);
    }
    if (props.id && props.id !== "-1") {
      fetchData();
    }
    return;
  }, [props.id]);

  console.log(slots);
  return (
    <>
      {apiError ? <PageError handleReload={handleReload} /> : <> <div
        className="gym-container"
        id="showcase"
      >

      </div>
        <form action="#" target="_blank" onSubmit={onSubmit}>
          <div className="slotpopups">
            <div>
              <div className="slotname  ">
                <label>Slot Name<span className="text-danger">*</span>:</label>{" "}
                <input
                  className="gym-input"
                  type="text"
                  name="slotname"
                  id="slotname"
                  placeholder="Slot Name"
                  value={form.name}
                  onChange={(e) => updateForm({ name: e.target.value })}
                />

                {error.name && (
                  <>
                    <p className="clr-red slotred">{error.name}</p>
                  </>
                )}
              </div>
              <div className="slotname">
                <label>Slot Time:</label>
                <div className="d-flex">
                  <div>
                    <select
                      name="shrs"
                      id="shr"
                      className="slotdrop"
                      value={form.shrs}
                      onChange={(e) => updateForm({ shrs: e.target.value })}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div>
                    <select
                      name="smins"
                      id="smins"
                      className="slotdrop"
                      value={form.smins}
                      onChange={(e) => updateForm({ smins: e.target.value })}
                    >
                      <option value="1">00</option>
                      <option value="2">15</option>
                      <option value="3">30</option>
                      <option value="4">45</option>
                    </select>
                  </div>
                  <div>
                    <select
                      name="shflag"
                      id="shflag"
                      className="slotdrops"
                      value={form.shflag}
                      onChange={(e) => updateForm({ shflag: e.target.value })}
                    >
                      <option value="1">AM</option>
                      <option value="2">PM</option>
                    </select>
                  </div>
                </div>
                <span className="slotto"> to </span>
                <div className="d-flex">
                  <div>
                    <select
                      name="ehr"
                      id="ehr"
                      className="slotdrop"
                      value={form.ehrs}
                      onChange={(e) => updateForm({ ehrs: e.target.value })}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div>
                    <select
                      name="emins"
                      id="emins"
                      className="slotdrop"
                      value={form.emins}
                      onChange={(e) => updateForm({ emins: e.target.value })}
                    >
                      <option value="1">00</option>
                      <option value="2">15</option>
                      <option value="3">30</option>
                      <option value="4">45</option>
                    </select>
                  </div>

                  <div>
                    <select
                      name="ehflag"
                      id="ehflag"
                      value={form.ehflag}
                      className="slotdrops"
                      onChange={(e) => updateForm({ ehflag: e.target.value })}
                    >
                      <option value="1">AM</option>
                      <option value="2">PM</option>
                    </select>
                  </div>
                </div>
              </div>
              {error.overlap && (
                <p className="clr-red slotred">{error.overlap}</p>
              )}

            </div>
            <div className="modal-footer d-flex justify-left">
              <button
                type="submit"
                className="btn btn-primary"
                data-mdb-dismiss="modal"
              >
                Save
              </button>{" "}
              &nbsp;
              <button
                to="#"
                onClick={props.oncancel}
                className="btn btn-secondary"
                data-mdb-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </>}</>
  );
};

export default CreateSlot;