import React, { Component } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Feature1 from "../assets/images/1.png";
import Feature2 from "../assets/images/2.png";

class Feature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [

                {
                    id: 'service4',
                    icon: 'mountains',
                    title: "Membership Addons",
                    description: 'Membership add-ons feature that allows gym owners and managers to offer additional services or products to their members on top of their existing membership plans. For example, they may offer personal training sessions, nutritional counseling, or gym merchandise as add-ons that members can purchase.'
                },
                {
                    id: 'service5',
                    icon: 'flip-h',
                    title: "Intuitive Point of Sale (PoS) Feature",
                    description: 'that makes it easy to record payments and tie them to memberships. With this feature, gym owners and managers can quickly and easily process payments for membership fees, classes, or other services, and associate them with the appropriate member account.'
                },
                {
                    id: 'service6',
                    icon: 'expand-arrows-alt',
                    title: "Simple and Intuitive for Desktop & Mobile",
                    description: 'Simple and intuitive app that is ultra-responsive on both desktop and mobile devices. The app s user interface is designed with the user in mind, making it easy to navigate and use. It is also optimized for speed and performance, ensuring that users can perform tasks quickly and efficiently.'
                },
                {
                    id: 'service4',
                    icon: 'fire',
                    title: "Slot Based Membership Plans",
                    description: 'With Gym Admin Onlines slot-based membership plans feature, gym owners and managers can also offer different packages for different time slots. This can allow them to customize their offerings and pricing based on the time of day or the popularity of certain time slots. For example, they may offer a lower-priced package for off-peak hours or a premium package for prime time slots.'
                },
                {
                    id: 'service1',
                    icon: 'users-alt',
                    title: "Member Management",
                    description: "Easily manage your members' profiles, including their personal information, membership plans, and attendance records."
                },
                {
                    id: 'service2',
                    icon: 'signin',
                    title: "E Check-in",
                    description: 'Gym owners and managers can quickly and easily check-in members as they arrive at the gym, using a simple interface'
                },
            ]
        };
    }

    render() {
        return (
            <React.Fragment>
                {/*  Services START  */}
                <section className="section" id="features">
                    <Container>
                        <Row className="justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center mb-4 pb-2">
                                    <h3 className="title mb-3">Our Features</h3>
                                    <p className="text-muted mx-auto para-desc mb-0">Our gym management app comes packed with a range of powerful features, including:</p>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            {this.state.services.map((item, key) => (
                                <Col lg={4} md={6} className="mt-5 pt-4" key={key}>
                                    <Card className="features feature-primary feature-shadow-md shadow rounded p-4 pt-5 prisingdiv">
                                        <div className="icon-style mt-n80 position-relative text-center shadow rounded bg-white mb-4">
                                            <i className={"h4 mb-0 uil uil-" + item.icon}></i>
                                        </div>
                                        <div className="content">
                                            <Link to="#" className="title text-dark h5">{item.title}</Link>
                                            <p className="text-muted mt-3">{item.description}</p>
                                            {/* <Link to="#" className="text-dark title">Read More <i className="uil uil-arrow-right"></i> </Link> */}
                                        </div>
                                    </Card>
                                </Col>
                            ))}

                        </Row>
                    </Container>

                    {/* <Container className="mt-100 mt-60">
                        <Row>
                            <Col lg={5}>
                                <div className="section-title">
                                    <h4 className="section-title mb-3 mb-lg-0">Some of the most importent <br /> things in this tools</h4>
                                </div>
                            </Col>

                            <Col lg={7}>
                                <p className="text-muted para-desc mb-0">Launch your campaign and benefit from our expertise on designing and managing conversion centered bootstrap v5 html page.</p>
                            </Col>
                        </Row>
                    </Container> */}

                    <Container className="mt-100 mt-60">
                        <Row className="align-items-center">
                            <Col lg={6} md={6}>
                                <img src={Feature1} className="img-fluid shadow-md rounded-md" alt="" />
                            </Col>

                            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ms-lg-5">
                                    <h4 className="title mb-4">Analytics With Easy Check-in & out Feature</h4>
                                    <p className="text-muted">
                                        Gym Admin offers a seamless user experience with a user friendly interface for tracking footfalls and time spent in the gym for insights to manage your resources better.                                    </p>
                                    <ul className="list-unstyled text-muted">
                                        <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Easy Check-in & out</li>
                                        {/* <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Workout Time Tracking</li> */}
                                    </ul>
                                    {/* <Link to="#" className="mt-3 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="mt-100 mt-60">
                        <Row className="align-items-center">
                            <Col lg={6} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title me-lg-5">
                                    <h4 className="title mb-4">Easy Creation of Slots/Plans/Addons</h4>
                                    <p className="text-muted">
                                        Create membership plans for peak and off peak hours, addons without inflating your membership data.                                    </p>
                                    <ul className="list-unstyled text-muted">
                                        {/* <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li> */}
                                        {/* <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your own Slots/Plans/Addon's</li> */}
                                    </ul>
                                    {/* <Link to="#" className="mt-3 text-primary">Find Out More <i className="uil uil-angle-right-b"></i></Link> */}
                                </div>
                            </Col>

                            <Col lg={6} md={6} className="order-1 order-md-2">
                                <img src={Feature2} className="img-fluid shadow-md rounded-md" alt="" />
                            </Col>
                        </Row>
                    </Container>
                </section>

            </React.Fragment>
        );
    }
}
export default Feature;